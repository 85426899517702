import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import {
  FiChevronDown,
  FiChevronUp,
  FiExternalLink,
  FiLogOut,
  FiUser,
} from 'react-icons/fi';
import { Avatar } from '.';
import { MSG_GLOBAL_ERROR, underscoreToSpace } from '../../../utils';
import { Text } from '../../typography';
import { useStores } from '../../../stores/stores';
import { SnackbarContext } from '../../alerts';
import { LoadingContext } from '../../modals/loading';
import { SidebarMenu } from '../sidebar';
import { ConfirmationModal } from '../../modals/confirmation-modal';
import { Popover } from '@headlessui/react';

export const NavBar: React.FunctionComponent<unknown> = observer(() => {
  const { accountStore } = useStores();
  const [logoutConfirmationVisible, setLogoutConfirmationVisibility] =
    useState<boolean>(false);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const user = accountStore?.sellerProfile?.User;
  const profilePicture = accountStore?.sellerProfile?.profilePicture;
  const role = accountStore?.staffPermissions?.roleName;

  const getProfileData = useCallback(async () => {
    const user = await accountStore.getSellerProfile();
    if (user) Bugsnag.setUser(user.User.id, undefined, user.User.name);
  }, [accountStore]);

  useEffect(() => {
    if (!accountStore?.sellerProfile) getProfileData();
  }, [accountStore?.sellerProfile, getProfileData]);

  const handleLogoutConfirmed = useCallback(async () => {
    try {
      setLogoutConfirmationVisibility(false);
      startLoading();
      const isLogoutSuccess = await accountStore?.logout();
      if (isLogoutSuccess) {
        window.location.href = '/login?logout=true';
      } else {
        showSnackbar({
          text: MSG_GLOBAL_ERROR,
          type: 'danger',
        });
      }
    } catch (e) {
      console.error(e);
      showSnackbar({
        text: MSG_GLOBAL_ERROR,
        type: 'danger',
      });
    } finally {
      stopLoading();
    }
  }, [accountStore, showSnackbar, startLoading, stopLoading]);

  return (
    <nav
      className="bg-white justify-end fixed flex w-full shadow-sm h-20"
      style={{ zIndex: 1 }}
    >
      <div className="flex flex-row items-center py-2 p-4 rounded-lg my-2 mr-2 select-none">
        <Avatar name={user?.name} imageUrl={profilePicture} />
        <div className="mx-4">
          <Text font="bold">{`${user?.name} - ${
            role ? underscoreToSpace(role) : 'Superadmin'
          }`}</Text>
          <div className="flex flex-row items-center">
            <a
              href={`${process.env.REACT_APP_WEB_URL}/${user?.username}`}
              target="_blank"
              rel="noreferrer"
              className="flex flex-row items-center group-2"
            >
              <Text className="text-primary pr-1">
                {process.env.REACT_APP_WEB_URL_DISPLAY}/{user?.username}
              </Text>
              <FiExternalLink className="text-primary invisible [.group-2:hover_&]:visible" />
            </a>
          </div>
        </div>
        <div className="cursor-pointer rounded hover:bg-gray-100">
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button>
                  {open ? (
                    <FiChevronUp size={24} />
                  ) : (
                    <FiChevronDown size={24} />
                  )}
                </Popover.Button>
                <Popover.Panel
                  className="bg-white absolute right-0 w-64 top-12 rounded-lg shadow-sm font-weight-bold text-secondary-gray px-2 py-1"
                  as="div"
                >
                  <SidebarMenu
                    title="Akun"
                    Icon={(props) => <FiUser fontSize="20" {...props} />}
                    link="/profile"
                  />
                  <div className="border border-t-0 border-l-0 border-r-0 my-1" />
                  <SidebarMenu
                    title="Keluar"
                    Icon={(props) => <FiLogOut fontSize="20" {...props} />}
                    link="#"
                    onClick={() => {
                      setLogoutConfirmationVisibility(true);
                    }}
                  />
                </Popover.Panel>
              </>
            )}
          </Popover>
        </div>
      </div>
      {logoutConfirmationVisible && (
        <ConfirmationModal
          isOpen={true}
          title="Keluar?"
          description="Anda akan kembali ke halaman awal."
          cancelText="BATAL"
          onCancel={() => {
            setLogoutConfirmationVisibility(false);
          }}
          confirmText="KELUAR"
          onConfirm={handleLogoutConfirmed}
        />
      )}
    </nav>
  );
});

import React from 'react';
import { SideBar, NavBar, type AdminProps } from '..';
import { AdminFooter } from '../../footers/admin-footer';

export const Admin: React.FunctionComponent<AdminProps> = ({
  children,
  isReseller,
}) => {
  return (
    <>
      <NavBar />
      <SideBar isReseller={isReseller} />
      <main className="flex flex-col flex-grow min-h-screen justify-between lg:ml-60">
        <section className="pt-20 z-0">{children}</section>
        <AdminFooter />
      </main>
    </>
  );
};

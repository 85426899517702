import { cast, type Instance, types } from 'mobx-state-tree';
import {
  MorakLoanStatus,
  normalizePhoneNumber,
  type OrderCountResult,
  type OrderDueDateSummaryResponse,
  type OrderItemLogsResponse,
  type OrderItemResponse,
  type OrderLimitResponse,
  type OrderResponse,
  type ProductsChangedResponse,
  type ResellerOrderReportOrderItem,
  type ResellerOrderReportResult,
} from '../../utils';
import {
  mapProductResponseToProduct,
  type Product,
  ProductModel,
} from '../product-store/product-store.model';
import {
  CashierModel,
  DeliverymanModel,
  SalesModel,
} from '../staff-store/staff-store.model';
import {
  SellerAdminFeeModel,
  SellerAdminFeeType,
} from '../account-store/account-store.model';

/**
 * ORDER ITEM LOGS MODEL (SUPPLIER)
 *
 * - OrderItemLogsModel
 * - OrderItemLogs
 *
 * Mappers:
 * - mapOrderItemLogsResponseToOrderItemLogs
 */

export const OrderItemLogsModel = types.model('OrderItemLogsModel').props({
  event: types.string,
  orderItemId: types.string,
  previousPrice: types.string,
  previousQty: types.number,
  updatedPrice: types.string,
  updatedQty: types.number,
});

export type OrderItemLogs = Instance<typeof OrderItemLogsModel>;

export function mapOrderItemLogsResponseToOrderItemLogs(
  response: OrderItemLogsResponse[],
): OrderItemLogs[] {
  return response
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    )
    .map((item) => ({
      event: item.events,
      orderItemId: item.order_item_id,
      previousPrice: item.previous_price,
      previousQty: item.previous_qty,
      updatedPrice: item.price,
      updatedQty: item.qty,
    }));
}

/**
 * ORDER ITEM MODEL
 *
 * - SellerDetailUserModel
 * - SellerDetailModel
 * - OrderItemModel
 * - OrderItem
 *
 * Mappers:
 * - mapOrderItemResponseToOrderItem
 */

const SellerDetailUserModel = types.model('SellerDetailUserModel').props({
  name: types.string,
  username: types.string,
});

export const SellerDetailModel = types.model('SellerDetailModel').props({
  User: SellerDetailUserModel,
  id: types.string,
  user_id: types.string,
});

export const OrderItemModel = types.model('OrderItemModel').props({
  basePrice: types.maybeNull(types.number),
  createdAt: types.string,
  discountPrice: types.maybeNull(types.number),
  deletedAt: types.maybeNull(types.string),
  id: types.identifier,
  notes: types.maybeNull(types.string),
  orderId: types.string,
  orderLogs: types.maybe(types.array(OrderItemLogsModel)),
  orderStatusSupplier: types.maybeNull(types.string),
  price: types.number,
  product: types.maybe(ProductModel),
  productId: types.string,
  qty: types.number,
  unit: types.string,
  revision: types.maybeNull(types.string),
  ratio: types.maybeNull(types.number),
  updatedAt: types.maybeNull(types.string),
  selected: types.boolean,
  SellerDetail: types.maybeNull(SellerDetailModel),
  supplierId: types.maybeNull(types.string),
  supplierName: types.maybeNull(types.string),
  isNew: types.maybeNull(types.boolean),
  isReturnedItem: types.maybeNull(types.boolean),
});

export type OrderItem = Instance<typeof OrderItemModel>;

export function mapOrderLimitResponseToOrderLimit(
  response: OrderLimitResponse,
): OrderLimit {
  return {
    resellerMinOrderCashLimit: response.reseller_min_order_cash_limit ?? 0,
    resellerMaxOrderCashLimit: response.reseller_max_order_cash_limit ?? 0,
    resellerMinOrderTempoLimit: response.reseller_min_order_tempo_limit ?? 0,
    resellerMaxOrderTempoLimit: response.reseller_max_order_tempo_limit ?? 0,
    tradingMinOrderCashLimit: response.trading_min_order_cash_limit ?? 0,
    tradingMaxOrderCashLimit: response.trading_max_order_cash_limit ?? 0,
    tradingMinOrderTempoLimit: response.trading_min_order_tempo_limit ?? 0,
    tradingMaxOrderTempoLimit: response.trading_max_order_tempo_limit ?? 0,
  };
}

export const OrderLimitModel = types.model('OrderLimit').props({
  resellerMinOrderCashLimit: types.number,
  resellerMaxOrderCashLimit: types.number,
  resellerMinOrderTempoLimit: types.number,
  resellerMaxOrderTempoLimit: types.number,
  tradingMinOrderCashLimit: types.number,
  tradingMaxOrderCashLimit: types.number,
  tradingMinOrderTempoLimit: types.number,
  tradingMaxOrderTempoLimit: types.number,
});

export type OrderLimit = Instance<typeof OrderLimitModel>;

export function mapOrderItemsResponseToOrderItems(
  response: OrderItemResponse[],
): OrderItem[] {
  return response.map((orderItem) => {
    return {
      basePrice: orderItem.base_price ?? 0,
      createdAt: orderItem.created_at ?? '',
      deletedAt: orderItem.deleted_at ?? '',
      discountPrice: orderItem.discount_price ?? null,
      id: orderItem.id,
      notes: orderItem.notes ?? '',
      orderId: orderItem.order_id ?? '',
      orderLogs: orderItem?.OrderItemLogs
        ? cast(mapOrderItemLogsResponseToOrderItemLogs(orderItem.OrderItemLogs))
        : null,
      orderStatusSupplier: orderItem.order_status_supplier ?? null,
      price: orderItem.price ?? 0,
      product: orderItem?.Product
        ? mapProductResponseToProduct(orderItem.Product)
        : null,
      productId: orderItem.product_id ?? '',
      qty: orderItem.qty ?? 0,
      unit: orderItem.unit ?? '',
      revision: orderItem.revision ?? '',
      ratio: orderItem.ratio ?? 0,
      updatedAt: orderItem.updated_at ?? null,
      selected: false,
      SellerDetail: orderItem.SellerDetail,
      supplierId: orderItem.Product?.supplier_id ?? null,
      supplierName: orderItem.supplier_name ?? '',
      isNew: orderItem.is_new,
      isReturnedItem: false,
    };
  });
}

export function mapProductsChangedResponseToProductsChange(
  response: ProductsChangedResponse[],
  productsMap: Map<string, Product>,
): ProductsChanged[] {
  return response.map((product: ProductsChangedResponse) => {
    return {
      id: product.id,
      previousPrice: product.previous_price ?? 0,
      price: product.price ?? 0,
      name: product.name ?? '',
      sellerName: product.seller_name ?? '',
      unitName: product.unit_name ?? '',
      imgUrl:
        productsMap.get(product.id)?.mainPictureUrl ??
        '/img/product-image-placeholder.png',
      isDeleted: product.is_deleted,
    };
  });
}

/**
 * ORDERS SUPPLIER MODEL (RESELLER)
 *
 * - OrdersSupplierModel
 * - OrdersSupplier
 *
 */

export const OrdersSupplierModel = types.model('OrdersSupplierModel').props({
  id: types.string,
  supplierName: types.string,
  orderItems: types.array(OrderItemModel),
  status: types.string,
  isOrderChanged: types.boolean,
});

export type OrdersSupplier = Instance<typeof OrdersSupplierModel>;

/**
 * ORDERS SUPPLIER MODEL (RESELLER)
 *
 * - OrdersSupplierModel
 * - OrdersSupplier
 *
 */

export const ProductsChangedModel = types.model('ProductsChangedModel').props({
  id: types.string,
  previousPrice: types.number,
  price: types.number,
  name: types.string,
  unitName: types.string,
  sellerName: types.string,
  imgUrl: types.string,
  isDeleted: types.boolean,
});

export type ProductsChanged = Instance<typeof ProductsChangedModel>;

export const VoucherModel = types.model('VoucherModel').props({
  id: types.string,
  voucherCode: types.string,
  discountAmount: types.maybeNull(types.number),
  discountDeliveryFee: types.maybeNull(types.number),
  discountPercentageAmount: types.maybeNull(types.number),
  discountPercentageDeliveryFee: types.maybeNull(types.number),
  maxNominalDiscount: types.maybeNull(types.number),
  minTotalPurchase: types.maybeNull(types.number),
});

export type Voucher = Instance<typeof VoucherModel>;

export const OrganicBuyerModel = types.model('OrganicBuyer').props({
  id: types.identifier,
  organic: types.boolean,
});

export type OrganicBuyer = Instance<typeof OrganicBuyerModel>;

export const ReturnItemModel = types.model('ReturnItem').props({
  orderItemId: types.string,
  orderItemLogsId: types.number,
  price: types.number,
  qty: types.number,
});

export type ReturnItem = Instance<typeof ReturnItemModel>;

/**
 * ORDER MODEL
 *
 * - OrderModel
 * - Order
 * - OrderMap
 *
 * Mappers:
 * - mapOrderResponseToOrder
 */

export const OrderModel = types.model('Order').props({
  id: types.identifier,
  totalValue: types.number,
  totalOrderValue: types.number,
  logisticFee: types.number,
  additionalFee: types.number,
  admin_fee_id: types.maybeNull(types.string),
  sellerId: types.string,
  buyerId: types.string,
  transactionNumber: types.string,
  paymentCode: types.maybeNull(types.string),
  paymentFee: types.maybeNull(types.number),
  paymentLink: types.maybeNull(types.string),
  paymentMethod: types.maybeNull(types.string),
  logisticMethod: types.maybeNull(types.string),
  customerName: types.maybeNull(types.string),
  customerNotes: types.maybeNull(types.string),
  customerPhone: types.string,
  recipientPhone: types.string,
  orderNotes: types.maybeNull(types.string),
  address: types.string,
  status: types.string,
  paymentStatus: types.string,
  acceptedAt: types.maybeNull(types.string),
  deliveryAt: types.maybeNull(types.string),
  shippedAt: types.maybeNull(types.string),
  cancelledAt: types.maybeNull(types.string),
  completedAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  stateId: types.number,
  cityId: types.number,
  districtId: types.number,
  subdistrictId: types.number,
  cancelledReason: types.maybeNull(types.string),
  paidAt: types.maybeNull(types.string),
  paidAmount: types.maybeNull(types.number),
  deliveryType: types.maybeNull(types.string),
  deliveryman: types.maybe(DeliverymanModel),
  sales: types.maybe(SalesModel),
  cashier: types.maybe(CashierModel),
  revision: types.string,
  dueDate: types.maybeNull(types.string),
  latitude: types.maybeNull(types.string),
  longitude: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.string,
  orderItems: types.array(OrderItemModel),
  adminFee: types.maybeNull(SellerAdminFeeModel),
  discountPercentage: types.maybeNull(types.number),
  discountAmount: types.maybeNull(types.number),
  sellerName: types.maybeNull(types.string),
  /**
   * `true` if reseller / retail order and `false` if trading / grosir order
   */
  isReseller: types.maybeNull(types.boolean),
  isChanged: types.maybeNull(types.boolean),
  willExpireAt: types.maybeNull(types.string),
  ordersSupplier: types.maybe(types.array(OrdersSupplierModel)),
  productsChanged: types.maybeNull(types.array(ProductsChangedModel)),
  type: types.maybeNull(types.string),
  coverageAreaId: types.maybeNull(types.string),
  resellerOrderReferenceId: types.maybeNull(types.string),
  CoverageArea: types.model({
    address: types.string,
    id: types.string,
    name: types.string,
    phone: types.string,
    status: types.boolean,
  }),
  User: types.maybeNull(OrganicBuyerModel),
  Voucher: types.maybe(VoucherModel),
  voucherFee: types.maybeNull(types.number),
  voucherLogisticFee: types.maybeNull(types.number),
  MorakLoan: types.maybeNull(
    types.model({
      callback_status: types.boolean,
      id: types.string,
      status: types.enumeration<MorakLoanStatus>(
        'MorakLoanStatus',
        Object.values(MorakLoanStatus),
      ),
    }),
  ),
  morakLoanStatus: types.maybeNull(
    types.enumeration<MorakLoanStatus>(
      'MorakLoanStatus',
      Object.values(MorakLoanStatus),
    ),
  ),
  returnItems: types.maybe(types.array(ReturnItemModel)),
  trackingNumber: types.maybeNull(types.string),
  trackingImg: types.maybeNull(types.string),
});

export type Order = Instance<typeof OrderModel>;

export type OrderMap = Map<string, Order>;

function mapOrganicBuyerResponseToOrganicBuyer(organicBuyerResponse: {
  id: string;
  organic: boolean;
}): OrganicBuyer {
  if (!organicBuyerResponse) return null;
  return {
    id: organicBuyerResponse.id,
    organic: organicBuyerResponse.organic,
  };
}

export function mapOrderResponseToOrder(
  response: OrderResponse,
  isChanged = false,
): Order {
  const ordersSupplier: OrdersSupplier[] = [];

  const orderItems = mapOrderItemsResponseToOrderItems(response.OrderItems);

  const productsMap: Map<string, Product> = new Map<string, Product>();
  orderItems.forEach(({ product, productId }: OrderItem) => {
    if (productId) productsMap.set(productId, product);
  });

  const productsChanged: ProductsChanged[] = response.ProductsChanged
    ? mapProductsChangedResponseToProductsChange(
        response.ProductsChanged,
        productsMap,
      )
    : [];

  if (response.OrdersSupplier) {
    for (const [key, value] of Object?.entries(response.OrdersSupplier)) {
      ordersSupplier.push({
        id: key,
        supplierName: value?.supplier_name ?? value?.User?.name,
        orderItems: cast([
          ...mapOrderItemsResponseToOrderItems(
            (value?.OrderItems?.changed_rows ??
              value?.Order?.OrderItems?.changed_rows) as OrderItemResponse[],
          ),
          ...mapOrderItemsResponseToOrderItems(
            (value?.OrderItems?.rows ??
              value?.Order?.OrderItems?.rows) as OrderItemResponse[],
          ),
        ]),
        status: value?.status,
        isOrderChanged:
          !!value?.is_order_changed ||
          !!value?.Order?.OrderItems?.changed_rows?.length,
      });
    }
  }

  let returnItems: ReturnItem[] = [];
  if (response.return_items?.length) {
    returnItems = response.return_items.map((item) => ({
      orderItemId: item.order_item_id,
      orderItemLogsId: item.order_item_logs_id,
      price: item.price,
      qty: item.qty,
    }));
  }

  const returnedItemsId = returnItems?.map((item) => item.orderItemId);
  const returnedItems = orderItems?.filter(
    (item) => returnedItemsId?.includes(item.id),
  );
  const filteredOrdersSupplier = ordersSupplier
    .filter(filterOrdersSupplier)
    .map(mapOrdersSupplier);

  function filterOrdersSupplier(supplier: OrdersSupplier) {
    const orderItems = filterItemsOrdersSupplier(supplier.orderItems);
    if (!orderItems.length) return false;
    return supplier;
  }

  function mapOrdersSupplier(supplier: OrdersSupplier) {
    const orderItems = filterItemsOrdersSupplier(supplier.orderItems);
    return { ...supplier, orderItems };
  }

  function filterItemsOrdersSupplier(orderItems: OrderItem[]) {
    return orderItems.filter((supplierItem) => {
      if (returnedItems?.length > 0) {
        const returnedItem = returnedItems.find(
          (returnedItem) =>
            returnedItem.product.referenceProductId === supplierItem.product.id,
        );
        // if we render from ordersSupplier and return_items for the supplier item exists, FE need to substract the qty manually.
        if (returnedItem?.qty) {
          return {
            ...supplierItem,
            qty: supplierItem.qty - returnedItem.qty,
          };
        }
        if (returnedItem && !returnedItem.qty) return false;
      }
      return supplierItem;
    });
  }

  return {
    id: response.id,
    totalValue: response.total_value,
    totalOrderValue:
      response.total_value +
      Math.max(
        0,
        (response.logistic_fee || 0) - (response?.voucher_logistic_fee || 0),
      ) +
      (response.additional_fee || 0) -
      (response.discount_amount || 0) -
      (response.voucher_fee || 0),
    logisticFee: response.logistic_fee,
    additionalFee: response.additional_fee,
    admin_fee_id: response.admin_fee_id,
    sellerId: response.seller_id,
    buyerId: response.buyer_id,
    transactionNumber: response.transaction_number,
    paymentCode: response.payment_code,
    paymentFee: response.payment_fee,
    paymentLink: response.payment_link,
    paymentMethod: response.payment_method,
    logisticMethod: response.logistic_method,
    customerName: response.customer_name,
    customerNotes: response.customer_notes,
    customerPhone: normalizePhoneNumber(response.customer_phone),
    recipientPhone: normalizePhoneNumber(response.recipient_phone),
    address: response.address,
    orderNotes: response.order_notes,
    status: response.status,
    paymentStatus: response.payment_status,
    acceptedAt: response.accepted_at,
    deliveryAt: response.delivery_at,
    shippedAt: response.shipped_at,
    cancelledAt: response.cancelled_at,
    completedAt: response.completed_at,
    deletedAt: response.deleted_at,
    stateId: response.state_id,
    cityId: response.city_id,
    districtId: response.district_id,
    subdistrictId: response.subdistrict_id,
    cancelledReason: response.cancelled_reason,
    paidAt: response.paid_at,
    paidAmount: response?.paid_amount || 0,
    deliveryType: response.delivery_type,
    deliveryman: {
      id: response.delivery_man_id,
      username: response.Deliveryman?.username,
    },
    sales: {
      id: response.sales_id,
      username: response.Sales?.username,
    },
    cashier: {
      id: response.Cashier?.id,
      username: response.Cashier?.username,
    },
    revision: response.revision,
    dueDate: response.due_date,
    latitude: response.latitude,
    longitude: response.longitude,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    orderItems: cast(orderItems),
    adminFee: response.SellerAdminFee
      ? {
          id: response.SellerAdminFee?.id || '',
          amount: response.SellerAdminFee?.amount || 0,
          type:
            response.SellerAdminFee?.type === SellerAdminFeeType.cash
              ? SellerAdminFeeType.cash
              : SellerAdminFeeType.tempo,
          percentageAmount: response.SellerAdminFee?.percentage_amount,
          dueDays: response.SellerAdminFee?.due_days,
          active: true,
          sellerType: response.SellerAdminFee?.seller_type ?? null,
        }
      : null,
    discountPercentage: response.discount_percentage || 0,
    discountAmount: response.discount_amount || 0,
    sellerName: response.SellerDetail?.User?.name || '',
    isReseller: !!response.is_reseller,
    willExpireAt: response.will_expire_at,
    type: response.type,
    isChanged,
    ordersSupplier: cast(filteredOrdersSupplier),
    productsChanged: cast(productsChanged),
    resellerOrderReferenceId: response.reseller_order_reference_id,
    CoverageArea: cast(response.CoverageArea),
    coverageAreaId: response.coverage_area_id ?? null,
    User: mapOrganicBuyerResponseToOrganicBuyer(response.User) ?? null,
    Voucher: response.Voucher
      ? {
          id: response.Voucher?.id,
          voucherCode: response.Voucher?.voucher_code,
          discountAmount: response.Voucher?.discount_amount,
          discountDeliveryFee: response.Voucher?.discount_delivery_fee,
          discountPercentageAmount:
            response.Voucher?.discount_percentage_amount,
          discountPercentageDeliveryFee:
            response.Voucher?.discount_percentage_delivery_fee,
          maxNominalDiscount: response.Voucher?.max_nominal_discount,
          minTotalPurchase: response.Voucher?.min_total_purchase,
        }
      : null,
    voucherFee: response.voucher_fee || null,
    voucherLogisticFee: response.voucher_logistic_fee || null,
    MorakLoan: response.MorakLoan,
    morakLoanStatus: response.morak_loan_status || response.MorakLoan?.status,
    returnItems: cast(returnItems),
    trackingNumber: response.tracking_number ?? null,
    trackingImg: response.tracking_img ?? null,
  };
}

export const OrderCountModel = types.model('OrderCount').props({
  totalOrder: types.number,
  totalOrderCreated: types.number,
  totalOrderAccepted: types.number,
  totalOrderShipped: types.number,
  totalOrderOnDelivery: types.number,
  totalOrderCompleted: types.number,
  totalOrderCancelled: types.number,
});

export type OrderCount = Instance<typeof OrderCountModel>;

export const SupplierOrderCountModel = types.model('SupplierOrderCount').props({
  totalOrderAccepted: types.number,
  totalOrder: types.number,
  totalOrderCancelled: types.number,
  totalOrderCompleted: types.number,
  totalOrderCreated: types.number,
  totalOrderShipped: types.number,
});

export type SupplierOrderCount = Instance<typeof SupplierOrderCountModel>;

export function mapOrderCountResultToOrderCount(
  response: OrderCountResult,
): OrderCount {
  return {
    totalOrder: response.total_order ?? 0,
    totalOrderCreated: response.total_order_created ?? 0,
    totalOrderAccepted: response.total_order_accepted ?? 0,
    totalOrderShipped: response.total_order_shipped ?? 0,
    totalOrderOnDelivery: response.total_order_on_delivery ?? 0,
    totalOrderCompleted: response.total_order_completed ?? 0,
    totalOrderCancelled: response.total_order_cancelled ?? 0,
  };
}

export const OrderDueDateSummaryModel = types
  .model('OrderDueDateSummary')
  .props({
    totalValue: types.number,
    logisticFee: types.number,
    additionalFee: types.number,
    totalOrder: types.number,
    totalPastDueDate: types.number,
    totalTodayDueDate: types.number,
    totalFutureDueDate: types.number,
  });

export type OrderDueDateSummary = Instance<typeof OrderDueDateSummaryModel>;

export function mapOrderDueDateSummary(response: OrderDueDateSummaryResponse) {
  return {
    totalValue: response.total_value ?? 0,
    logisticFee: response.logistic_fee ?? 0,
    additionalFee: response.additional_fee ?? 0,
    totalOrder: response.total_order ?? 0,
    totalPastDueDate: response.total_past_due_date ?? 0,
    totalTodayDueDate: response.total_today_due_date ?? 0,
    totalFutureDueDate: response.total_future_due_date ?? 0,
  };
}

const ResellerOrderReportItemModelProductModel = types
  .model('ResellerOrderReportItemModelProductModel')
  .props({
    coverageArea: types.model({
      address: types.string,
      id: types.string,
      name: types.string,
      phone: types.string,
      status: types.boolean,
    }),
    id: types.string,
    name: types.string,
    sku: types.string,
  });

export const ResellerOrderReportItemModel = types
  .model('ResellerOrderReportItemModel')
  .props({
    product: ResellerOrderReportItemModelProductModel,
    basePrice: types.number,
    discountPrice: types.number,
    id: types.string,
    price: types.number,
    productId: types.string,
    qty: types.number,
    unit: types.string,
  });

export function mapResellerOrderReportItems(
  items: ResellerOrderReportOrderItem[],
) {
  return items.map((item) => ({
    product: {
      id: item.Product.id,
      name: item.Product.name,
      sku: item.Product.sku,
    },
    basePrice: item.base_price,
    discountPrice: item.discount_price,
    id: item.id,
    price: item.price,
    productId: item.product_id,
    qty: item.qty,
    unit: item.unit,
  }));
}

export const ResellerOrderReportModel = types
  .model('ResellerOrderReport')
  .props({
    orderItems: types.array(ResellerOrderReportItemModel),
    additionalFee: types.number,
    completedAt: types.string,
    createdAt: types.string,
    discountAmount: types.number,
    discountPercentage: types.number,
    id: types.string,
    logisticFee: types.number,
    paidAmount: types.number,
    paymentFee: types.number,
    paymentLink: types.string,
    paymentMethod: types.string,
    paymentStatus: types.string,
    sellerId: types.string,
    sellerName: types.string,
    status: types.string,
    totalValue: types.number,
    transactionNumber: types.string,
    customerName: types.string,
    parentOrder: types.model({
      totalValue: types.maybeNull(types.number),
      logisticFee: types.maybeNull(types.number),
      additionalFee: types.maybeNull(types.number),
      discountPercentage: types.maybeNull(types.number),
      discountAmount: types.maybeNull(types.number),
      paymentLink: types.string,
      paymentFee: types.maybeNull(types.number),
      paidAmount: types.maybeNull(types.number),
      id: types.string,
      coverageAreaId: types.string,
      CoverageArea: types.model({
        id: types.string,
        name: types.string,
      }),
    }),
  });

export type ResellerOrderReport = Instance<typeof ResellerOrderReportModel>;

export function mapResellerOrderReportResponse(
  response: ResellerOrderReportResult[],
) {
  return response.map((orderData) => ({
    orderItems: mapResellerOrderReportItems(orderData.OrderItems),
    additionalFee: orderData.additional_fee,
    completedAt: orderData.completed_at,
    createdAt: orderData.created_at,
    discountAmount: orderData.discount_amount,
    discountPercentage: orderData.discount_percentage,
    id: orderData.id,
    logisticFee: orderData.logistic_fee,
    paidAmount: orderData.paid_amount,
    paymentFee: orderData.payment_fee,
    paymentLink: orderData.payment_link,
    paymentMethod: orderData.payment_method,
    paymentStatus: orderData.payment_status,
    sellerId: orderData.seller_id,
    sellerName: orderData.SellerDetail.User.name,
    status: orderData.status,
    totalValue: orderData.total_value,
    transactionNumber: orderData.transaction_number,
    customerName: orderData.customer_name,
    parentOrder: orderData.ParentOrder && {
      totalValue: orderData.ParentOrder.total_value,
      logisticFee: orderData.ParentOrder.logistic_fee,
      additionalFee: orderData.ParentOrder.additional_fee,
      discountPercentage: orderData.ParentOrder.discount_percentage,
      discountAmount: orderData.ParentOrder.discount_amount,
      paymentLink: orderData.ParentOrder.payment_link,
      paymentFee: orderData.ParentOrder.payment_fee,
      paidAmount: orderData.ParentOrder.paid_amount,
      id: orderData.ParentOrder.id,
      coverageAreaId: orderData.ParentOrder.coverage_area_id,
      CoverageArea: orderData.ParentOrder.CoverageArea,
    },
  }));
}

import React, { useCallback, useState } from 'react';
import { initialContent, ImageViewerContext } from '.';

export const ImageViewerProvider = ({ children }) => {
  const [content, setContent] = useState(initialContent);

  const clearImageViewerUrl = useCallback(() => {
    setContent({ ...initialContent, url: null });
  }, []);

  const initImageViewerUrl = useCallback((args) => {
    setContent({ ...initialContent, ...args });
  }, []);

  return (
    <ImageViewerContext.Provider
      value={{
        content,
        clearImageViewerUrl,
        initImageViewerUrl,
      }}
    >
      {children}
    </ImageViewerContext.Provider>
  );
};

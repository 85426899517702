import currency from 'currency.js';
import { defaultNumericOpts } from './string';

export const numericToStringThousands = (value: number) => {
  return value ? currency(value, defaultNumericOpts).format() : '';
};

export const normalizePhoneNumber = (phone: string) => {
  let phoneNumber = phone?.toString();
  if (phoneNumber?.startsWith('8')) {
    phoneNumber = `0${phoneNumber}`;
    return phoneNumber;
  }
  return phone;
};

import { cast, type Instance, types } from 'mobx-state-tree';
import {
  type ParentProductResponse,
  type ProductCategoryResponse,
  type CoverageAreaResponse,
  type ProductImageResponse,
  type ProductResponse,
  type ProductUnitResponse,
  type ResellerSeller,
  type ResellerSellerRow,
  type ResellerSupplier,
  type ResellerSupplierRow,
  type SellerProductCategoryResponse,
  type ProductCoverageAreasResponse,
  type Unit,
  type UnitResponse,
} from '../../utils';

/**
 * PRODUCT CATEGORY MODEL
 *
 * - ProductCategoryInterface
 * - ProductCategoryModel
 * - ProductCategory
 *
 * Mappers:
 * - mapProductCategoryResponseToProductCategory
 * - mapSellerProductCategoryResponseToProductCategory
 */

interface ProductCategoryInterface {
  id: string;
  sellerId: string;
  name: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  margin?: number;
  isReseller?: boolean;
}

export const ProductCategoryModel = types.model('ProductCategory').props({
  id: types.identifier,
  sellerId: types.maybeNull(types.string),
  name: types.string,
  description: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  totalProduct: types.maybe(types.number),
  margin: types.maybeNull(types.number),
  active: types.maybe(types.boolean),
  selected: types.maybe(types.boolean),
});

export type ProductCategory = Instance<typeof ProductCategoryModel>;

export function mapProductCategoryResponseToProductCategory(
  response: ProductCategoryResponse,
): ProductCategoryInterface {
  return {
    id: response.id,
    sellerId: response.seller_id,
    name: response.name ?? '-',
    deletedAt: response.deleted_at,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    margin: response.margin,
    isReseller: response.is_reseller,
  };
}

export function mapSellerProductCategoryResponseToProductCategory(
  response: SellerProductCategoryResponse,
): ProductCategory {
  return {
    id: response.id,
    sellerId: null,
    name: response.name ?? '',
    description: response.description ?? null,
    icon: response.icon ?? null,
    deletedAt: null,
    createdAt: null,
    updatedAt: null,
    totalProduct: response.total_product,
    active: response.active ?? true,
    margin: response.margin,
    selected: false,
  };
}

/**
 * PRODUCT UNITS MODEL
 *
 * - ProductUnitInterface
 * - ProductUnitModel
 * - ProductUnit
 *
 * Mappers:
 * - mapProductUnitResponseToProductUnit
 */

interface ProductUnitInterface {
  id: string;
  price: number;
  discountPrice: number;
  productId: string;
  unit: string;
  ratio: number;
  active: boolean;
  availableOnline: boolean;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  unitType: null | 'credimart';
  featured: boolean;
}

export const ProductUnitModel = types.model('ProductUnit').props({
  id: types.maybeNull(types.identifier),
  key: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  basePrice: types.maybeNull(types.number),
  discountPrice: types.maybeNull(types.number),
  productId: types.maybeNull(types.string),
  unit: types.maybeNull(types.string),
  ratio: types.maybeNull(types.number),
  active: types.maybeNull(types.boolean),
  availableOnline: types.maybeNull(types.boolean),
  minQty: types.maybeNull(types.number),
  userUnitId: types.maybeNull(types.string),
  wholesalePrice: types.maybeNull(types.boolean),
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  unitType: types.union(types.null, types.literal('credimart')), // null = buyer price, 'credimart' = credimart price
  featured: types.boolean,
  priceError: types.maybe(types.string),
  discountPriceError: types.maybe(types.string),
  qtyError: types.maybe(types.string),
  credimartPrice: types.maybe(types.number), // additional property only available if added from product bulk edit
});

export type ProductUnit = Instance<typeof ProductUnitModel>;

export function mapProductUnitsResponseToProductUnits(
  response: ProductUnitResponse[],
): ProductUnitInterface[] {
  if (!response) {
    return [];
  }

  return response?.map((unit) => ({
    id: unit.id,
    key: unit.id,
    price: unit.price,
    basePrice: unit.base_price ?? 0,
    discountPrice: unit.discount_price > 0 ? unit.discount_price : null, // important to default to null if no discount val, to comply with FE logic
    productId: unit.product_id,
    unit: unit.unit,
    ratio: unit.ratio,
    active: unit.active,
    availableOnline: unit.available_online,
    minQty: unit.min_quantity,
    userUnitId: unit.user_unit_id,
    wholesalePrice: unit.wholesale_price,
    deletedAt: unit.deleted_at,
    createdAt: unit.created_at,
    updatedAt: unit.updated_at,
    unitType: unit.unit_type,
    featured: !!unit.featured,
  }));
}

/**
 * PRODUCT IMAGE MODEL
 *
 * - ProductImageInterface
 * - ProductImageModel
 * - ProductImage
 *
 * Mappers:
 * - mapProductResponseToProduct
 */

export interface ProductImageInterface {
  id: string;
  productId: string;
  imageUrl: string;
  active: boolean;
  featured: boolean;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
}

export const ProductImageModel = types.model('ProductImage').props({
  id: types.identifier,
  productId: types.string,
  imageUrl: types.maybeNull(types.string),
  active: types.boolean,
  featured: types.boolean,
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

export type ProductImage = Instance<typeof ProductImageModel>;

export function mapProductImagesResponseToProductImages(
  response: ProductImageResponse[],
): ProductImageInterface[] {
  return response?.map((result) => ({
    id: result.id,
    productId: result.product_id,
    imageUrl: result.image_url,
    active: result.active,
    featured: result.featured,
    deletedAt: result.deleted_at,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
  }));
}

export const ProductCoverageAreaModel = types
  .model('ProductCoverageArea')
  .props({
    id: types.identifier,
    name: types.string,
    phone: types.string,
    address: types.string,
    status: types.boolean,
  });

export type ProductCoverageArea = Instance<typeof ProductCoverageAreaModel>;

export function mapCoverageAreaResponseToProductCoverageArea(
  response: CoverageAreaResponse,
): ProductCoverageArea {
  return {
    id: response.id,
    name: response.name,
    phone: response.phone,
    address: response.address,
    status: response.status,
  };
}

export function mapProductCoverageAreasResponseToProductCoverageAreas(
  response: ProductCoverageAreasResponse[],
): ProductCoverageArea[] {
  return response.map(({ CoverageArea }) => CoverageArea);
}

export const ParentProductModel = types.model('ParentProduct').props({
  id: types.identifier,
  productUnits: types.optional(types.array(ProductUnitModel), []),
  sellerId: types.string,
  sellerName: types.string,
  sellerUsername: types.string,
});

export type ParentProduct = Instance<typeof ParentProductModel>;

export function mapParentProductResponseToParentProduct(
  response: ParentProductResponse,
): ParentProduct {
  return {
    id: response.id,
    productUnits: cast(
      response.ProductUnits
        ? mapProductUnitsResponseToProductUnits(response.ProductUnits)
        : [],
    ),
    sellerId: response.seller_id,
    sellerName: response.SellerDetail?.User?.name ?? '',
    sellerUsername: response.SellerDetail?.User?.username ?? '',
  };
}

/**
 * PRODUCT MODEL
 *
 * - ProductModel
 * - Product
 * - ProductMap
 *
 * Mappers:
 * - mapProductResponseToProduct
 */

export const ProductModel = types.model('Product').props({
  id: types.identifier,
  sellerId: types.string,
  productCategoryId: types.string,
  name: types.string,
  sku: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  mainPictureUrl: types.maybeNull(types.string),
  available: types.boolean,
  promoActive: types.boolean,
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  productImages: types.optional(types.array(ProductImageModel), []),
  productCategory: types.maybeNull(ProductCategoryModel),
  productUnits: types.optional(types.array(ProductUnitModel), []),
  coverageArea: types.maybeNull(ProductCoverageAreaModel),
  coverageAreaId: types.maybeNull(types.string),
  parentProduct: types.maybeNull(ParentProductModel),
  referenceProductId: types.maybeNull(types.string),
  selected: types.boolean,
  coverageAreas: types.maybeNull(types.array(ProductCoverageAreaModel)),
  coverageAreasDisplay: types.maybeNull(types.string),
  supplierId: types.maybeNull(types.string),
  hasOngoingResellerOrder: types.maybe(types.boolean),
});

export type Product = Instance<typeof ProductModel>;

export type ProductMap = Map<string, Product>;

export function mapProductResponseToProduct(
  response: ProductResponse,
): Product {
  return {
    id: response.id,
    sellerId: response.seller_id,
    productCategoryId: response.product_category_id,
    name: response.name,
    sku: response.sku,
    description: response.description,
    mainPictureUrl: response.main_picture_url,
    available: !!response.available,
    promoActive: response.promo_active
      ? response.promo_active
      : response.ProductUnits?.some((u) => u.discount_price > 0),
    deletedAt: response.deleted_at,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    productImages: cast(
      response.ProductImages
        ? mapProductImagesResponseToProductImages(response.ProductImages)
        : [],
    ),
    productCategory: cast(
      response.ProductCategory
        ? mapProductCategoryResponseToProductCategory(response.ProductCategory)
        : null,
    ),
    productUnits: cast(
      response.ProductUnits ||
        response.units ||
        response.ParentProduct?.ProductUnits
        ? mapProductUnitsResponseToProductUnits(
            (response.ProductUnits ||
              response.units ||
              response.ParentProduct?.ProductUnits) ??
              [],
          )
        : [],
    ),
    coverageArea: cast(
      response.CoverageArea
        ? mapCoverageAreaResponseToProductCoverageArea(response.CoverageArea)
        : null,
    ),
    coverageAreaId: response.coverage_area_id,
    coverageAreas: cast(
      response.ProductCoverageAreas
        ? mapProductCoverageAreasResponseToProductCoverageAreas(
            response.ProductCoverageAreas,
          )
        : [],
    ),
    coverageAreasDisplay: response.ProductCoverageAreas?.reduce(
      (output, area, index) =>
        `${output}${area.CoverageArea?.name}${
          index < response.ProductCoverageAreas.length - 1 ? ', ' : ''
        }`,
      '',
    ),
    parentProduct: cast(
      response.ParentProduct
        ? mapParentProductResponseToParentProduct(response.ParentProduct)
        : null,
    ),
    referenceProductId: response.reference_product_id,
    selected: false,
    supplierId: response.supplier_id ?? null,
    hasOngoingResellerOrder: !!response.has_ongoing_reseller_order, // attribute only sent by backend if is supplier account
  };
}

export function mapSuppliersResponseToSuppliers(
  row: ResellerSupplierRow,
): ResellerSupplier {
  return {
    id: row.id,
    resellerId: row.reseller_id,
    supplierId: row.supplier_id,
    isActive: row.is_active,
    address: row.address,
    categoryId: row.category_id,
    categoryName: row.category_name,
    sellerJopan: row.seller_jopan,
    reseller: row.reseller,
    countOrderCompleted: row.count_order_completed,
    gmvValue: row.gmv_value,
    hasOngoingOrder: row.has_ongoing_order,
    ongoingOrder: row.ongoing_order,
    User: {
      id: row.User.id,
      name: row.User.name,
      username: row.User.username,
      phone: row.User.phone,
    },
  };
}

export function mapSellersResponseToSellers(
  row: ResellerSellerRow,
): ResellerSeller {
  return {
    id: row.id,
    userId: row.user_id,
    stateId: row.state_id,
    cityId: row.city_id,
    districtId: row.district_id,
    subdistrictId: row.subdistrict_id,
    zipcode: row.zipcode,
    latitude: row.latitude,
    longitude: row.longitude,
    address: row.address,
    additionalAddress: row.additional_address,
    categoryId: row.category_id,
    categoryName: row.category_name,
    profilePicture: row.profile_picture,
    deliveryType: row.delivery_type,
    sellerJopan: row.seller_jopan,
    featuredWholesaler: row.featured_wholesaler,
    deliveryArea: row.delivery_area,
    startClosedAt: row.start_closed_at,
    endClosedAt: row.end_closed_at,
    isSyncedBuyer: row.is_synced_buyer,
    reseller: row.reseller,
    digitalPayments: row.digital_payments,
    countOrderCompleted: row.count_order_completed,
    gmvValue: row.gmv_value,
    User: {
      name: row.User.name,
      username: row.User.username,
      phone: row.User.phone,
      email: row.User.email,
    },
  };
}

export function mapUnitResultToUnit(unitResponse: UnitResponse): Unit {
  return {
    id: unitResponse.id,
    name: unitResponse.name,
    createdAt: unitResponse.created_at ?? '',
    deletedAt: unitResponse.deleted_at ?? '',
    updatedAt: unitResponse.updated_at ?? '',
    userId: unitResponse.user_id ?? '',
    countProductUsingUnit: unitResponse.count_product_using_unit ?? 0,
  };
}

export const MSG_GLOBAL_ERROR = 'Terjadi kesalahan, silakan coba lagi';
export const MSG_GLOBAL_SUCCESS = 'Request berhasil';
export const MSG_GLOBAL_RESTRICTED_ACCESS = 'Anda tidak memiliki akses';

// ORDER STATUS
export const ORDER_STATUS_CREATED = 'created';
export const ORDER_STATUS_ACCEPTED = 'accepted';
export const ORDER_STATUS_SHIPPED = 'shipped';
export const ORDER_STATUS_READY_TO_PICKUP = 'ready_pickup'; // frontend label if order (status === shipped && delivery_type === pickup)
export const ORDER_STATUS_ON_DELIVERY = 'on_delivery';
export const ORDER_STATUS_COMPLETED = 'completed';
export const ORDER_STATUS_CANCELLED = 'cancelled';

// ORDER DELIVERY TYPE
export const ORDER_DELIVERY_TYPE_DELIVERY = 'delivery';
export const ORDER_DELIVERY_TYPE_PICKUP = 'pickup';
export const ORDER_DELIVERY_TYPE_DELIVERY_PICKUP = 'delivery_pickup';

// ORDER TYPE
export const ORDER_TYPE_ONLINE = 'online';
export const ORDER_TYPE_OFFLINE = 'offline';

// PAYMENT STATUS
export const PAYMENT_STATUS_PAID = 'paid';
export const PAYMENT_STATUS_PAID_TRANSFER = 'paid_transfer';
export const PAYMENT_STATUS_PAID_SALES = 'paid_sales';
export const PAYMENT_STATUS_PAID_COD = 'paid_cod';
export const PAYMENT_STATUS_UNPAID = 'unpaid';

// PAYMENT METHOD
export const PAYMENT_METHOD_CASH = 'cash';
export const PAYMENT_METHOD_TEMPO = 'tempo';

export const DEFAULT_ITEM_PER_PAGE = 10;
export const DEFAULT_IMAGE_PER_PRODUCT = 6;
export const DEFAULT_SUPPLIER_PRODUCT_PER_PAGE = 20;

// DEFAULT ROLE IDS
export const SUPERADMIN_ID = 1;
export const ADMIN_ID = 2;
export const SALES_ID = 3;
export const DELIVERYMAN_ID = 4;
export const CASHIER_ID = 5;
export const AREA_SALES_MANAGER_ID = 8;
export const FINANCE_ADMIN_ID = 9;
export const SUPERVISOR_SALES_ID = 10;
export const EXTERNAL_ADMIN_ID = 11; // same as ADMIN_ID but this role ID is for supplier/external accounts (different permission assignments)
export const TEAM_HO_ID = 13;
export const VERIFICATOR_ID = 12;

// DEFAULT STAFF PERMISSION IDS
export const PERMISSION_WEB_TAMBAH_PRODUK = 3;
export const PERMISSION_WEB_UBAH_PRODUK = 4;
export const PERMISSION_WEB_TOLAK_DAN_BATALKAN_PESANAN = 5;
export const PERMISSION_WEB_TERIMA_PESANAN = 6;
export const PERMISSION_WEB_UBAH_HAPUS_PRODUK_DI_PESANAN = 7;
export const PERMISSION_WEB_TENTUKAN_BIAYA_ADMIN = 8;
export const PERMISSION_WEB_SET_TRANSAKSI_JADI_LUNAS = 9;
export const PERMISSION_WEB_BAGIKAN_INVOICE_PESANAN = 10;
export const PERMISSION_WEB_BAGIKAN_KATALOG_PRODUK = 11; // unused
export const PERMISSION_WEB_KONFIRMASI_PESANAN = 12;
export const PERMISSION_WEB_ATUR_JATUH_TEMPO = 13;
export const PERMISSION_APP_SET_PESANAN_JADI_SELESAI_DIANTAR = 14;
export const PERMISSION_APP_HUBUNGI_BUYER_VIA_WHATSAPP = 15;
export const PERMISSION_APP_UBAH_PESANAN = 16;
export const PERMISSION_APP_SET_TRANSAKSI_JADI_LUNAS = 17;
export const PERMISSION_WEB_UBAH_PROFIL = 18;
export const PERMISSION_WEB_PENGATURAN_PEMBAYARAN = 19; // unused
export const PERMISSION_WEB_PENGATURAN_PENGIRIMAN = 20;
export const PERMISSION_WEB_SET_PESANAN_JADI_SELESAI = 21;
export const PERMISSION_WEB_HAPUS_PRODUK = 22;
export const PERMISSION_APP_TOLAK_DAN_BATALAKAN_PESANAN = 23;
export const PERMISSION_APP_TERIMA_PESANAN = 24;
export const PERMISSION_APP_KONFIRMASI_PESANAN = 25;
export const PERMISSION_APP_BAGIKAN_INVOICE_PESANAN = 26;
export const PERMISSION_APP_SELESAIKAN_PESANAN_DIAMBIL_SENDIRI = 27;
export const PERMISSION_APP_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY = 28;
export const PERMISSION_WEB_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY_DARI_WEB = 29;
export const PERMISSION_APP_TAMBAH_PRODUK_DARI_MOBILE = 30;
export const PERMISSION_APP_UBAH_PRODUK_DARI_MOBILE = 31;
export const PERMISSION_APP_HAPUS_PRODUK_DARI_MOBILE = 32;
export const PERMISSION_WEB_UBAH_PESANAN_DAN_UBAH_SALES_DARI_WEB = 33;
export const PERMISSION_APP_UBAH_PESANAN_DAN_UBAH_SALES_DARI_MOBILE = 34;
export const PERMISSION_APP_SUPERADMIN_ATAU_STAF_DAPAT_MEMBUAT_PESANAN_DI_APLIKASI = 35;
export const PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MEMPROSES_PEMBAYARAN = 36;
export const PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENAMBAH_PELANGGAN = 37;
export const PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENAMBAH_DISKON_PRODUK = 38;
export const PERMISSION_APP_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_JADWAL_OPERASIONAL_TOKO_DENGAN_MENGGUNAKAN_APLIKASI = 39;
export const PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_LIST_SUPPLIER = 40;
export const PERMISSION_WEB_FINANCE_ADMIN_RESELLER_BISA_MENGAKSES_MENU_PEMBAYARAN = 60;
export const PERMISSION_WEB_FINANCE_ADMIN_RESELLER_BISA_MENERIMA_DAN_MENOLAK_PEMBAYARAN = 61;
export const PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MELIHAT_DAFTAR_STAFF = 62;
export const PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MENAMBAHKAN_STAFF = 63;
export const PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MEMPERBAHARUI_INFORMASI_KEANGGOTAAN_STAFF = 64;
export const PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MENGHAPUS_AKUN_STAFF = 65;
export const PERMISSION_WEB_SUPERADMIN_TAMBAH_PRODUK_PADA_PESANAN = 66;
export const PERMISSION_APP_SUPERADMIN_TAMBAH_PRODUK_PADA_PESANAN = 67;
export const PERMISSION_WEB_TENTUKAN_BIAYA_PENGIRIMAN = 68;
export const PERMISSION_WEB_SUPERADMIN_ATAU_ADMIN_BERIZIN_BISA_TERIMA_ATAU_TOLAK_VERIFIKASI_TOKO = 71;
export const PERMISSION_WEB_ATUR_LIMIT_TEMPO_TOKO = 73;
export const PERMISSION_WEB_UBAH_BIAYA_ADMIN_DAN_PENGIRIMAN_PADA_PESANAN = 74;
export const PERMISSION_WEB_TAMBAH_DISKON_PADA_PESANAN = 75;
export const PERMISSION_WEB_UBAH_METODE_PEMBAYARAN_PADA_PESANAN = 76;
export const PERMISSION_WEB_ATUR_LIMIT_BELANJA = 77;
export const PERMISSION_ALL_MANAJEMEN_PESANAN_TRADING = 78;
export const PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_PESANAN_SUPPLIER = 79;
export const PERMISSION_WEB_TAMBAH_AREA_PENGIRIMAN = 81;
export const PERMISSION_WEB_UBAH_AREA_PENGIRIMAN = 82;
export const PERMISSION_WEB_HAPUS_AREA_PENGIRIMAN = 83;
export const PERMISSION_APP_MANAJEMEN_PESANAN_RETAIL = 84;
export const PERMISSION_APP_MANAJEMEN_PESANAN_GROSIR = 85;
export const PERMISSION_WEB_LIHAT_INFORMASI_PELANGGAN = 86;
export const PERMISSION_APP_MANAJEMEN_PRODUK_RETAIL_DARI_MOBILE = 87;
export const PERMISSION_APP_MANAJEMEN_PRODUK_GROSIR_DARI_MOBILE = 88;
export const PERMISSION_APP_SUPERAADMIN_ATAU_STAFF_DAPAT_MEMPROSES_PEMBAYARAN = 89;
export const PERMISSION_WEB_AKSES_TOMBOL_PEMBAYARAN_DARI_WEB = 90;

export const AREA_MANAGEMENT_PERMISSION_IDS = [
  PERMISSION_WEB_TAMBAH_AREA_PENGIRIMAN,
  PERMISSION_WEB_UBAH_AREA_PENGIRIMAN,
  PERMISSION_WEB_HAPUS_AREA_PENGIRIMAN,
];

export const PRODUCT_PERMISSION_IDS = [
  PERMISSION_WEB_TAMBAH_PRODUK,
  PERMISSION_WEB_UBAH_PRODUK,
  PERMISSION_WEB_HAPUS_PRODUK,
  PERMISSION_APP_TAMBAH_PRODUK_DARI_MOBILE,
  PERMISSION_APP_UBAH_PRODUK_DARI_MOBILE,
  PERMISSION_APP_HAPUS_PRODUK_DARI_MOBILE,
  PERMISSION_APP_MANAJEMEN_PRODUK_RETAIL_DARI_MOBILE,
  PERMISSION_APP_MANAJEMEN_PRODUK_GROSIR_DARI_MOBILE,
];
export const ORDER_PERMISSION_IDS = [
  PERMISSION_WEB_TOLAK_DAN_BATALKAN_PESANAN,
  PERMISSION_WEB_TERIMA_PESANAN,
  PERMISSION_WEB_UBAH_HAPUS_PRODUK_DI_PESANAN,
  PERMISSION_WEB_SET_TRANSAKSI_JADI_LUNAS,
  PERMISSION_WEB_BAGIKAN_INVOICE_PESANAN,
  PERMISSION_WEB_KONFIRMASI_PESANAN,
  PERMISSION_WEB_ATUR_JATUH_TEMPO,
  PERMISSION_WEB_SET_PESANAN_JADI_SELESAI,
  PERMISSION_WEB_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY_DARI_WEB,
  PERMISSION_WEB_UBAH_PESANAN_DAN_UBAH_SALES_DARI_WEB,
  PERMISSION_APP_UBAH_PESANAN_DAN_UBAH_SALES_DARI_MOBILE,
  PERMISSION_WEB_SUPERADMIN_TAMBAH_PRODUK_PADA_PESANAN,
  PERMISSION_APP_SUPERADMIN_TAMBAH_PRODUK_PADA_PESANAN,
  PERMISSION_WEB_UBAH_BIAYA_ADMIN_DAN_PENGIRIMAN_PADA_PESANAN,
  PERMISSION_WEB_TAMBAH_DISKON_PADA_PESANAN,
  PERMISSION_ALL_MANAJEMEN_PESANAN_TRADING,
  PERMISSION_APP_MANAJEMEN_PESANAN_RETAIL,
  PERMISSION_APP_MANAJEMEN_PESANAN_GROSIR,
  PERMISSION_WEB_UBAH_METODE_PEMBAYARAN_PADA_PESANAN,
  PERMISSION_APP_SUPERAADMIN_ATAU_STAFF_DAPAT_MEMPROSES_PEMBAYARAN,
  PERMISSION_WEB_AKSES_TOMBOL_PEMBAYARAN_DARI_WEB,
];
export const SALES_PERMISSION_IDS = [
  PERMISSION_APP_UBAH_PESANAN,
  PERMISSION_APP_SET_TRANSAKSI_JADI_LUNAS,
  PERMISSION_APP_TOLAK_DAN_BATALAKAN_PESANAN,
  PERMISSION_APP_TERIMA_PESANAN,
  PERMISSION_APP_KONFIRMASI_PESANAN,
  PERMISSION_APP_BAGIKAN_INVOICE_PESANAN,
  PERMISSION_APP_SELESAIKAN_PESANAN_DIAMBIL_SENDIRI,
];
export const DELIVERY_PERMISSION_IDS = [
  PERMISSION_APP_SET_PESANAN_JADI_SELESAI_DIANTAR,
  PERMISSION_APP_HUBUNGI_BUYER_VIA_WHATSAPP,
  PERMISSION_APP_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY,
];
export const CASHIER_PERMISSION_IDS = [
  PERMISSION_APP_SUPERADMIN_ATAU_STAF_DAPAT_MEMBUAT_PESANAN_DI_APLIKASI,
  PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MEMPROSES_PEMBAYARAN,
  PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENAMBAH_PELANGGAN,
  PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENAMBAH_DISKON_PRODUK,
  PERMISSION_APP_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_JADWAL_OPERASIONAL_TOKO_DENGAN_MENGGUNAKAN_APLIKASI,
];
export const ACCOUNT_PERMISSION_IDS = [
  PERMISSION_WEB_UBAH_PROFIL,
  PERMISSION_WEB_TENTUKAN_BIAYA_ADMIN,
  PERMISSION_WEB_PENGATURAN_PENGIRIMAN,
  PERMISSION_WEB_TENTUKAN_BIAYA_PENGIRIMAN,
  PERMISSION_WEB_ATUR_LIMIT_BELANJA,
];

export const STAFF_PERMISSION_IDS = [
  PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MELIHAT_DAFTAR_STAFF,
  PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MENAMBAHKAN_STAFF,
  PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MEMPERBAHARUI_INFORMASI_KEANGGOTAAN_STAFF,
  PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MENGHAPUS_AKUN_STAFF,
];

export const SUPPLIER_PERMISSION_IDS = [
  PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_LIST_SUPPLIER,
  PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_PESANAN_SUPPLIER,
];
export const FINANCE_MANAGEMENT_PERMISSION_IDS = [
  PERMISSION_WEB_FINANCE_ADMIN_RESELLER_BISA_MENGAKSES_MENU_PEMBAYARAN,
  PERMISSION_WEB_FINANCE_ADMIN_RESELLER_BISA_MENERIMA_DAN_MENOLAK_PEMBAYARAN,
];
export const BUYER_MANAGEMENT_PERMISSION_IDS = [
  PERMISSION_WEB_SUPERADMIN_ATAU_ADMIN_BERIZIN_BISA_TERIMA_ATAU_TOLAK_VERIFIKASI_TOKO,
  PERMISSION_WEB_ATUR_LIMIT_TEMPO_TOKO,
  PERMISSION_WEB_LIHAT_INFORMASI_PELANGGAN,
];

// reseller exclusive permissions. user role permissions will be filtered by this array
export const RESELLER_PERMISSION_IDS = [
  // Order Permissions
  PERMISSION_ALL_MANAJEMEN_PESANAN_TRADING,
  PERMISSION_APP_MANAJEMEN_PESANAN_RETAIL,
  PERMISSION_APP_MANAJEMEN_PESANAN_GROSIR,
  // Account Permissions
  PERMISSION_WEB_ATUR_LIMIT_BELANJA,
];

// reseller & featured wholesaler exclusive permissions. user role permissions will be filtered by this array
export const RESELLER_AND_TRADING_PERMISSION_IDS = [
  // Order Permissions
  PERMISSION_WEB_UBAH_BIAYA_ADMIN_DAN_PENGIRIMAN_PADA_PESANAN,
  PERMISSION_WEB_TAMBAH_DISKON_PADA_PESANAN,
  PERMISSION_WEB_UBAH_METODE_PEMBAYARAN_PADA_PESANAN,
];

export const PERMISSION_DESCRIPTION = {
  [PERMISSION_WEB_TAMBAH_PRODUK]: 'Tambah Produk dari Web',
  [PERMISSION_WEB_UBAH_PRODUK]: 'Ubah Produk dari Web',
  [PERMISSION_WEB_TOLAK_DAN_BATALKAN_PESANAN]: 'Tolak & Batalkan Pesanan',
  [PERMISSION_WEB_TERIMA_PESANAN]: 'Terima Pesanan',
  [PERMISSION_WEB_UBAH_HAPUS_PRODUK_DI_PESANAN]:
    'Ubah & Hapus Produk di Pesanan',
  [PERMISSION_WEB_TENTUKAN_BIAYA_ADMIN]: 'Tentukan Biaya Admin',
  [PERMISSION_WEB_SET_TRANSAKSI_JADI_LUNAS]: 'Set Transaksi jadi Lunas',
  [PERMISSION_WEB_BAGIKAN_INVOICE_PESANAN]: 'Bagikan Invoice Pesanan',
  [PERMISSION_WEB_BAGIKAN_KATALOG_PRODUK]: 'Bagikan Katalog Produk',
  [PERMISSION_WEB_KONFIRMASI_PESANAN]: 'Konfirmasi Pesanan',
  [PERMISSION_WEB_ATUR_JATUH_TEMPO]: 'Atur Jatuh Tempo',
  [PERMISSION_APP_SET_PESANAN_JADI_SELESAI_DIANTAR]:
    'Set Pesanan jadi Selesai Diantar',
  [PERMISSION_APP_HUBUNGI_BUYER_VIA_WHATSAPP]: 'Hubungi Buyer Via Whatsapp',
  [PERMISSION_APP_UBAH_PESANAN]: 'Ubah Pesanan',
  [PERMISSION_APP_SET_TRANSAKSI_JADI_LUNAS]: 'Set Transaksi jadi Lunas',
  [PERMISSION_WEB_UBAH_PROFIL]: 'Ubah Profil',
  [PERMISSION_WEB_PENGATURAN_PEMBAYARAN]: 'Pengaturan Pembayaran',
  [PERMISSION_WEB_PENGATURAN_PENGIRIMAN]: 'Pengaturan Pengiriman',
  [PERMISSION_WEB_TENTUKAN_BIAYA_PENGIRIMAN]: 'Tentukan Biaya Pengiriman',
  [PERMISSION_WEB_SET_PESANAN_JADI_SELESAI]: 'Set Pesanan Jadi Selesai',
  [PERMISSION_WEB_HAPUS_PRODUK]: 'Hapus Produk',
  [PERMISSION_WEB_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY_DARI_WEB]:
    'Akses mengubah status pesanan menjadi on_delivery dari web',
  [PERMISSION_APP_TOLAK_DAN_BATALAKAN_PESANAN]: 'Tolak & Batalkan Pesanan',
  [PERMISSION_APP_TERIMA_PESANAN]: 'Terima Pesanan',
  [PERMISSION_APP_KONFIRMASI_PESANAN]: 'Konfirmasi Pesanan',
  [PERMISSION_APP_BAGIKAN_INVOICE_PESANAN]: 'Bagikan Invoice Pesanan',
  [PERMISSION_APP_SELESAIKAN_PESANAN_DIAMBIL_SENDIRI]:
    'Selesai-kan Pesanan Diambil Sendiri',
  [PERMISSION_APP_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY]:
    'Akses mengubah status pesanan menjadi on_delivery',
  [PERMISSION_APP_TAMBAH_PRODUK_DARI_MOBILE]: 'Tambah Produk dari mobile',
  [PERMISSION_APP_UBAH_PRODUK_DARI_MOBILE]: 'Ubah Produk dari mobile',
  [PERMISSION_APP_HAPUS_PRODUK_DARI_MOBILE]: 'Hapus Produk dari mobile',
  [PERMISSION_WEB_UBAH_PESANAN_DAN_UBAH_SALES_DARI_WEB]:
    'Ubah Pesanan & Ubah Sales dari web',
  [PERMISSION_APP_UBAH_PESANAN_DAN_UBAH_SALES_DARI_MOBILE]:
    'Ubah Pesanan & Ubah Sales dari mobile',
  [PERMISSION_WEB_SUPERADMIN_TAMBAH_PRODUK_PADA_PESANAN]:
    'Tambah Produk pada Pesanan dari web',
  [PERMISSION_APP_SUPERADMIN_TAMBAH_PRODUK_PADA_PESANAN]:
    'Tambah Produk pada Pesanan dari mobile',
  [PERMISSION_APP_SUPERADMIN_ATAU_STAF_DAPAT_MEMBUAT_PESANAN_DI_APLIKASI]:
    'Superadmin atau staf dapat membuat pesanan di aplikasi',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MEMPROSES_PEMBAYARAN]:
    'Superadmin atau staf dapat memproses pembayaran',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENAMBAH_PELANGGAN]:
    'Superadmin atau staf dapat menambah pelanggan',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENAMBAH_DISKON_PRODUK]:
    'Superadmin atau staf dapat menambah diskon produk',
  [PERMISSION_APP_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_JADWAL_OPERASIONAL_TOKO_DENGAN_MENGGUNAKAN_APLIKASI]:
    'Superadmin atau staf dapat mengatur jadwal operasional toko dengan menggunakan aplikasi',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_LIST_SUPPLIER]:
    'Superadmin atau staf dapat mengatur list supplier',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_PESANAN_SUPPLIER]:
    'Superadmin atau staf dapat mengatur pesanan supplier',
  [PERMISSION_WEB_FINANCE_ADMIN_RESELLER_BISA_MENGAKSES_MENU_PEMBAYARAN]:
    'Akses menu pembayaran',
  [PERMISSION_WEB_FINANCE_ADMIN_RESELLER_BISA_MENERIMA_DAN_MENOLAK_PEMBAYARAN]:
    'Terima dan tolak bukti pembayaran',
  [PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MELIHAT_DAFTAR_STAFF]:
    'Superadmin atau admin berizin bisa melihat daftar staff',
  [PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MENAMBAHKAN_STAFF]:
    'Superadmin atau admin berizin bisa menambahkan staff',
  [PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MEMPERBAHARUI_INFORMASI_KEANGGOTAAN_STAFF]:
    'Superadmin atau admin berizin bisa memperbaharui informasi keanggotaan staff',
  [PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MENGHAPUS_AKUN_STAFF]:
    'Superadmin atau admin berizin bisa menghapus akun staff',
  [PERMISSION_WEB_SUPERADMIN_ATAU_ADMIN_BERIZIN_BISA_TERIMA_ATAU_TOLAK_VERIFIKASI_TOKO]:
    'Superadmin atau admin berizin bisa terima atau tolak verifikasi toko',
  [PERMISSION_WEB_ATUR_LIMIT_TEMPO_TOKO]: 'Atur Limit Tempo Toko',
  [PERMISSION_WEB_UBAH_BIAYA_ADMIN_DAN_PENGIRIMAN_PADA_PESANAN]:
    'Ubah Biaya Admin & Pengiriman pada Pesanan',
  [PERMISSION_WEB_TAMBAH_DISKON_PADA_PESANAN]: 'Tambah Diskon pada Pesanan',
  [PERMISSION_WEB_UBAH_METODE_PEMBAYARAN_PADA_PESANAN]:
    'Ubah Metode Pembayaran pada Pesanan',
  [PERMISSION_WEB_ATUR_LIMIT_BELANJA]: 'Atur Minimal & Maximal Belanja',
  [PERMISSION_ALL_MANAJEMEN_PESANAN_TRADING]: 'Manajemen Pesanan Trading',
  [PERMISSION_WEB_LIHAT_INFORMASI_PELANGGAN]: 'Lihat Informasi Pelanggan',
  [PERMISSION_APP_MANAJEMEN_PESANAN_RETAIL]:
    'Kelola Pesanan Retail dari Mobile',
  [PERMISSION_APP_MANAJEMEN_PESANAN_GROSIR]:
    'Kelola Pesanan Grosir dari Mobile',
  [PERMISSION_APP_MANAJEMEN_PRODUK_RETAIL_DARI_MOBILE]:
    'Kelola Produk Retail dari Mobile',
  [PERMISSION_APP_MANAJEMEN_PRODUK_GROSIR_DARI_MOBILE]:
    'Kelola Produk Grosir dari Mobile',
  [PERMISSION_WEB_TAMBAH_AREA_PENGIRIMAN]: 'Tambah Area Pengiriman',
  [PERMISSION_WEB_UBAH_AREA_PENGIRIMAN]: 'Ubah Area Pengiriman',
  [PERMISSION_WEB_HAPUS_AREA_PENGIRIMAN]: 'Hapus Area Pengiriman',
  [PERMISSION_APP_SUPERAADMIN_ATAU_STAFF_DAPAT_MEMPROSES_PEMBAYARAN]:
    'Superadmin atau staf dapat memproses pembayaran',
  [PERMISSION_WEB_AKSES_TOMBOL_PEMBAYARAN_DARI_WEB]:
    'Akses Tombol Pembayaran dari Web',
};
export const PERMISSION_ACCESS_PLATFORM = {
  [PERMISSION_WEB_TAMBAH_PRODUK]: 'web',
  [PERMISSION_WEB_UBAH_PRODUK]: 'web',
  [PERMISSION_WEB_TOLAK_DAN_BATALKAN_PESANAN]: 'web',
  [PERMISSION_WEB_TERIMA_PESANAN]: 'web',
  [PERMISSION_WEB_UBAH_HAPUS_PRODUK_DI_PESANAN]: 'web',
  [PERMISSION_WEB_TENTUKAN_BIAYA_ADMIN]: 'web',
  [PERMISSION_WEB_SET_TRANSAKSI_JADI_LUNAS]: 'web',
  [PERMISSION_WEB_BAGIKAN_INVOICE_PESANAN]: 'web',
  [PERMISSION_WEB_BAGIKAN_KATALOG_PRODUK]: 'web',
  [PERMISSION_WEB_KONFIRMASI_PESANAN]: 'web',
  [PERMISSION_WEB_ATUR_JATUH_TEMPO]: 'web',
  [PERMISSION_APP_SET_PESANAN_JADI_SELESAI_DIANTAR]: 'app',
  [PERMISSION_APP_HUBUNGI_BUYER_VIA_WHATSAPP]: 'app',
  [PERMISSION_APP_UBAH_PESANAN]: 'app',
  [PERMISSION_APP_SET_TRANSAKSI_JADI_LUNAS]: 'app',
  [PERMISSION_WEB_UBAH_PROFIL]: 'web',
  [PERMISSION_WEB_PENGATURAN_PEMBAYARAN]: 'web',
  [PERMISSION_WEB_PENGATURAN_PENGIRIMAN]: 'web',
  [PERMISSION_WEB_TENTUKAN_BIAYA_PENGIRIMAN]: 'web',
  [PERMISSION_WEB_SET_PESANAN_JADI_SELESAI]: 'web',
  [PERMISSION_WEB_HAPUS_PRODUK]: 'web',
  [PERMISSION_WEB_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY_DARI_WEB]:
    'web',
  [PERMISSION_APP_TOLAK_DAN_BATALAKAN_PESANAN]: 'app',
  [PERMISSION_APP_TERIMA_PESANAN]: 'app',
  [PERMISSION_APP_KONFIRMASI_PESANAN]: 'app',
  [PERMISSION_APP_BAGIKAN_INVOICE_PESANAN]: 'app',
  [PERMISSION_APP_SELESAIKAN_PESANAN_DIAMBIL_SENDIRI]: 'app',
  [PERMISSION_APP_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY]: 'app',
  [PERMISSION_APP_TAMBAH_PRODUK_DARI_MOBILE]: 'app',
  [PERMISSION_APP_UBAH_PRODUK_DARI_MOBILE]: 'app',
  [PERMISSION_APP_HAPUS_PRODUK_DARI_MOBILE]: 'app',
  [PERMISSION_WEB_UBAH_PESANAN_DAN_UBAH_SALES_DARI_WEB]: 'web',
  [PERMISSION_APP_UBAH_PESANAN_DAN_UBAH_SALES_DARI_MOBILE]: 'app',
  [PERMISSION_WEB_SUPERADMIN_TAMBAH_PRODUK_PADA_PESANAN]: 'web',
  [PERMISSION_APP_SUPERADMIN_TAMBAH_PRODUK_PADA_PESANAN]: 'app',
  [PERMISSION_APP_SUPERADMIN_ATAU_STAF_DAPAT_MEMBUAT_PESANAN_DI_APLIKASI]:
    'app',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MEMPROSES_PEMBAYARAN]: 'all',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENAMBAH_PELANGGAN]: 'all',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENAMBAH_DISKON_PRODUK]: 'all',
  [PERMISSION_APP_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_JADWAL_OPERASIONAL_TOKO_DENGAN_MENGGUNAKAN_APLIKASI]:
    'app',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_LIST_SUPPLIER]: 'all',
  [PERMISSION_ALL_SUPERADMIN_ATAU_STAF_DAPAT_MENGATUR_PESANAN_SUPPLIER]: 'all',
  [PERMISSION_WEB_FINANCE_ADMIN_RESELLER_BISA_MENGAKSES_MENU_PEMBAYARAN]: 'web',
  [PERMISSION_WEB_FINANCE_ADMIN_RESELLER_BISA_MENERIMA_DAN_MENOLAK_PEMBAYARAN]:
    'web',
  [PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MELIHAT_DAFTAR_STAFF]:
    'web',
  [PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MENAMBAHKAN_STAFF]: 'web',
  [PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MEMPERBAHARUI_INFORMASI_KEANGGOTAAN_STAFF]:
    'web',
  [PERMISSION_WEB_SUPER_ADMIN_ATAU_ADMIN_BERIZIN_BISA_MENGHAPUS_AKUN_STAFF]:
    'web',
  [PERMISSION_WEB_SUPERADMIN_ATAU_ADMIN_BERIZIN_BISA_TERIMA_ATAU_TOLAK_VERIFIKASI_TOKO]:
    'web',
  [PERMISSION_WEB_ATUR_LIMIT_TEMPO_TOKO]: 'web',
  [PERMISSION_WEB_UBAH_BIAYA_ADMIN_DAN_PENGIRIMAN_PADA_PESANAN]: 'web',
  [PERMISSION_WEB_TAMBAH_DISKON_PADA_PESANAN]: 'web',
  [PERMISSION_WEB_UBAH_METODE_PEMBAYARAN_PADA_PESANAN]: 'web',
  [PERMISSION_WEB_ATUR_LIMIT_BELANJA]: 'web',
  [PERMISSION_ALL_MANAJEMEN_PESANAN_TRADING]: 'all',
  [PERMISSION_WEB_LIHAT_INFORMASI_PELANGGAN]: 'web',
  [PERMISSION_APP_MANAJEMEN_PESANAN_RETAIL]: 'app',
  [PERMISSION_APP_MANAJEMEN_PESANAN_GROSIR]: 'app',
  [PERMISSION_APP_MANAJEMEN_PRODUK_RETAIL_DARI_MOBILE]: 'app',
  [PERMISSION_APP_MANAJEMEN_PRODUK_GROSIR_DARI_MOBILE]: 'app',
  [PERMISSION_WEB_TAMBAH_AREA_PENGIRIMAN]: 'web',
  [PERMISSION_WEB_UBAH_AREA_PENGIRIMAN]: 'web',
  [PERMISSION_WEB_HAPUS_AREA_PENGIRIMAN]: 'web',
  [PERMISSION_APP_SUPERAADMIN_ATAU_STAFF_DAPAT_MEMPROSES_PEMBAYARAN]: 'app',
};

export const DEFAULT_PRODUCT_CATEGORY_ICON = '/img/category/default.svg';

export const PRODUCT_CATEGORY_ICONS = [
  { name: 'Buku', url: '/img/category/buku.svg' },
  { name: 'Peralatan Dapur', url: '/img/category/peralatan-dapur.svg' },
  { name: 'Fashion Anak Bayi', url: '/img/category/fashion-anak-bayi.svg' },
  { name: 'Fashion Muslim', url: '/img/category/fashion-muslim.svg' },
  { name: 'Fashion Wanita', url: '/img/category/fashion-wanita.svg' },
  { name: 'Fashion Pria', url: '/img/category/fashion-pria.svg' },
  { name: 'Musik', url: '/img/category/musik.svg' },
  { name: 'Gaming', url: '/img/category/gaming.svg' },
  { name: 'Mainan', url: '/img/category/mainan.svg' },
  { name: 'Handphone', url: '/img/category/handphone.svg' },
  { name: 'Kebutuhan Bayi', url: '/img/category/kebutuhan-bayi.svg' },
  { name: 'Kamera', url: '/img/category/kamera.svg' },
  { name: 'Kecantikan', url: '/img/category/kecantikan.svg' },
  { name: 'Rumah Tangga', url: '/img/category/rumah-tangga.svg' },
  { name: 'Komputer', url: '/img/category/komputer.svg' },
  { name: 'Elektronik', url: '/img/category/elektronik.svg' },
  { name: 'Voucher Pulsa', url: '/img/category/voucher-pulsa.svg' },
  { name: 'Makanan', url: '/img/category/makanan.svg' },
  { name: 'Minuman', url: '/img/category/minuman.svg' },
  { name: 'Olahraga', url: '/img/category/olahraga.svg' },
  { name: 'Otomotif', url: '/img/category/otomotif.svg' },
  { name: 'Perlengkapan Hewan', url: '/img/category/perlengkapan-hewan.svg' },
  { name: 'Perlengkapan Pesta', url: '/img/category/perlengkapan-pesta.svg' },
  { name: 'Perawatan Tubuh', url: '/img/category/perawatan-tubuh.svg' },
  { name: 'Pertukangan', url: '/img/category/pertukangan.svg' },
  { name: 'Bakery', url: '/img/category/bakery.svg' },
  { name: 'Obat-obatan', url: '/img/category/obat-obatan.svg' },
  { name: 'Stationary', url: '/img/category/stationary.svg' },
  { name: 'Lainnya', url: '/img/category/lainnya.svg' },
];

export const CUSTOMER_NAME_OPTS = [
  { key: 'empty', value: 'Kosong' },
  { key: 'customer_name', value: 'Nama Pelanggan' },
  { key: 'transaction_id', value: 'No. Transaksi' },
];

export const CUSTOMER_ADDRESS_OPTS = [
  { key: 'empty', value: 'Kosong' },
  { key: 'customer_address', value: 'Alamat Pelanggan' },
];

export const SELLER_TYPE = {
  RESELLER: 'reseller',
  TRADING: 'trading',
};

export const PRODUCT_DESCRIPTION_MAX_LENGTH = 4000;
export const PRODUCT_CATEGORY_DESCRIPTION_MAX_LENGTH = 4000;
export const PRODUCT_IMAGE_UPLOAD_MAX_SLOT = 6;
export const ORDER_UPDATED = 'ORDER_UPDATED';
export const ORDER_UPDATED_SUCCESS = '1';

export const ORDER_BY_PRICE = 'price';
export const ORDER_BY_NEWEST = 'newest';
export const ORDER_BY_NAME = 'name';
export const SORT_BY_ASC = 'asc';
export const SORT_BY_DESC = 'desc';

export const IS_STAFF = 'is_staff';

export const ORDER_LOG_EVENT_ITEM_PRICE_UPDATE = 'item_price_update';
export const ORDER_LOG_EVENT_PRODUCT_DELETED = 'product_deleted';

export const MAX_TEMPO_LIMIT = 999999999;
export const MAX_DEFAULT_NUMERIC_INPUT = 9999999999;
export const MAX_DEFAULT_DAYS_INPUT = 365;

export const WHITELISTED_AREAS = [
  'CRA-2022011415264208068289272',
  'CRA-2022011209253919912080404',
  'CRA-2022030908461501058353265',
];

export const WHITELISTED_AREAS_ORDER_LIMIT_SETTINGS = {
  cashMin: 150000,
  tempoMin: 300000,
  tempoMax: 100000000,
};

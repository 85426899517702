import React, { type CSSProperties, type ReactNode } from 'react';
import Link, { type LinkProps } from 'next/link';

interface Props extends LinkProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  target?: string;
  rel?: string;
  onClick?: () => void;
}

export const Anchor = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  className,
  style,
  children,
  target,
  rel,
  onClick,
}: Props) => (
  <Link
    href={href}
    as={as}
    replace={replace}
    scroll={scroll}
    shallow={shallow}
    passHref={passHref}
    prefetch={prefetch}
    locale={locale}
    className={className}
    style={style}
    target={target}
    rel={rel}
    onClick={onClick}
  >
    {children}
  </Link>
);

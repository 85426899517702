import React, { useEffect, useState } from 'react';
import { Anchor } from '../anchor';
import module from './../../package.json';

export const AdminFooter: React.FunctionComponent<
  Record<string, never>
> = () => {
  const [version, setVersion] = useState<string>('development');
  useEffect(() => {
    setVersion(module?.version);
  }, []);
  return (
    <footer className="w-full object-bottom flex flex-row justify-between p-8 text-secondary-gray">
      <section>
        <Anchor
          href="https://jooalan.id/privacy-policy"
          className="pr-2 hover:underline"
          target="_blank"
        >
          Kebijakan Privasi
        </Anchor>
        &bull;
        <Anchor
          href="https://jooalan.id/terms-and-conditions"
          className="px-2 hover:underline"
          target="_blank"
        >
          Syarat dan Ketentuan
        </Anchor>
        &bull;
        <Anchor
          href="https://api.whatsapp.com/send/?phone=6281910000118"
          className="px-2 hover:underline"
          target="_blank"
        >
          Bantuan
        </Anchor>
        &bull;
        <Anchor
          href="https://jooalan.id"
          className="pl-2 hover:underline"
          target="_blank"
        >
          Jooalan &#169; {new Date().getFullYear()}
        </Anchor>
      </section>
      <section>
        <p>App Version {version}</p>
      </section>
    </footer>
  );
};

import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from './index.module.css';
import { LoadingContext } from '../modals/loading';

export interface ButtonProps {
  id?: string;
  type?: 'primary' | 'secondary' | 'tertiary';
  disabled?: boolean;
  className?: string;
  onClick?: (clickParam: any) => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  title?: string;
  fullWidth?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      type,
      disabled,
      className,
      onClick,
      children,
      style,
      title,
      fullWidth,
      ...rest
    },
    ref,
  ) => {
    const { content: loading } = useContext(LoadingContext);
    const propsClass = {
      [styles.buttonPrimary]: type === 'primary',
      [styles.buttonSecondary]: type === 'secondary',
      [styles.buttonTertiary]: type === 'tertiary',
      [styles.buttonDisabled]: disabled,
      [styles.buttonFull]: fullWidth,
    };

    const handleClick = (clickParam) => {
      if (disabled) {
        return;
      }

      onClick(clickParam);
    };

    return (
      <button
        id={id}
        className={classNames(styles.button, propsClass, className)}
        style={style}
        onClick={handleClick}
        type="button"
        disabled={disabled || loading.visible}
        ref={ref}
        title={title}
        {...rest}
      >
        {children}
      </button>
    );
  },
);

Button.defaultProps = {
  id: null,
  type: 'primary',
  disabled: false,
  onClick: () => {},
};

import { type DiscountType } from './enums';
import { type Product } from '../stores/product-store/product-store.model';
import { type DeliveryPointArea } from '../modules/area-management-list/delivery-area-modal/delivery-point-modal';
import {
  type City,
  type District,
  type State,
  type Subdistrict,
} from '../stores/wholesaler-store';

export interface CustomPageProps {
  isReseller?: boolean;
  isSupplier?: boolean;
  isFeaturedWholesaler?: boolean;
}
export type StyleSheet = Record<string, React.CSSProperties>;
export interface OtpRequestParam {
  phone: string;
  media_type?: 'wa' | 'sms';
  app: 'credimart';
}

export interface TokenizeOTPRequestParam extends OtpRequestParam {
  token: string;
}

export interface OtpVerifyParam {
  phone: string;
  sendtype: string | number;
  otp: string;
  app: 'credimart';
}

export interface LoginStaffParams {
  username: string;
  password: string;
  token: string;
}

export interface SellerStaffPermissionsResponse {
  permission_id: number;
  Permission: {
    description?: string;
    access_platform?: string;
  };
}

export interface StaffRolePermissionObject {
  permission_id: number;
  seller_id: string;
}

export interface StaffRolePermissionResponse {
  id: string;
  role_id: number;
  seller_id: string;
  username: string;
  Role: {
    name?: string;
    id?: number;
    RolePermissions: StaffRolePermissionObject[];
  };
  SellerStaffPermissions: SellerStaffPermissionsResponse[];
}

export interface UserResponse {
  id: string;
  username: string;
  phone: string;
  name: string;
  referral_code?: string;
  verified: boolean;
  created_at: string;
}

export interface CityResponse {
  id: number;
  state_id: number;
  name: string;
}

export interface StateResponse {
  id: number;
  name: string;
}

export interface DistrictResponse {
  id: number;
  city_id: number;
  name: string;
}

export interface SubdistrictResponse {
  id: number;
  district_id: number;
  name: string;
}
export interface SellerProfileResponse {
  additional_address: string;
  address: string;
  category_id?: string;
  City?: CityResponse;
  count_orders: number;
  count_products: number;
  delivery_area?: string;
  delivery_type?: string;
  digital_payments: boolean;
  District?: DistrictResponse;
  end_closed_at: Date;
  featured_wholesaler: boolean;
  id: string;
  reseller: boolean;
  latitude?: number;
  longitude?: number;
  min_total_purchase?: number;
  profile_picture?: string;
  seller_jopan: boolean;
  start_open_at?: Date;
  State?: StateResponse;
  Subdistrict?: SubdistrictResponse;
  supplier: boolean;
  User: UserResponse;
  zipcode?: string;
  owner_name: string | null;
  reseller_min_order_cash_limit?: number;
  reseller_max_order_cash_limit?: number;
  reseller_min_order_tempo_limit?: number;
  reseller_max_order_tempo_limit?: number;
  trading_min_order_cash_limit?: number;
  trading_max_order_cash_limit?: number;
  trading_min_order_tempo_limit?: number;
  trading_max_order_tempo_limit?: number;
  min_order_cash_limit?: number;
  max_order_cash_limit?: number;
  min_order_tempo_limit?: number;
  max_order_tempo_limit?: number;
  seller_hub_id?: string;
  bank_account?: number;
  bank_code?: number;
  bank_owner_name?: string;
  bank_name?: string;
}

export interface User {
  id: string;
  phone: string;
  name: string;
  username?: string;
  email?: string;
  referral_code?: string;
  verified?: boolean;
  country_code?: string;
  created_at?: string;
  wholesaler_id?: string;
}

export interface SellerProfile {
  additionalAddress?: string;
  address: string;
  categoryId: string;
  City?: City;
  countOrders: number;
  countProducts: number;
  delivery_area?: string;
  District?: District;
  deliveryArea?: string;
  deliveryType?: string;
  digitalPayments: boolean;
  featuredWholesaler: boolean;
  id: string;
  latitude: number;
  longitude: number;
  minTotalPurchase: number;
  profilePicture: string;
  reseller?: boolean;
  sellerJopan?: boolean;
  Subdistrict?: Subdistrict;
  supplier: boolean;
  State?: State;
  User: User;
  zipcode?: string;
  ownerName: string | null;
  resellerMinOrderCashLimit?: number;
  resellerMaxOrderCashLimit?: number;
  resellerMinOrderTempoLimit?: number;
  resellerMaxOrderTempoLimit?: number;
  tradingMinOrderCashLimit?: number;
  tradingMaxOrderCashLimit?: number;
  tradingMinOrderTempoLimit?: number;
  tradingMaxOrderTempoLimit?: number;
  minOrderCashLimit?: number;
  maxOrderCashLimit?: number;
  minOrderTempoLimit?: number;
  maxOrderTempoLimit?: number;
  sellerHubId?: string;
  bankAccountNo?: number;
  bankCode?: number;
  bankOwnerName?: string;
  bankName?: string;
}

export interface SellerProfileData {
  name?: string;
  categoryId?: string;
  stateId?: number;
  cityId?: number;
  districtId?: number;
  subdistrictId?: number;
  zipcode?: string;
  phone?: string;
  email?: string;
  pin?: string;
  address?: string;
  latitude?: string;
  longitude?: string;
  profilePicture?: string;
  username?: string;
  additionalAddress?: string;
  photo?: File;
  deliveryArea?: string;
  reseller?: boolean;
  ownerName?: string;
  resellerMinOrderCashLimit?: number;
  resellerMinOrderTempoLimit?: number;
  resellerMaxOrderTempoLimit?: number;
  tradingMinOrderCashLimit?: number;
  tradingMinOrderTempoLimit?: number;
  tradingMaxOrderTempoLimit?: number;
  minOrderCashLimit?: number;
  minOrderTempoLimit?: number;
  maxOrderTempoLimit?: number;
  bankAccountNo?: number;
  bankCode?: number;
  bankOwnerName?: string;
  bankName?: string;
}

export interface PostSellerProfileParams {
  name?: string;
  category_id?: string;
  state_id?: number;
  city_id?: number;
  district_id?: number;
  subdistrict_id?: number;
  zipcode?: string;
  phone?: string;
  email?: string;
  seller_pin?: string;
  address?: string;
  latitude?: string;
  longitude?: string;
  profile_picture?: string;
  username?: string;
  additional_address?: string;
  delivery_area?: string;
  reseller?: boolean;
  owner_name?: string;
  reseller_min_order_cash_limit?: number;
  reseller_max_order_cash_limit?: number;
  reseller_min_order_tempo_limit?: number;
  reseller_max_order_tempo_limit?: number;
  trading_min_order_cash_limit?: number;
  trading_max_order_cash_limit?: number;
  trading_min_order_tempo_limit?: number;
  trading_max_order_tempo_limit?: number;
  min_order_cash_limit?: number;
  max_order_cash_limit?: number;
  min_order_tempo_limit?: number;
  max_order_tempo_max_limit?: number;
  bank_account?: number;
  bank_code?: number;
  bank_owner_name?: string;
  bank_name?: string;
}

export interface SellerCategory {
  key: string;
  value: string;
}

export interface PlaceIdResponse {
  place_id: string;
  formatted_address: string;
}

export interface PlaceDetailsResponse {
  result: {
    place_id: string;
    formatted_address: string;
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
  };
  parsed_data: {
    place_id: string;
    formatted_address: string;
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
    state: {
      state_id: number;
      state_name: string;
    };
    city: {
      city_id: number;
      city_name: string;
    };
    district: {
      district_id: number;
      district_name: string;
    };
    subdistrict: {
      subdistrict_id: number;
      subdistrict_name: string;
    };
    zipcode: {
      zipcode: string;
    };
  };
  status: string;
}

export interface OrderLimitsResponse {
  cash_min: number;
  tempo_min: number;
  tempo_max: number;
}

interface TempoFeeResponse {
  percentage_amount: number;
  due_days: number;
}

export interface AdminFeesResponse {
  cash_percentage_amount: number;
  cash_nominal_amount: number;
  tempo: TempoFeeResponse[];
}

export interface DeliveryAreaOrderSettingsResponse {
  order_limits: OrderLimitsResponse;
  admin_fees: AdminFeesResponse;
}

export interface DeliveryAreaDistrictResponse {
  id: number;
  state_id: number;
  city_id: number;
  district_id: number;
  state: {
    name: string;
  };
  city: {
    name: string;
  };
  district: {
    name: string;
  };
}

export interface DeliveryAreaDetailProductCountResponse {
  reseller: number;
  trading: number;
}

export interface DeliveryAreaDetailResponse {
  id: string;
  address: string;
  address_city_id: number;
  address_state_id: number;
  latitude: number;
  longitude: number;
  areas: DeliveryPointArea[];
  coverage_area_districts?: DeliveryAreaDistrictResponse[];
  name: string;
  phone: string;
  has_order_type_reseller: boolean;
  has_order_type_trading: boolean;
  order_type_reseller_settings: DeliveryAreaOrderSettingsResponse;
  order_type_trading_settings: DeliveryAreaOrderSettingsResponse;
  product_count: DeliveryAreaDetailProductCountResponse;
}

export interface PlaceSuggestionsResponse {
  place_id: string;
  description?: string;
  structured_formatting?: {
    main_text: string;
    secondary_text: string;
  };
  state?: {
    state_id: number;
    state_name: string;
  };
}

export interface GetDashboardSummaryResponse {
  total_count_unpaid_orders: number;
  total_orders: number;
  total_product_sold: number;
  total_value_orders: number;
  total_value_orders_in: number;
  total_value_unpaid_orders: number;
  total_view: number;
  total_profits: number;
}

export interface GetDashboardSummaryParams {
  start_date?: Date;
  end_date?: Date;
}

export interface GetProductsParam {
  limit?: number;
  offset?: number;
  name?: string;
  sku?: string;
  product_category_id?: string;
}

export interface PutProductImageParam {
  url: string;
  featured: boolean;
}

export interface PutProductUnitParam {
  unit: string;
  ratio: number;
  base_price: number;
  price: number;
  wholesale_price?: boolean;
  unit_type: null | 'credimart';
}

export interface PutProductParams extends StaffPlatformParams {
  name?: string;
  description?: string;
  images?: PutProductImageParam[];
  product_category_id?: string;
  available?: boolean;
  sku?: string;
  units?: PutProductUnitParam[];
  promo_active?: boolean;
  coverage_area_id?: string; // only if reseller account
}

export interface CreateProductBulkData {
  name: string;
  categoryName: string;
  sku: string;
  coverageAreaName1?: string;
  coverageAreaId1?: string;
  coverageAreaName2?: string;
  coverageAreaId2?: string;
  coverageAreaName3?: string;
  coverageAreaId3?: string;
  description: string;
  unit1: string;
  unit2?: string;
  unit3?: string;
  base1: number;
  base2?: number;
  base3?: number;
  price1: number;
  price2?: number;
  price3?: number;

  qty1a?: number;
  qty1b?: number;
  qty1c?: number;
  qty2a?: number;
  qty2b?: number;
  qty2c?: number;
  qty3a?: number;
  qty3b?: number;
  qty3c?: number;

  base1a?: number;
  base1b?: number;
  base1c?: number;
  base2a?: number;
  base2b?: number;
  base2c?: number;
  base3a?: number;
  base3b?: number;
  base3c?: number;

  price1a?: number;
  price1b?: number;
  price1c?: number;
  price2a?: number;
  price2b?: number;
  price2c?: number;
  price3a?: number;
  price3b?: number;
  price3c?: number;
}

export interface CreateProductImageParam extends PutProductImageParam {}

export interface CreateProductUnitParam {
  unit: string;
  base_price: number;
  price: number;
  wholesale_price?: boolean;
  discount_price?: number;
  min_quantity?: number;
  user_unit_id?: string;
  active?: boolean;
  ratio?: number;
  available_online?: boolean;
  product_id?: string;
  unit_type?: null | 'credimart';
  featured?: boolean;
}
export interface CreateProductParams extends StaffPlatformParams {
  name: string;
  description?: string;
  product_category_id?: string;
  available?: boolean;
  sku?: string;
  units: CreateProductUnitParam[];
  images?: CreateProductImageParam[];
  promo_active?: boolean;
  coverage_area_id?: string; // if multiple area -> 'CRA-123, CRA-456' (comma separated)
}

export interface CreateProductBulkParams extends CreateProductParams {
  category_name: string;
}

export interface PostBulkCreateProductParams extends StaffPlatformParams {
  products: CreateProductParams[];
}

export interface PutBulkActiveStatusProductsParams extends StaffPlatformParams {
  products: string[];
  available: boolean;
}

export interface DeleteBulkProductsParams {
  products: string[];
}

export interface ProductPriceUnitParams {
  unit: string;
  price: number;
  base_price: number;
  min_quantity?: number;
  user_unit_id?: string;
  wholesale_price?: boolean;
  unit_type?: null | 'credimart';
}

export interface ProductPriceParams {
  id: string;
  sku?: string;
  units: ProductPriceUnitParams[];
}

export interface PutBulkEditProductsParams extends StaffPlatformParams {
  products: ProductPriceParams[];
}

export interface ResellerEditProductParams {
  id: string;
  product_category_id: string;
  coverage_area_id: string;
}

export interface PutBulkEditResellerProductsParams extends StaffPlatformParams {
  products: ResellerEditProductParams[];
}

export interface PutBulkActiveStatusProductsResponse {
  update_products: Product[];
}

export interface UnitResponse {
  id: string;
  name: string;
  created_at?: string;
  deleted_at?: string;
  updated_at?: string;
  user_id?: string;
  count_product_using_unit?: number;
}

export interface UnitResult {
  count: number;
  rows: UnitResponse[];
}

export interface ProductResult {
  count: number;
  rows: ProductResponse[];
}

export interface GetOrdersParam {
  buyer_id?: string;
  limit?: number;
  offset?: number;
  order_number?: string;
  status?: string;
  payment_status?: string;
  delivery_type?: string;
  due_date?: string;
  start_at?: string;
  end_at?: string;
  customer_name?: string;
  wholesaler_id?: string;
  name?: string;
  type?: string;
  sales_id?: string;
  coverage_area_id?: string;
  is_report?: 'true' | 'false'; // if true, orders response will be limited to 7days max from the selection_range
  voucher?: string; // true | false | all
  order_type_transaction?: 'trading' | 'reseller'; // for reseller account only
}

export interface GetOrderLimitParam {
  buyer_id: string;
  coverage_area_id: string;
}

export interface SupplierOrdersCountParam {
  wholesaler_id?: string;
  start_at?: string;
  end_at?: string;
  status?: string;
  name?: string;
  customer_name?: string;
  order_number?: string;
}

export interface OrderResult {
  count: number;
  rows: OrderResponse[];
  changed_rows: OrderResponse[];
}

export interface OrderLimitResponse {
  reseller_min_order_cash_limit: number;
  reseller_max_order_cash_limit: number;
  reseller_min_order_tempo_limit: number;
  reseller_max_order_tempo_limit: number;
  trading_min_order_cash_limit: number;
  trading_max_order_cash_limit: number;
  trading_min_order_tempo_limit: number;
  trading_max_order_tempo_limit: number;
}

export interface GetResellerWholesalerResult {
  count: number;
  rows: GetResellerWholesalerResponse[];
}

export interface GetResellerWholesalerParam {
  limit?: number;
  offset?: number;
  name?: string;
  is_supplier_setting?: boolean;
  status?: 'true' | 'false';
}

export interface GetResellerWholesalerResponse {
  id: string;
  user_id: string;
  seller_jopan: true;
  reseller: false;
  User: User;
}

export interface GetResellerSettingsResult {
  id: string;
  reseller_id: string;
  name: string;
  name_setting: 'empty' | 'customer_name' | 'transaction_id';
  address_setting: 'empty' | 'customer_address';
  ResellerWholesalerSettings: GetResellerWholesalerSettingsResponse[];
}

export interface GetResellerWholesalerSettingsResponse {
  id: number;
  wholesaler_id: string;
  name: string;
  phone: string;
}

export interface PostResellerWholesalerSettingsRequest {
  id: string;
  name: string;
  phone: string;
}

export interface PostResellerSettingsPayload {
  name: string;
  name_setting: string;
  address_setting: string;
  wholesalers: PostResellerWholesalerSettingsRequest[];
}

export interface OrderCountResult {
  total_order?: number;
  total_order_created?: number;
  total_order_accepted?: number;
  total_order_shipped?: number;
  total_order_on_delivery?: number;
  total_order_cancelled?: number;
  total_order_completed?: number;
}

export interface SupplierOrderCountResult {
  total_order_accepted?: number;
  total_order?: number;
  total_order_cancelled?: number;
  total_order_completed?: number;
  total_order_created?: number;
  total_order_shipped?: number;
}

export interface CreateAdminFeeData {
  id?: string;
  type: 'cash' | 'tempo';
  percentageAmount: number;
  dueDays: number;
  amount: number;
  active: boolean;
  sellerType?: string;
}

export interface CreateAdminFeeParams {
  id?: string;
  type: 'cash' | 'tempo';
  percentage_amount: number;
  due_days: number;
  amount: number;
  active: boolean;
}

export interface DeliveryFeeData {
  type: 'cash' | 'tempo';
  amount: number;
  active: boolean;
  seller_type: 'reseller' | 'trading';
}

export interface AdminFeeResponse {
  id: string;
  type: 'cash' | 'tempo';
  percentage_amount: number;
  due_days: number;
  amount: number;
  active: boolean;
  created_at: string;
  deleted_at: string | null;
  seller_id: string;
  updated_at: string;
  seller_type: 'reseller' | 'trading' | null;
}

export interface OrderAdminFeeResponse extends AdminFeeResponse {}

export interface SellerDetailResponse {
  id: string;
  User: {
    name: string;
  };
}

export enum MorakLoanStatus {
  pending = 'pending',
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
}

export interface ReturnItemResponse {
  order_item_id: string; // same value with OrderItemResponse.id. order_item_id !== OrdersSupplier.orderItems[...].id
  order_item_logs_id: number;
  price: number;
  qty: number;
}

export interface OrderResponse {
  total_value: number;
  logistic_fee: number;
  additional_fee: number;
  admin_fee_id?: string;
  id: string;
  seller_id: string;
  buyer_id: string;
  transaction_number: string;
  payment_code?: string;
  payment_fee?: number;
  payment_link?: string;
  payment_method?: string;
  logistic_method?: string;
  customer_name?: string;
  customer_notes?: string;
  customer_phone: string;
  recipient_phone: string;
  order_notes?: string;
  address: string;
  status: string;
  payment_status: string;
  accepted_at?: string;
  delivery_at?: string;
  shipped_at?: string;
  cancelled_at?: string;
  completed_at?: string;
  deleted_at?: string;
  state_id: number;
  city_id: number;
  district_id: number;
  subdistrict_id: number;
  cancelled_reason?: string;
  paid_at?: string;
  paid_amount?: number;
  delivery_type?: string;
  delivery_man_id?: string;
  Deliveryman: {
    username?: string;
  };
  sales_id?: string;
  Sales: {
    username?: string;
  };
  revision: string;
  due_date?: string;
  latitude?: string;
  longitude?: string;
  created_at: string;
  updated_at: string;
  OrderItems: OrderItemResponse[];
  OrdersSupplier?: OrdersSupplierResponse;
  SellerAdminFee: OrderAdminFeeResponse;
  tracking_number?: string;
  tracking_img?: string;
  discount_percentage?: number;
  discount_amount?: number;
  SellerDetail?: SellerDetailResponse;
  is_reseller?: boolean;
  will_expire_at?: string;
  type?: string;
  Cashier: {
    id?: string;
    username?: string;
  };
  reseller_order_reference_id?: string | null;
  CoverageArea?: CoverageAreaResponse; // only if reseller account
  coverage_area_id?: string;
  ProductsChanged: ProductsChangedResponse[];
  User?: {
    id: string;
    organic: boolean;
  };
  Voucher: {
    discount_amount: number | null;
    discount_delivery_fee: number | null;
    discount_percentage_amount: number | null;
    discount_percentage_delivery_fee: number | null;
    id: string;
    max_nominal_discount: number | null;
    min_total_purchase: number;
    voucher_code: string;
  };
  voucher_fee: number | null;
  voucher_logistic_fee: number | null;
  agreement_at?: string;
  agreement_status?: string;
  MorakLoan: {
    callback_status: boolean;
    id: string;
    status: MorakLoanStatus;
  }; // only if reseller account
  morak_loan_status: MorakLoanStatus; // only if reseller account
  // only exist if return_items exist & only if reseller account
  return_items?: ReturnItemResponse[];
}

export interface OrderItemResponse {
  base_price: number;
  discount_price: number;
  created_at: string;
  deleted_at?: string;
  id: string;
  order_id: string;
  notes?: string;
  order_status_supplier?: string;
  OrderItemLogs: OrderItemLogsResponse[];
  price: number;
  Product: ProductResponse;
  product_id: string;
  qty: number;
  ratio: number;
  revision: string;
  SellerDetail?: {
    // only if reseller account
    id: string;
    User: {
      name: string;
      username: string;
    };
    user_id: string;
  };
  unit: string;
  updated_at: string;
  is_new?: boolean;
  supplier_name?: string;
}

export type OrdersSupplierResponse = Record<
  number,
  {
    Order: OrderSupplierResponse;
    supplier_name?: string;
    User?: UserResponse;
    OrderItems?: OrderResult;
    status?: string;
    is_order_changed?: boolean;
  }
>;

export interface ProductsChangedResponse {
  id: string;
  previous_price: number;
  price: number;
  name: string;
  seller_name: string;
  unit_name: string;
  is_deleted?: boolean;
}

export interface OrderSupplierResponse {
  Order: {
    OrderItems?: {
      changed_rows?: OrderItemResponse[];
      rows?: OrderItemResponse[];
    };
    status: string;
  };
  User: User;
  id: string;
  OrderItems?: {
    changed_rows?: OrderItemResponse[];
    rows?: OrderItemResponse[];
  };
}

export interface OrderItemLogsResponse {
  changed_by: string;
  createdAt: string | null;
  created_at: string;
  events: string;
  id: number;
  order_item_id: string;
  previous_price: string;
  previous_qty: number;
  price: string;
  qty: number;
  revision: string;
  updatedAt: string | null;
  updated_at: string;
}

export interface ProductResponse {
  available: boolean;
  created_at: string;
  deleted_at?: string;
  description: string;
  id: string;
  product_category_id: string;
  promo_active: boolean;
  CoverageArea: CoverageAreaResponse;
  coverage_area_id: string;
  main_picture_url?: string;
  name: string;
  ProductCategory: ProductCategoryResponse;
  ProductCoverageAreas: ProductCoverageAreasResponse[];
  ProductImages: ProductImageResponse[];
  ProductUnits?: ProductUnitResponse[];
  ParentProduct: ParentProductResponse;
  reference_product_id: string;
  selected: boolean;
  seller_id: string;
  sku: string;
  supplier_id?: string;
  updated_at: string;
  units?: ProductUnitResponse[];
  has_ongoing_reseller_order?: boolean; // only exists for supplier
}

export interface ProductUnitResponse {
  id: string;
  price: number;
  base_price: number;
  discount_price: number;
  product_id: string;
  unit: string;
  ratio: number;
  active: boolean;
  available_online: boolean;
  min_quantity?: number;
  user_unit_id?: string;
  wholesale_price?: boolean;
  deleted_at?: string;
  created_at: string;
  updated_at: string;
  UserUnit?: Unit;
  unit_type: null | 'credimart';
  featured: boolean;
}

export interface ProductImageResponse {
  id: string;
  product_id: string;
  image_url: string;
  active: boolean;
  featured: boolean;
  deleted_at?: string;
  created_at: string;
  updated_at: string;
}

export interface ProductCategoryResponse {
  id: string;
  seller_id: string;
  name: string;
  deleted_at?: string;
  created_at: string;
  updated_at: string;
  margin?: number;
  is_reseller?: boolean;
}

export interface AcceptOrderParams {
  logisticFee?: number;
  additionalFee?: number;
  adminFeeId?: string;
  paymentMethod?: string;
  discountType?: DiscountType;
  discount?: number;
}

export interface StaffPlatformParams {
  platform?: string;
}

export interface PostAcceptOrderParams extends StaffPlatformParams {
  logistic_fee?: number;
  additional_fee?: number;
  admin_fee_id?: string;
  payment_method?: string;
  discount_type?: DiscountType;
  discount_percentage?: number; // use if discount_type = percentage
  discount_amount?: number; // use if discount_type = nominal
}

export interface ConfirmOrderData {
  logisticFee?: number;
  additionalFee?: number;
  deliverymanId?: string;
}

export interface ReturnOrderData {
  id: string;
  qty: number;
}

export interface PostConfirmOrderParams extends StaffPlatformParams {
  logistic_fee?: number;
  additional_fee?: number;
  delivery_man_id?: string;
}

export interface CancelOrderParams {
  reason?: string;
}

export interface PostCancelOrderParams extends StaffPlatformParams {
  cancelled_reason?: string;
}

export interface PostMarkAsPaidOrderParams extends StaffPlatformParams {}

export interface PostSetOrderOnDeliveryParams extends StaffPlatformParams {
  tracking_number?: string;
  tracking_img?: string;
}

export interface PostCompleteOrderParams extends StaffPlatformParams {
  return_items?: ReturnOrderData[];
}

export interface UpdateOrderData {
  items?: UpdateOrderItemParams[];
  newItems?: UpdateNewOrderItemParams;
  salesId?: string;
}

export interface UpdateOrderItemParams {
  id: string;
  qty: number;
  price?: number;
  update_product_price?: boolean;
}

export type UpdateNewOrderItemParams = Record<
  string,
  Record<
    string,
    {
      qty: number;
    }
  >
>;

export interface PutUpdateOrderParams extends StaffPlatformParams {
  items?: UpdateOrderItemParams[];
  new_items?: UpdateNewOrderItemParams;
  sales_id?: string;
}

export interface Unit {
  id: string;
  name: string;
  createdAt?: string;
  deletedAt?: string;
  updatedAt?: string;
  userId?: string;
  countProductUsingUnit?: number;
}

export interface ProductImageParam {
  url: string;
  featured: boolean;
}

export interface BulkProductImagesParam {
  id: string;
  images: ProductImageParam[];
}

export interface PostBulkProductImagesParams extends StaffPlatformParams {
  products: BulkProductImagesParam[];
}

export interface GetStaffParams {
  limit?: number;
  offset?: number;
  username?: string;
  role_name?: string;
}

export interface StaffResult {
  count: number;
  rows: StaffResponse[];
}

export interface StaffResponse {
  id: string;
  seller_id: string;
  username: string;
  password: string;
  role_id: number;
  status: boolean;
  Admin: {
    user_id: string;
  };
  Role: {
    id: number;
    name: string;
    user_id: string | null;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    RolePermissions: StaffRolePermissionObject[];
  };
  SellerStaffPermissions: StaffPermissionResponse[];
  StaffCoverageAreas: StaffCoverageAreasResponse[];
  created_at: string;
  updated_at: string;
}

export interface StaffPermissionResponse {
  id: number;
  staff_id: string;
  permission_id: number;
  created_at: string;
  updated_at: string;
}

export interface StaffCoverageAreasResponse {
  id: string;
  staff_id: string;
  coverage_area_id: string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  CoverageArea: {
    id: string;
    seller_id: string;
    name: string;
    phone: string;
    address: string;
    status: string;
    deleted_at: string | null;
    latitude: string;
    longitude: string;
    created_at: string;
    updated_at: string;
  };
}

export interface StaffRole {
  id: number;
  name: string;
}

export interface PermissionsResult {
  count: number;
  rows: PermissionResponse[];
}

export interface PermissionResponse {
  id: number;
  description: string;
  access_platform: string;
}

export interface CreateStaffData {
  username: string;
  password: string;
  roleId: number;
  status?: boolean;
  covereageAreaIds?: Array<{ coverageAreaId: string }>;
}

export interface SalesListParams {
  coverage_area_ids?: string; // single area string / comma separated strings
}

export interface UpdateStaffData extends Omit<CreateStaffData, 'password'> {}

export interface PostStaffParams {
  username: string;
  password: string;
  confirm_password: string;
  role_id: number;
  status: boolean;
  coverage_area_ids?: Array<{ coverage_area_id: string }>;
}

export interface PutStaffParams
  extends Omit<PostStaffParams, 'password' | 'confirm_password'> {}

export interface DeliverymanResult {
  id: string;
  username: string;
}

export interface SalesResult {
  id: string;
  username: string;
}

export interface SellerCategoryResponse {
  key: string;
  value: string;
}

export interface GetProductCategoryParam {
  name?: string;
  is_reseller?: boolean;
  have_product?: boolean; // param only read by backend if logged-in user is staff
}

export interface SellerProductCategoryResponse {
  id: string;
  name: string;
  description: string;
  icon: string;
  total_product: number;
  margin: number;
  active: boolean;
}

export interface SellerSummaryResponse {
  total_count_unpaid_orders: number;
  total_orders: number;
  total_product_sold: number;
  total_value_orders: number;
  total_value_orders_in: number;
  total_value_unpaid_orders: number;
  total_view: number;
  total_profits: number;
}

export interface DeliveryTypesResponse {
  delivery_type_options?: string[];
  success: boolean;
}

export interface PostSellerProductCategoryParams extends StaffPlatformParams {
  name: string;
  description: string;
  icon: string;
  active: boolean;
  margin?: number;
  is_reseller?: boolean;
}

export interface PutSellerProductCategoryParams
  extends PostSellerProductCategoryParams {}

export interface CreateProductCategoryData {
  name: string;
  description: string;
  icon: string;
  active: boolean;
  margin?: number;
  isReseller?: boolean;
}

export interface UpdateProductCategoryData extends CreateProductCategoryData {}

export interface ProductCategoryIcon {
  name: string;
  url: string;
}

export interface SetOrderDueDateData {
  transaction_number: string;
  due_date: string;
}

export interface OrderDueDateSummaryResponse {
  total_value: number;
  logistic_fee: number;
  additional_fee: number;
  total_order: number;
  total_past_due_date: number;
  total_today_due_date: number;
  total_future_due_date: number;
}

export interface OrderInvoiceResponse {
  invoice_url: string;
}

export interface GetProductsResellerParam {
  limit?: number;
  offset?: number;
  name?: string;
  sku?: string;
  product_category_id?: string;
  wholesaler_id?: string;
  coverage_area_id?: string;
}

export interface GetWholesalerParam {
  limit?: number;
  offset?: number;
  name?: string;
  status?: 'true' | 'false';
}

export interface WholesalerResult {
  count: number;
  rows: WholesalerResponse[];
}

export interface WholesalerResponse {
  id: string;
  reseller_id: string;
  supplier_id: string;
  is_active: boolean;
  address: string;
  category_id: string;
  category_name: string;
  seller_jopan: boolean;
  reseller: boolean;
  count_order_completed: number;
  gmv_value: number;
  user_id: string;
  User: User;
}

export interface GetWholesaleProductsParam {
  username?: string;
  limit?: number;
  offset?: number;
  name?: string;
  productCategoryId?: string;
  isNew?: boolean;
  promoActive?: boolean;
  orderBy?: string; // newest | price | name
  sortBy?: string; // asc | desc
  isResellerProduct?: string; // yes | no
  supplierId?: string;
  coverageAreaIds?: string;
  available?: boolean;
  isTradingAccount?: boolean;
  isExternalAccount?: boolean;
}

export interface GetWholesaleProducts {
  username?: string;
  limit?: number;
  offset?: number;
  name?: string;
  product_category_id?: string;
  is_new?: boolean;
  promo_active?: boolean;
  order_by?: string; // newest | price | name
  sort_by?: string; // asc | desc
  is_reseller_product?: string; // yes | no
  wholesaler_id?: string;
  coverage_area_id?: string;
}

export interface WholesaleProductResult {
  product: {
    count: number;
    rows: ProductResponse[];
  };
}

export interface WholesaleResellerProductResult {
  count: number;
  rows: ProductResponse[];
}

export interface BulkResellerProductsParam {
  products: Array<{
    id: string;
    category_name: string;
    margin: number;
  }>;
  coverage_area_id: string;
}

export interface CoverageAreaResponse {
  address: string;
  id: string;
  name: string;
  phone: string;
  status: boolean;
}

export interface ProductCoverageAreasResponse {
  coverage_area_id: string;
  CoverageArea: CoverageAreaResponse;
}

export interface ParentProductResponse {
  id: string;
  name: string;
  product_category_id: string;
  seller_id: string;
  ProductUnits: ProductUnitResponse[];
  SellerDetail: {
    id: string;
    user_id: string;
    User: {
      id: string;
      name: string;
      username: string;
    };
  };
}

export interface GetDeliveryAreaParams {
  limit?: number;
  offset?: number;
  name?: string;
  with_districts?: boolean; // make sure this is false if fetching >10 to ensure fast api response time
  active?: boolean;
}

export interface GetDeliveryAreaParamsV2 {
  limit?: number;
  offset?: number;
  name?: string;
  active?: boolean;
  order_type?: string;
  exclude_id?: string;
  order_by?: string;
  sort_by?: string;
}

export interface AreaDistrict {
  id: number;
  districtId: number;
  districtName: string;
  stateId: number;
  stateName: string;
  cityId: number;
  cityName: string;
}

export interface AreaDistrictResponse {
  id: number;
  state_id: number;
  city_id: number;
  district_id: number;
  State: {
    name: string;
  };
  City: {
    name: string;
  };
  District: {
    name: string;
  };
}

export interface DeliveryAreaResponse {
  id: number;
  seller_id: string;
  name: string;
  phone: string;
  address: string;
  status: boolean;
  latitude: number;
  longitude: number;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  product_count: string;
  CoverageAreaDistricts: AreaDistrictResponse[];
  address_city_id: number;
}

export interface DeliveryAreaResponseV2 {
  id: string;
  name: string;
  address: string;
  phone: string;
  seller_id: string;
  latitude: number;
  longitude: number;
  address_city_id: number;
  seller_hub_id: number;
  has_order_type_trading: boolean;
  has_order_type_reseller: boolean;
  status: boolean;
}

export interface TempoDeliveryArea {
  percentage_amount: number;
  due_days: number;
}

export interface OrderSettingOnDeliveryArea {
  order_limits: {
    cash_min: number;
    cash_max: number;
    tempo_min: number;
    tempo_max: number;
  };
  admin_fees: {
    cash_percentage_amount: number;
    cash_nominal_amount: number;
    tempo: TempoDeliveryArea[];
  };
}

export interface DeliveryAreaDistrict {
  id: number;
  state_id: number;
  city_id: number;
  district_id: number;
  state: { name: string };
  city: { name: string };
  district: { name: string };
}

export interface DeliveryAreaDetailResponseV2 {
  id: string;
  name: string;
  address: string;
  phone: string;
  latitude: number;
  longitude: number;
  address_city_id: number;
  seller_hub_id: number;
  seller_id: string;
  has_order_type_trading: boolean;
  has_order_type_reseller: boolean;
  order_type_reseller_settings: OrderSettingOnDeliveryArea;
  status: boolean;
  coverage_area_districts: DeliveryAreaDistrict[];
  product_count: {
    reseller: number;
    trading: number;
  };
}

export interface PostDeliveryAreaParams {
  name: string;
  phone: string;
  address: string;
  status: boolean;
  latitude: number;
  longitude: number;
  areas: DeliveryPointArea[];
  address_city_id: number;
}

export interface GetResellerOrderReportParams {
  wholesaler_id: string;
  coverage_area_id: string;
  start_at: string;
  end_at: string;
}

export interface ResellerOrderReportOrderItem {
  Product: {
    id: string;
    name: string;
    sku: string;
  };
  base_price: number;
  discount_price: number;
  id: string;
  price: number;
  product_id: string;
  qty: number;
  unit: string;
}

export interface ResellerOrderReportSellerDetail {
  User: {
    name: string;
  };
  id: string;
}

export interface ResellerOrderReportResult {
  OrderItems: ResellerOrderReportOrderItem[];
  SellerDetail: ResellerOrderReportSellerDetail;
  additional_fee: number;
  completed_at: string;
  created_at: string;
  discount_amount: number;
  discount_percentage: number;
  id: string;
  logistic_fee: number;
  paid_amount: number;
  payment_fee: number;
  payment_link: string;
  payment_method: string;
  payment_status: string;
  seller_id: string;
  status: string;
  total_value: number;
  transaction_number: string;
  customer_name: string;
  ParentOrder: {
    total_value: number | null;
    logistic_fee: number | null;
    additional_fee: number | null;
    discount_percentage: number | null;
    discount_amount: number | null;
    payment_link: string;
    payment_fee: number | null;
    paid_amount: number | null;
    id: string;
    coverage_area_id: string;
    CoverageArea: {
      id: string;
      name: string;
    };
  };
}

export interface ResellerOrderReportResponse {
  results: ResellerOrderReportResult[];
}

export interface GetOrderCountParams {
  type?: string;
}
export interface GetResellerSuppliersParams {
  name?: string;
  limit?: number;
  offset?: number;
  status?: 'true' | 'false';
}

export interface ResellerSupplierRow {
  id: string;
  reseller_id: string;
  supplier_id: string;
  is_active: boolean;
  address: string;
  category_id: string;
  category_name: string;
  seller_jopan: boolean;
  reseller: boolean;
  count_order_completed: number;
  gmv_value: number;
  has_ongoing_order: boolean;
  ongoing_order: number;
  User: {
    id: string;
    name: string;
    username: string;
    phone: string;
  };
}

export interface ResellerSuppliersResponse {
  count: number;
  rows: ResellerSupplierRow[];
}

// normalized data from ResellerSupplierRow
export interface ResellerSupplier {
  id: string;
  resellerId: string;
  supplierId: string;
  isActive: boolean;
  address: string;
  categoryId: string;
  categoryName: string;
  sellerJopan: boolean;
  reseller: boolean;
  countOrderCompleted: number;
  gmvValue: number;
  hasOngoingOrder: boolean;
  ongoingOrder: number;
  User: {
    id: string;
    name: string;
    username: string;
    phone: string;
  };
}

export interface UpdateResellerSupplierStatusParams {
  is_active: boolean;
}

export interface GetResellerSellersParams {
  limit?: number;
  offset?: number;
  name?: string;
}

export interface ResellerSellerRow {
  id: string;
  user_id: string;
  state_id: string;
  city_id: string;
  district_id: string;
  subdistrict_id: string;
  zipcode: string;
  latitude: number;
  longitude: number;
  address: string;
  additional_address: string;
  category_id: string;
  category_name: string;
  profile_picture: string;
  delivery_type: string;
  seller_jopan: boolean;
  featured_wholesaler: boolean;
  delivery_area: any; // TODO: type
  start_closed_at: string;
  end_closed_at: string;
  is_synced_buyer: boolean;
  reseller: boolean;
  digital_payments: boolean;
  count_order_completed: number;
  gmv_value: number;
  User: {
    name: string;
    username: string;
    phone: string;
    email: string;
  };
}

// normalized data from ResellerSellerRow
export interface ResellerSeller {
  id: string;
  userId: string;
  stateId: string;
  cityId: string;
  districtId: string;
  subdistrictId: string;
  zipcode: string;
  latitude: number;
  longitude: number;
  address: string;
  additionalAddress: string;
  categoryId: string;
  categoryName: string;
  profilePicture: string;
  deliveryType: string;
  sellerJopan: boolean;
  featuredWholesaler: boolean;
  deliveryArea: any; // TODO: type
  startClosedAt: string;
  endClosedAt: string;
  isSyncedBuyer: boolean;
  reseller: boolean;
  digitalPayments: boolean;
  countOrderCompleted: number;
  gmvValue: number;
  User: {
    name: string;
    username: string;
    phone: string;
    email: string;
  };
}

export interface StoreResellerSuppliersResponseItem {
  created_at: string;
  deleted_at: string;
  id: string;
  is_active: boolean;
  reseller_id: string;
  supplier_id: string;
  updated_at: string;
}

export interface GetDeliverymansParam {
  coverage_area_ids?: string;
}
export interface RegeneratePaymentLinkResponse {
  payment_link: string;
  will_expire_at: string;
}

export interface CheckPaymentStatusResponse {
  success: boolean;
}

export interface DeliveryFeeResponse {
  amount: number;
  id: string;
  seller_id: string;
  active: boolean;
  deleted_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  type: 'cash' | 'tempo';
  seller_type: 'reseller' | 'trading';
}

export interface RemoveProductUnitParams extends StaffPlatformParams {
  new_user_unit_id?: string;
}

// Buyers API Interface
export interface BuyersListResponse {
  count: number;
  rows: BuyersListResult[];
}
export interface BuyersListResult {
  address: string;
  additional_address: string;
  cash_for_tempo: number;
  created_at: string;
  deleted_at?: string;
  finished_order_count?: number;
  finished_order_amount?: number;
  id: string;
  identity_status?: string;
  kyc_history?: string;
  name: string;
  phone: string;
  store_status?: string;
  type: string;
  verification_stage?: string;
  sales_latest_order?: {
    id: string;
    status: string;
    username: string;
  };
  SellerStaff: {
    id: string;
    status: boolean;
    username: string;
  };
  organic: boolean;
  order_latitude: number;
  order_longitude: number;
  order_address: string;
  order_additional_address: string;
  trading: boolean;
}

export interface BuyersListParams {
  account_created_date?: string;
  customer_name?: string;
  customer_phone?: string;
  group_status?: string;
  identity_status?: string;
  limit?: number;
  offset?: number;
  store_status?: string;
  coverage_area_id?: string;
}

export interface KycResponse {
  address?: string;
  blood_type?: string;
  date_of_birth?: string;
  full_name?: string;
  id: string;
  identity_number?: string;
  marital_status?: string;
  place_of_birth?: string;
  religion?: string;
  salt?: string;
  status?: string;
  store_image: string;
  store_status: string;
  user_id: string;
  verification_stage: string;
}

export interface StoreHistoriesResponse {
  id: string;
  user_id: string;
  reason_blocked: string;
  kyc_type: string;
  kyc_store_status: string;
  action_by: string;
  ActionBy?: {
    name: string;
  };
  ActionByStaff?: {
    username: string;
  };
  created_at: string;
}

export interface KeyValueResponse {
  key: string;
  value: string;
}

export interface TempoLimitHistoryResponse {
  amount?: number;
  tempo_status?: boolean;
  created_at: string;
  id: number;
  updated_by: string;
}

export interface BuyerDetailResponse {
  id: string;
  name: string;
  customer_name: string;
  phone: string;
  address: string;
  additional_address: string;
  business_type?: KeyValueResponse;
  blocked_at?: string;
  Kyc: KycResponse;
  picture_url?: string;
  StoreHistory: StoreHistoriesResponse[];
  latitude: number;
  longitude: number;
  order_address?: string;
  TempoLimitHistory?: TempoLimitHistoryResponse[];
  tempo_active: boolean;
  tempo_limit_type: string;
  tempo_max_order_limit: number;
  total_order_count: number;
  total_order_value: number;
  order_latitude: number;
  order_longitude: number;
  order_additional_address: string;
  trading: boolean;
}

export interface RejectStoreVerificationParams {
  buyerId: string;
  reasons: string[];
}
// Buyers API Interface ends.

export interface DefaultResponse {
  success: boolean;
  message: string;
}

export interface DefaultAPIResponse {
  success: boolean;
}

export interface RolePermissionRow {
  id: number;
  name: string;
  RolePermissions: Array<{
    permission_id: number;
    seller_id: string;
    Permission: {
      feature_name: string;
      action: string;
      description: string;
      access_platform: string;
    };
  }>;
  count_access: number;
  wording_name: string;
}

export interface GetRolePermissionsResponse {
  count: number;
  rows: RolePermissionRow[];
}
export interface ProductSku {
  id: string;
  name: string;
  sku: string;
}

export interface ProductReportResponse {
  count: number;
  rows: ProductSku[];
}

export interface ISwitchOption {
  key: string;
  label: string;
}

export interface ChangeLogRequestParams {
  dashboard_type?: string;
  feature_name?: string;
  action_type?: string;
  reference_id?: string;
  limit?: number;
  offset?: number;
}

export interface ChangeLogResponse {
  action_by: string;
  action_type: string;
  created_at: string;
  dashboard_type: string;
  feature_name: string;
  field_name: string;
  id: number;
  new_value: string;
  old_value: string;
  reference_id: string;
  updated_at: string;
}
export interface OrderResellerSettings {
  cashMin: number;
  tempoMin: number;
  tempoMax: number;
}

export interface GetGuestSellerProfileParamsRequest {
  username: string;
  coverage_area_id?: string;
}

export interface GetGuestSellerProfileParams {
  username: string;
  coverageAreaId?: string;
}

export interface GetAdminFeeParamsRequest {
  coverage_area_id?: string;
}

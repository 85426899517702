import React from 'react';
import '../theme/main.css';
import Head from 'next/head';
import App, { type AppProps } from 'next/app';
import Modal from 'react-modal';
import { Admin } from '../components/layouts/admin';
import { IdleTimer } from '../components/alerts/idle-timer';
import { LoadingProvider } from '../components/modals/loading';
import { Snackbar, SnackbarProvider } from '../components/alerts/snackbar';
import { StoreProvider } from '../stores/stores';
import { getCookies, getAccessTokenCookie } from '../utils';
import Bugsnag from '../services/bugsnag';
import Error from './_error';
import { enableLogging } from '../utils/mobx-logger';
import { Api } from '../services/api';
import Script from 'next/script';
import {
  ImageViewer,
  ImageViewerProvider,
} from '../components/modals/image-viewer';

interface CustomAppProps extends AppProps {
  isReseller?: boolean;
  isSupplier?: boolean;
  isFeaturedWholesaler?: boolean;
}

Modal.setAppElement('body');

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary();

class CrediMartApp extends App<CustomAppProps> {
  mobxStore: any;

  static async getInitialProps(appContext) {
    const { ctx } = appContext;
    const { req, res, pathname } = ctx;

    const cookies = req?.cookies
      ? req.cookies // serverside
      : getCookies(); // clientside

    const cookieAuth = cookies?.JOOALAN_AT
      ? JSON.parse(cookies?.JOOALAN_AT) // serverside
      : getAccessTokenCookie(); // clientside

    if (res?.writeHead && !cookieAuth?.token?.length && pathname !== '/login') {
      res.writeHead(302, {
        Location: '/login',
      });
      res.end();
    }

    if (cookieAuth?.token?.length && pathname !== '/login') {
      const _api = new Api();
      _api.setup(cookieAuth.token);

      if (_api) {
        const profile = await _api.getSellerProfile();
        let isResellerHost = false;
        if (req?.headers?.host) {
          // serverside
          isResellerHost = req.headers.host === process.env.RESELLER_HOST_URL;
        } else if (window?.location?.host) {
          // clientside
          isResellerHost =
            window.location.host === process.env.RESELLER_HOST_URL;
        }

        const isReseller = profile?.reseller && isResellerHost;
        const isSupplier = !!profile?.supplier; // supplier for credimart's /pesan
        const isFeaturedWholesaler = !!profile?.featured_wholesaler; // trading accounts
        ctx.isReseller = isReseller;
        ctx.isSupplier = isSupplier;
        ctx.isFeaturedWholesaler = isFeaturedWholesaler;
        // Redirections
        if (!isReseller && pathname === '/supplier-order') {
          res.writeHead(302, {
            Location: '/order',
          });
          res.end();
        }
        if (
          !isReseller &&
          // TODO: remove buyer management non-reseller redirection until feature fully done for reseller
          (pathname === '/supplier-settings' || pathname === '/area-management')
        ) {
          res.writeHead(302, {
            Location: '/dashboard',
          });
          res.end();
        }
      }
    }

    const appProps = await App.getInitialProps(appContext);

    return {
      ...appProps,
      isReseller: ctx.isReseller,
      isSupplier: ctx.isSupplier,
      isFeaturedWholesaler: ctx.isFeaturedWholesaler,
    };
  }

  constructor(props) {
    super(props);

    enableLogging({
      reaction: false,
      action: true,
    });

    // const env = process.env.NODE_ENV
    // if (env === 'development') {
    //   enableLogging();
    // }
  }

  renderComponent = () => {
    const {
      Component,
      pageProps,
      router,
      isReseller,
      isSupplier,
      isFeaturedWholesaler,
    } = this.props;
    if (!['/login', '/404'].includes(router.pathname)) {
      return (
        <Admin isReseller={isReseller}>
          <Component
            {...pageProps}
            isReseller={isReseller}
            isSupplier={isSupplier}
            isFeaturedWholesaler={isFeaturedWholesaler}
          />
        </Admin>
      );
    }

    return <Component {...pageProps} />;
  };

  render() {
    return (
      <>
        <Head>
          <title>{`CrediMart | ${
            this.props.isReseller ? 'Reseller' : 'Seller'
          } Web Dashboard`}</title>
          <meta name="robots" content="noindex,follow" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        {process.env?.REACT_APP_GA_ID && (
          <>
            <Script
              id="google-tag-manager-src"
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`}
              strategy="afterInteractive"
            />
            <Script
              id="google-tag-manager-inline-script"
              strategy="afterInteractive"
            >
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${process.env.REACT_APP_GA_ID}');
            `}
            </Script>
          </>
        )}
        {process.env?.HOTJAR_ID && (
          <Script id="hotjar-script">
            {`
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${process.env.HOTJAR_ID},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
          </Script>
        )}
        <ErrorBoundary FallbackComponent={Error}>
          <StoreProvider>
            <SnackbarProvider>
              <LoadingProvider>
                <ImageViewerProvider>
                  <Snackbar />
                  {this.renderComponent()}
                  <IdleTimer />
                  <ImageViewer />
                </ImageViewerProvider>
              </LoadingProvider>
              <IdleTimer />
            </SnackbarProvider>
          </StoreProvider>
        </ErrorBoundary>
      </>
    );
  }
}

export default CrediMartApp;

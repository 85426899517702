import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
  FiHome,
  FiPackage,
  FiSettings,
  FiShoppingCart,
  FiUsers,
  FiSliders,
  FiBookOpen,
  FiMenu,
  FiX,
} from 'react-icons/fi';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { SidebarMenu } from './sidebar-menu';
import { useRouter } from 'next/router';
import { useStores } from '../../../stores/stores';
import { IconHierarchy } from '../../icons/icon-hierarchy';
import {
  ACCOUNT_PERMISSION_IDS,
  BUYER_MANAGEMENT_PERMISSION_IDS,
  AREA_MANAGEMENT_PERMISSION_IDS,
  ORDER_PERMISSION_IDS,
  PRODUCT_PERMISSION_IDS,
  STAFF_PERMISSION_IDS,
  SUPPLIER_PERMISSION_IDS,
} from '../../../utils/constants';
import { SideMenuModal } from '../../modals/side-menu-modal/side-menu-modal';

const SidebarComponent: React.FC<{
  isMenuActive: (menu: string) => boolean;
  isReseller?: boolean;
}> = observer(({ isMenuActive, isReseller }) => {
  const [showSidebarOn1024Screen, set1024SidebarVisibility] =
    useState<boolean>(false);
  const { accountStore } = useStores();
  const getData = useCallback(async () => {
    await accountStore.getRolePermissions();
  }, [accountStore]);
  const isSuperAdmin = useMemo(
    () => accountStore?.isSuperAdmin,
    [accountStore?.isSuperAdmin],
  );
  const permissionIds = accountStore?.staffPermissions?.permissions.map(
    (p) => p,
  );
  const hasOrderPermission =
    isSuperAdmin ||
    ORDER_PERMISSION_IDS.some((id) => permissionIds?.find((pid) => pid === id));
  const hasAreaManagementPermission =
    (isReseller && isSuperAdmin) ||
    AREA_MANAGEMENT_PERMISSION_IDS.some(
      (id) => permissionIds?.find((pid) => pid === id),
    );
  const hasProductPermission =
    isSuperAdmin ||
    PRODUCT_PERMISSION_IDS.some(
      (id) => permissionIds?.find((pid) => pid === id),
    );
  const hasBuyerManagementPermission =
    isSuperAdmin ||
    BUYER_MANAGEMENT_PERMISSION_IDS.some(
      (id) => permissionIds?.find((pid) => pid === id),
    );
  const hasSupplierManagementPermission =
    isReseller &&
    (isSuperAdmin ||
      SUPPLIER_PERMISSION_IDS.some(
        (id) => permissionIds?.find((pid) => pid === id),
      ));
  const hasSettingsManagementPermission =
    isSuperAdmin ||
    ACCOUNT_PERMISSION_IDS.some(
      (id) => permissionIds?.find((pid) => pid === id),
    );
  const hasStaffManagementPermission =
    isSuperAdmin ||
    STAFF_PERMISSION_IDS.some((id) => permissionIds?.find((pid) => pid === id));

  const navMenus = [
    {
      title: 'Beranda',
      icon: (props) => <FiHome {...props} size="20" />,
      link: '/dashboard',
    },
    {
      title: isReseller ? 'Pesanan Pembeli' : 'Pesanan',
      icon: (props) => <FiPackage {...props} size="20" />,
      link: '/order',
      isRestricted: !hasOrderPermission,
    },
    {
      title: 'Produk',
      icon: (props) => <FiShoppingCart {...props} size="20" />,
      link: '/product',
      isRestricted: !hasProductPermission,
    },
    {
      title: 'Area Pengiriman',
      icon: (props) => <HiOutlineLocationMarker {...props} size="20" />,
      link: '/area-management',
      isRestricted: !hasAreaManagementPermission,
    },
    {
      title: 'Kelola Pelanggan',
      icon: (props) => <FiBookOpen {...props} size="20" />,
      link: '/buyer-management',
      isRestricted: !hasBuyerManagementPermission,
    },
    {
      title: 'Pesanan Supplier',
      icon: (props) => <IconHierarchy width={20} height={20} {...props} />,
      link: '/supplier-order',
      isRestricted: !hasSupplierManagementPermission,
    },
    {
      title: 'Pengaturan Supplier',
      icon: (props) => <FiSliders {...props} size="20" />,
      link: '/supplier-settings',
      isRestricted: !hasSupplierManagementPermission,
    },
    {
      title: 'Karyawan',
      icon: (props) => <FiUsers {...props} size="20" />,
      link: '/staff',
      isRestricted: !hasStaffManagementPermission,
    },
    {
      title: 'Pengaturan',
      icon: (props) => <FiSettings {...props} size="20" />,
      link: '/settings',
      isRestricted: !hasSettingsManagementPermission,
    },
  ];

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMenu = () => {
    return (
      <nav className="flex flex-col w-full">
        {showSidebarOn1024Screen && (
          <FiX
            className="absolute cursor-pointer block lg:hidden"
            size={18}
            onClick={() => {
              set1024SidebarVisibility(false);
            }}
          />
        )}
        <div className="flex items-center justify-center pb-12">
          <img src="/img/logo-v.svg" width="80" className="pt-8" alt="logo" />
        </div>
        <div
          className="nav flex-col mb-2 mx-1"
          onClick={() => {
            set1024SidebarVisibility(false);
          }}
        >
          {navMenus.map((menu, index) => {
            if (menu.isRestricted) return null;
            return (
              <SidebarMenu
                key={index}
                title={menu.title}
                Icon={menu.icon}
                link={menu.link}
                isActive={isMenuActive(menu.link)}
              />
            );
          })}
        </div>
      </nav>
    );
  };

  return (
    <>
      <div
        className="fixed h-full min-h-screen flex flex-row"
        style={{ zIndex: 1 }}
      >
        <div className="hidden lg:flex w-64 bg-white">{renderMenu()}</div>
        <div className="cursor-pointer block lg:hidden absolute top-7 left-4">
          <FiMenu
            size={24}
            onClick={() => {
              set1024SidebarVisibility(true);
            }}
          />
        </div>
      </div>
      <SideMenuModal
        visible={showSidebarOn1024Screen}
        onClose={() => {
          set1024SidebarVisibility(false);
        }}
      >
        {renderMenu()}
      </SideMenuModal>
    </>
  );
});

export const SideBar: React.FunctionComponent<{ isReseller: boolean }> =
  observer(({ isReseller }) => {
    const router = useRouter();

    const isMenuActive = useCallback(
      (menu: string) => {
        if (menu === '/dashboard') {
          return router.pathname?.startsWith(menu) || router.pathname === '/';
        } else if (menu === '/order') {
          return (
            router.pathname?.startsWith(menu) &&
            !router.pathname?.startsWith('/supplier-order')
          );
        }
        return router.pathname?.startsWith(menu);
      },
      [router.pathname],
    );

    return (
      <SidebarComponent isMenuActive={isMenuActive} isReseller={isReseller} />
    );
  });

import { makeAutoObservable } from 'mobx';
import {
  type BuyerDetailResponse,
  type BuyersListParams,
  type BuyersListResponse,
  type DefaultResponse,
  MSG_GLOBAL_ERROR,
  type RejectStoreVerificationParams,
} from '../../utils';
import type RootStore from '../root-store';
import {
  type Buyer,
  type BuyerDetail,
  mapBuyerDetailResponseToBuyerDetail,
  mapBuyersResponseToBuyers,
} from './buyer-store.model';

class BuyerStore {
  rootStore: RootStore;
  buyer: BuyerDetail = null;
  buyers: Buyer[] = [];
  buyersCount = 0;

  constructor(rootStore?: RootStore) {
    if (rootStore) {
      this.rootStore = rootStore;
      makeAutoObservable(this, { rootStore: false });
    } else {
      makeAutoObservable(this);
    }
  }

  setBuyer(_buyer: BuyerDetail) {
    this.buyer = _buyer;
  }

  setBuyers(_buyers: Buyer[]) {
    this.buyers = _buyers;
  }

  setBuyersCount(_buyersCount: number) {
    this.buyersCount = _buyersCount;
  }

  async getBuyers(params: BuyersListParams) {
    const buyersResponse: BuyersListResponse =
      await this.rootStore.api.getBuyers(params);
    const buyersData = mapBuyersResponseToBuyers(buyersResponse.rows);
    this.setBuyers(buyersData);
    this.setBuyersCount(buyersResponse?.count ?? 0);
  }

  async getBuyerDetail(
    buyerId: string,
    shouldReturnData = false,
  ): Promise<{ success: boolean; message: string }> {
    let success = false;
    let message = '';

    try {
      const buyerData: BuyerDetailResponse =
        await this.rootStore.api.getBuyerDetail(buyerId);
      const buyerDetail = mapBuyerDetailResponseToBuyerDetail(buyerData);
      this.setBuyer(buyerDetail);
      success = !!buyerData;
      message = success
        ? `buyer id ${buyerId}`
        : `buyer id ${buyerId} tidak ditemukan`;
      if (shouldReturnData) {
        // @ts-expect-error any -> BuyerDetail
        return buyerDetail;
      }
    } catch (error) {
      success = false;
      message = error?.message || 'Gagal mendapatkan data buyer';
    }

    return { success, message };
  }

  async acceptStoreVerification(buyerId: string): Promise<DefaultResponse> {
    if (!buyerId)
      return {
        success: false,
        message: 'ID Pelanggan tidak ditemukan',
      };
    try {
      const response =
        await this.rootStore.api.acceptStoreVerification(buyerId);
      if (response)
        return {
          success: true,
          message: 'Berhasil melakukan verifikasi toko',
        };
    } catch (e) {
      return {
        success: false,
        message:
          e?.message || 'Gagal melakukan verifikasi toko, silakan coba lagi',
      };
    }
  }

  async rejectStoreVerification(
    params: RejectStoreVerificationParams,
  ): Promise<DefaultResponse> {
    if (!params?.buyerId)
      return {
        success: false,
        message: 'ID Pelanggan tidak ditemukan',
      };
    try {
      const response = await this.rootStore.api.rejectStoreVerification(params);
      if (response)
        return {
          success: true,
          message: 'Verifikasi toko berhasil ditolak',
        };
    } catch (e) {
      return {
        success: false,
        message:
          e?.message ||
          'Gagal melakukan penolakan verifikasi toko, silakan coba lagi',
      };
    }
  }

  async cancelStoreVerification(
    params: RejectStoreVerificationParams,
  ): Promise<DefaultResponse> {
    if (!params?.buyerId)
      return {
        success: false,
        message: 'ID Pelanggan tidak ditemukan',
      };
    try {
      const response = await this.rootStore.api.cancelStoreVerification(params);
      if (response)
        return {
          success: true,
          message: 'Verifikasi toko berhasil dibatalkan',
        };
    } catch (e) {
      return {
        success: false,
        message:
          e?.message ||
          'Gagal melakukan pembatalan verifikasi toko, silakan coba lagi',
      };
    }
  }

  async updateBuyerTempoInfo(
    buyerId: string,
    {
      isTempoEligible,
      tempoOrderLimit,
    }: { isTempoEligible?: boolean; tempoOrderLimit?: number },
  ): Promise<DefaultResponse> {
    try {
      const params = {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
        ...((isTempoEligible === true || isTempoEligible === false) && {
          tempo_active: isTempoEligible.toString(),
        }),
        ...(!!tempoOrderLimit && { tempo_order_limit: tempoOrderLimit }),
      };
      const response = await this.rootStore.api.updateBuyerTempoInfo(
        buyerId,
        params,
      );
      if (response)
        return {
          success: true,
          message: 'Data tempo berhasil diubah',
        };
    } catch (error) {
      return {
        success: false,
        message: error?.message || error || MSG_GLOBAL_ERROR,
      };
    }
  }
}

export default BuyerStore;

import { useRouter } from 'next/router';
import React from 'react';
import { Button } from '../components/button';
import Bugsnag from '../services/bugsnag';

interface Props {
  statusCode?: number;
  error?: Error;
}

const ErrorPage = ({ statusCode, error }: Props) => {
  const router = useRouter();

  const handleBack = () => {
    router.push('/');
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <img
        src="/img/empty-2.png"
        className="mb-2 aspect-square"
        width="200"
        alt="error"
      />
      {statusCode && (
        <div className="font-bold text-heading3">{statusCode}</div>
      )}
      <div className="font-medium text-heading5 mt-2">
        {error ? error.message : 'Terjadi kesalahan'}
      </div>
      <Button className="mt-6" onClick={handleBack}>
        Kembali ke Halaman Utama
      </Button>
    </div>
  );
};

ErrorPage.getInitialProps = ({ res, err }) => {
  if (err) Bugsnag.notify(err);
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorPage;

import React from 'react';
import classNames from 'classnames';
import { type AvatarProps } from '.';

export const Avatar: React.FunctionComponent<AvatarProps> = ({
  name,
  imageUrl,
  width = '3rem',
  height = '3rem',
  style,
  className,
}) => {
  if (imageUrl) {
    return (
      <img
        src={imageUrl}
        className={classNames('rounded-full fit-cover', className)}
        style={{ width, height }}
        alt="avatar"
      />
    );
  }
  return (
    <div
      className={classNames(
        'flex items-center justify-center bg-line-gray p-2 rounded-full text-white font-bold uppercase',
        className,
      )}
      style={{
        width,
        height,
        ...style,
      }}
    >
      <p className="m-0 p-0 self-center text-label-md text-white flex-none">
        {name?.slice(0, 2) || ''}
      </p>
    </div>
  );
};

import React from 'react';
import { type TextProps } from '.';

export const Text: React.FunctionComponent<TextProps> = ({
  children,
  color,
  preset,
  className,
  font,
  ...rest
}) => (
  <p
    className={`text text-${color ?? 'gray'} text-${preset ?? 'body2'} font-${
      font ?? '500'
    } ${className ?? ''} leading-[20px]`}
    {...rest}
  >
    {children}
  </p>
);

import React, { useCallback, useState } from 'react';
import { initialLoadingContent, LoadingContext } from '.';

export const LoadingProvider = ({ children }) => {
  const [content, setContent] = useState(initialLoadingContent);

  const startLoading = useCallback(() => {
    setContent({ ...initialLoadingContent, visible: true });
  }, []);

  const stopLoading = useCallback(() => {
    setContent({ ...content, visible: false });
  }, [content]);

  return (
    <LoadingContext.Provider
      value={{
        startLoading,
        stopLoading,
        content,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

import { enableStaticRendering } from 'mobx-react';
import React, { type ReactNode } from 'react';
import type AccountStore from './account-store/account-store';
import type BuyerStore from './buyer-store/buyer-store';
import type OrderStore from './order-store/order-store';
import type ProductStore from './product-store/product-store';
import RootStore from './root-store';
import type StaffStore from './staff-store/staff-store';
import type WholesalerStore from './wholesaler-store/wholesaler-store';

const isServer = typeof window === 'undefined';
enableStaticRendering(isServer);

let store: RootStore;

export default function initializeStore(): RootStore {
  const _store = store ?? new RootStore();
  // for SSG and SSR always create a new store
  if (isServer) return _store;
  // create the store once in the client
  if (!store) store = _store;
  return _store;
}

const StoreContext = React.createContext<RootStore | undefined>(undefined);
StoreContext.displayName = 'StoreContext';

interface StoreProviderProps {
  children: ReactNode;
}

export function StoreProvider({ children }: StoreProviderProps) {
  const store = initializeStore();
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}

export function useStores() {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within StoreProvider');
  }
  return context;
}

export interface StoresModel {
  buyerStore: BuyerStore;
  productStore: ProductStore;
  orderStore: OrderStore;
  accountStore: AccountStore;
  staffStore: StaffStore;
  wholesalerStore: WholesalerStore;
}

export enum VerificationMethod {
  whatsapp,
  sms,
}

// DUE_DATE PARAMS VALUE (for GET /seller/order)
export enum OrderDueDate {
  all = 'all',
  future = 'future',
  passed = 'passed',
  today = 'today',
}

export enum DateRangeType {
  all = 'all',
  today = 'today',
  this_week = 'this_week',
  this_month = 'this_month',
  certain_date = 'certain_date',
}

export enum DiscountType {
  nominal = 'nominal',
  percentage = 'percentage',
}

export function mergeTwoArray<T>(
  arrayToReplace: T[],
  arrayToMerge: T[],
  propertyName: string,
) {
  return arrayToReplace
    .filter(
      (a) => !arrayToMerge.find((b) => a[propertyName] === b[propertyName]),
    )
    .concat(arrayToMerge);
}

/**
 * example:
 * const cars = [{
    make: 'audi',
    model: 'r8',
  }, {
    make: 'audi',
    model: 'rs5',
  }, {
    make: 'ford',
    model: 'mustang',
  }, {
    make: 'kia',
    model: 'optima',
  }]

  groupBy(cars, 'make') // => [
    audi: [{ make: audi, model: 'r8' }, ...],
    ford: [...],
    kia: [...],
  ]
 */
export function groupBy(array, key) {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

/**
 * Convert UNIQUE array of string to a string with certain separator
 * @param array
 * @param separator
 * @returns
 */
export function arrayOfStringToUniqueString(
  array: string[],
  separator: string,
) {
  try {
    return array.reduce((accumulation: string, item: string, index: number) => {
      if (accumulation.includes(item) || !item) {
        // Make sure unique value and non empty
        return accumulation;
      }
      return `${item}${index > 0 ? separator : ''}${accumulation}`;
    }, '');
  } catch (err) {
    console.error(err);
  }
}

import { type ApisauceInstance, create } from 'apisauce';
import { type GeneralApiProblem, getGeneralApiProblem } from './api-problem';
import { type ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import Bugsnag from '../../services/bugsnag';
import {
  type AcceptOrderParams,
  type CancelOrderParams,
  type GetOrdersParam,
  type GetProductsParam,
  MSG_GLOBAL_ERROR,
  type OrderResponse,
  type OrderResult,
  type OtpRequestParam,
  type OtpVerifyParam,
  type SupplierOrderCountResult,
  type PostAcceptOrderParams,
  type PostCancelOrderParams,
  type PostBulkProductImagesParams,
  type ProductResult,
  type PutProductParams,
  type GetDashboardSummaryParams,
  type UnitResult,
  type ProductResponse,
  type PutBulkActiveStatusProductsParams,
  type DeleteBulkProductsParams,
  type PutBulkEditProductsParams,
  type CreateProductParams,
  type OrderCountResult,
  type PostSellerProfileParams,
  type GetStaffParams,
  type StaffResult,
  type StaffRole,
  type PostStaffParams,
  type PermissionsResult,
  type ConfirmOrderData,
  type PostConfirmOrderParams,
  type DeliverymanResult,
  type PutStaffParams,
  type LoginStaffParams,
  type StaffRolePermissionResponse,
  type PostMarkAsPaidOrderParams,
  type PostCompleteOrderParams,
  type PostBulkCreateProductParams,
  type PlaceIdResponse,
  type PlaceDetailsResponse,
  type PlaceSuggestionsResponse,
  type PutUpdateOrderParams,
  type PostSetOrderOnDeliveryParams,
  type SellerCategoryResponse,
  type SellerProductCategoryResponse,
  type AdminFeeResponse,
  type PostSellerProductCategoryParams,
  type PutSellerProductCategoryParams,
  type GetProductCategoryParam,
  clearAccessTokenCookie,
  getAccessTokenCookie,
  setAccessTokenCookie,
  DiscountType,
  type Unit,
  IS_STAFF,
  PERMISSION_ACCESS_PLATFORM,
  SUPERADMIN_ID,
} from '../../utils';
import {
  type CreateAdminFeeParams,
  type SalesResult,
  type SetOrderDueDateData,
  type UpdateOrderData,
  type CreateProductBulkParams,
  type TokenizeOTPRequestParam,
  type OrderDueDateSummaryResponse,
  type OrderInvoiceResponse,
  type GetWholesalerParam,
  type WholesalerResult,
  type GetProductsResellerParam,
  type GetDeliveryAreaParams,
  type DeliveryAreaResponse,
  type PostDeliveryAreaParams,
  type BulkResellerProductsParam,
  type GetWholesaleProducts,
  type WholesaleProductResult,
  type PutBulkEditResellerProductsParams,
  type GetResellerWholesalerParam,
  type GetResellerWholesalerResult,
  type GetResellerSettingsResult,
  type PostResellerSettingsPayload,
  type GetResellerOrderReportParams,
  type ResellerOrderReportResponse,
  type GetResellerSuppliersParams,
  type ResellerSuppliersResponse,
  type UpdateResellerSupplierStatusParams,
  type GetResellerSellersParams,
  type StoreResellerSuppliersResponseItem,
  type SellerProfileResponse,
  type SalesListParams,
  type GetDeliverymansParam,
  type RegeneratePaymentLinkResponse,
  type WholesaleResellerProductResult,
  type CheckPaymentStatusResponse,
  type DeliveryFeeData,
  type DeliveryFeeResponse,
  type RemoveProductUnitParams,
  type BuyersListResponse,
  type BuyersListParams,
  type BuyerDetailResponse,
  type RejectStoreVerificationParams,
  type ProductReportResponse,
  type ProductSku,
  type GetRolePermissionsResponse,
  type DefaultAPIResponse,
  type GetDashboardSummaryResponse,
  type GetDeliveryAreaParamsV2,
  type DeliveryAreaResponseV2,
  type DeliveryAreaDetailResponseV2,
  type ChangeLogRequestParams,
  type GetGuestSellerProfileParamsRequest,
  type GetAdminFeeParamsRequest,
  type DeliveryAreaDetailResponse,
  type GetOrderLimitParam,
  type OrderLimitResponse,
  type ReturnOrderData,
} from '../../utils/interfaces';
import { getPlatformInfo } from '../../utils/api';
import { type Product } from '../../stores/product-store/product-store.model';

export const getTokenFromCookie = () => {
  const cookieAuth = getAccessTokenCookie();
  if (cookieAuth) {
    try {
      const { token } = cookieAuth;
      if (!token) {
        window.location.href = '/login';
      }
      return { 'x-access-token': token };
    } catch (e) {
      console.error('error getting token', e);
      window.location.href = '/login';
      return null;
    }
  }
  return null;
};

async function checkResponse(response: any) {
  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) {
      const problemWithUrl = {
        ...problem,
        url: response?.config?.url || '',
        method: response?.config?.method || '',
      };
      if (
        problem.kind === 'forbidden' &&
        typeof window !== 'undefined' &&
        !!localStorage &&
        !!window
      ) {
        localStorage.clear();
        clearAccessTokenCookie();
        window.location.reload();
      }
      // return also response url
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw problemWithUrl;
    }
  }
  return response.data;
}

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup(serverToken?: string) {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
        'x-access-token': serverToken,
      },
    });
  }

  checkTokenExist(): boolean {
    return !!getAccessTokenCookie()?.token;
  }

  sanitizeFetchResponse(response: any) {
    const { status } = response;
    switch (status) {
      case 401:
      case 403:
        if (localStorage) localStorage.clear();
        clearAccessTokenCookie();
        if (window) window.location.href = '/login';
        return false;
      default:
        return response;
    }
  }

  /**
   * =====================================================================
   * Request methods:
   * GET, POST, PUT, DELETE
   * =====================================================================
   */

  async getData(
    url: string,
    params?: any,
    isUsingHeaders = true,
    mockupBaseUrl?: string,
  ): Promise<any> {
    try {
      let headers = {};
      if (isUsingHeaders) {
        if (typeof window === 'undefined') {
          headers = {
            'x-access-token': this.apisauce.headers['x-access-token'],
          };
        } else {
          const tokenFromStorage = getTokenFromCookie();
          if (tokenFromStorage) {
            headers = getTokenFromCookie();
          }
        }
      }
      const paramsPayload: string = params
        ? new URLSearchParams(params).toString()
        : '';
      const response = await fetch(
        `${mockupBaseUrl || process.env.REACT_APP_BASE_URL}${url}${
          paramsPayload ? `?${paramsPayload}` : ''
        }`,
        { headers },
      );
      const sanitizedResponse =
        await this.sanitizeFetchResponse(response)?.json();
      if (sanitizedResponse.statusCode === 400) {
        throw Error(sanitizedResponse?.message || 'Terjadi kesalahan');
      }
      return sanitizedResponse;
    } catch (error) {
      Bugsnag.notify(error);
      throw error;
    }
  }

  async postData(
    url: string,
    body?: any,
    isUsingHeaders = true,
    timeout = this.config.timeout,
    onUploadProgress?: any,
    mockupBaseUrl?: string,
  ): Promise<any> {
    try {
      let headers = null;
      if (isUsingHeaders) {
        headers = getTokenFromCookie();
      }
      const response = await this.apisauce.post(mockupBaseUrl || url, body, {
        headers,
        timeout,
        onUploadProgress,
      });
      return await checkResponse(response);
    } catch (error) {
      Bugsnag.notify(error);
      throw error;
    }
  }

  async putData(url: string, body?: any, onUploadProgress?: any): Promise<any> {
    try {
      const headers = getTokenFromCookie();
      const response = await this.apisauce.put(url, body, {
        headers,
        onUploadProgress,
      });
      return await checkResponse(response);
    } catch (error) {
      Bugsnag.notify(error);
      throw error;
    }
  }

  async deleteData(url: string, params?: any, body?: any): Promise<any> {
    try {
      const headers = getTokenFromCookie();
      const response = await this.apisauce.delete(url, params, {
        headers,
        data: body,
      });
      return await checkResponse(response);
    } catch (error) {
      Bugsnag.notify(error);
      throw error;
    }
  }

  /**
   * =====================================================================
   *                             API METHODS
   * =====================================================================
   */

  async otpRequest(params: OtpRequestParam): Promise<any> {
    try {
      return await this.postData('/auth/seller/request-otp', params, false);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async tokenizeOtpRequest(params: TokenizeOTPRequestParam): Promise<any> {
    try {
      return await this.postData('/auth/seller/send-otp', params, false);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async otpVerify(
    params: OtpVerifyParam,
  ): Promise<{ success: boolean; message: string }> {
    try {
      const response = await this.postData(
        '/auth/seller/verify-otp',
        params,
        false,
      );
      if (response.token) {
        setAccessTokenCookie(response);
        if (localStorage) localStorage.setItem(IS_STAFF, 'false');
        return { success: true, message: response.message };
      }
      return { success: false, message: response.message || MSG_GLOBAL_ERROR };
    } catch (e) {
      console.error(e);
      return { success: false, message: e.message || MSG_GLOBAL_ERROR };
    }
  }

  async otpVerifyV2(
    params: OtpVerifyParam,
  ): Promise<{ success: boolean; message: string }> {
    try {
      const response = await this.postData(
        '/v2/auth/seller/verify-otp',
        params,
        false,
      );
      if (response.data) {
        setAccessTokenCookie(response.data);
        if (localStorage) localStorage.setItem(IS_STAFF, 'false');
        return { success: true, message: response.message };
      }
      return { success: false, message: response.message || MSG_GLOBAL_ERROR };
    } catch (e) {
      console.error(e);
      return { success: false, message: e.message || MSG_GLOBAL_ERROR };
    }
  }

  async loginStaff(
    params: LoginStaffParams,
  ): Promise<{ success: boolean; message: string }> {
    try {
      const response = await this.postData('/auth/staff/login/v2', params);
      if (response.token) {
        setAccessTokenCookie(response);
        const { Role } = await this.getStaffPermission();
        const anyPlatformWeb = Role.RolePermissions?.some(
          (p) =>
            PERMISSION_ACCESS_PLATFORM[p.permission_id] === 'web' ||
            PERMISSION_ACCESS_PLATFORM[p.permission_id] === 'all',
        );
        if (!anyPlatformWeb) {
          clearAccessTokenCookie();
          return { success: false, message: 'restricted' };
        }
        if (localStorage) {
          if (Role.id === SUPERADMIN_ID) {
            localStorage.setItem(IS_STAFF, 'false');
          } else localStorage.setItem(IS_STAFF, 'true');
        }
        return { success: true, message: response.message };
      }
    } catch (e) {
      console.error(e);
      return { success: false, message: e.message || MSG_GLOBAL_ERROR };
    }
  }

  async loginStaffV2(
    params: LoginStaffParams,
  ): Promise<{ success: boolean; message: string }> {
    try {
      const response = await this.postData(
        '/v2/auth/staff/login',
        params,
        false,
      );
      if (response.status) {
        setAccessTokenCookie(response.data);
        const { Role } = await this.getStaffPermission();
        const anyPlatformWeb = Role.RolePermissions?.some(
          (p) =>
            PERMISSION_ACCESS_PLATFORM[p.permission_id] === 'web' ||
            PERMISSION_ACCESS_PLATFORM[p.permission_id] === 'all',
        );
        if (!anyPlatformWeb) {
          clearAccessTokenCookie();
          return { success: false, message: 'restricted' };
        }
        if (localStorage) {
          if (Role.id === SUPERADMIN_ID) {
            localStorage.setItem(IS_STAFF, 'false');
          } else localStorage.setItem(IS_STAFF, 'true');
        }
        return { success: true, message: response.message };
      } else {
        return { success: false, message: MSG_GLOBAL_ERROR };
      }
    } catch (e) {
      console.error(e);
      return { success: false, message: e.message || MSG_GLOBAL_ERROR };
    }
  }

  async getStaffPermission(): Promise<StaffRolePermissionResponse> {
    try {
      return await this.getData('/seller/staff/permission');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getProductUnits(): Promise<UnitResult> {
    try {
      return await this.getData('/seller/product-unit');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getProductUnit(id: string): Promise<Unit> {
    try {
      return await this.getData(`/seller/product-unit/${id}`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async storeProductUnit(name: string): Promise<Unit> {
    try {
      return await this.postData('/seller/product-unit', { name });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async removeProductUnit(id: string, replacementId?: string): Promise<any> {
    const body: RemoveProductUnitParams = { ...getPlatformInfo() };
    if (replacementId) body.new_user_unit_id = replacementId;
    try {
      return await this.deleteData(`/seller/product-unit/${id}`, null, body);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getProduct(id: string): Promise<ProductResponse> {
    try {
      return await this.getData(`/seller/product/${id}`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getProducts(params?: GetProductsParam): Promise<ProductResult> {
    try {
      return await this.getData('/seller/product', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async createProduct(params: CreateProductParams): Promise<any> {
    try {
      const body: CreateProductParams = {
        ...params,
        ...getPlatformInfo(),
      };

      return await this.postData('/seller/product', body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async bulkCreateProduct(
    params: CreateProductBulkParams[],
  ): Promise<ProductResponse[]> {
    try {
      const body: PostBulkCreateProductParams = {
        products: params,
        ...getPlatformInfo(),
      };

      return await this.postData('/seller/products', body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async updateProduct(id: string, params: PutProductParams): Promise<number[]> {
    try {
      const body: PutProductParams = {
        ...params,
        ...getPlatformInfo(),
      };
      return await this.putData(`/seller/product/${id}`, body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async uploadImage(
    photo: File,
    onUploadProgress?: any,
  ): Promise<{ url: string }> {
    try {
      const data = new FormData();
      data.append('photo', photo);
      return await this.postData(
        '/seller/upload',
        data,
        true,
        0,
        onUploadProgress,
      );
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async bulkProductImages(
    params: PostBulkProductImagesParams,
  ): Promise<{ success: boolean }> {
    try {
      const body: PostBulkProductImagesParams = {
        ...params,
        ...getPlatformInfo(),
      };
      return await this.postData('/seller/product/update-images', body);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async removeProduct(id: string): Promise<{ success: boolean }> {
    try {
      return await this.deleteData(
        `/seller/product/${id}`,
        {},
        {
          ...getPlatformInfo(),
        },
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async bulkActiveStatusProducts(
    params: PutBulkActiveStatusProductsParams,
  ): Promise<any> {
    try {
      const body: PutBulkActiveStatusProductsParams = {
        ...params,
        ...getPlatformInfo(),
      };
      return await this.putData('/seller/product/update-statuses', body);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async bulkRemoveProducts(params: DeleteBulkProductsParams): Promise<any> {
    try {
      const body: DeleteBulkProductsParams = {
        ...params,
        ...getPlatformInfo(),
      };
      return await this.postData('/seller/product/bulk-delete', body);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async bulkEditProducts(
    params: PutBulkEditProductsParams,
  ): Promise<Product[] | GeneralApiProblem> {
    try {
      const body: PutBulkEditProductsParams = {
        ...params,
        ...getPlatformInfo(),
      };
      return await this.putData('/seller/product/update-prices', body);
    } catch (e) {
      return e;
    }
  }

  async bulkEditResellerProducts(
    params: PutBulkEditResellerProductsParams,
  ): Promise<Product[] | GeneralApiProblem> {
    try {
      const body: PutBulkEditResellerProductsParams = {
        ...params,
        ...getPlatformInfo(),
      };
      return await this.putData('/seller/reseller/products', body);
    } catch (e) {
      return e;
    }
  }

  async getOrders(params?: GetOrdersParam): Promise<OrderResult> {
    try {
      return await this.getData('/seller/order', params);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getOrderLimit(
    params?: GetOrderLimitParam,
  ): Promise<OrderLimitResponse> {
    try {
      const response = await this.getData('/v2/seller/order-limit', params);
      return response.data;
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getOrderDetail(id: string): Promise<OrderResponse> {
    try {
      return await this.getData(`/seller/order/${id}`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   *
   * @param id order id
   * @param version set api versioning
   */
  async getResellerOrderDetail(
    id: string,
    version = '',
  ): Promise<OrderResponse> {
    try {
      return await this.getData(
        `/seller/reseller/order/${version ? `${version}/` : ''}${id}`,
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async markAsReadResellerOrder(
    orderId: string,
  ): Promise<{ success: boolean }> {
    try {
      return await this.postData(`/seller/reseller/order/${orderId}/read`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getResellerSupplierOrderDetail(id: string): Promise<OrderResponse> {
    try {
      return await this.getData(`/seller/reseller-supplier/order/${id}`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSupplierOrders(params?: GetOrdersParam): Promise<OrderResult> {
    try {
      return await this.getData('/seller/reseller/order', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSupplierOrderCount(
    params?: GetOrdersParam,
  ): Promise<SupplierOrderCountResult> {
    try {
      return await this.getData('/seller/reseller/order-count', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // TODO: dupe getResellerSuppliers(params: GetResellerSuppliersParams)
  async getResellerWholesalers(
    params?: GetResellerWholesalerParam,
  ): Promise<GetResellerWholesalerResult> {
    try {
      return await this.getData('/seller/reseller/suppliers', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getResellerSettings(): Promise<GetResellerSettingsResult> {
    try {
      return await this.getData('/seller/reseller/settings');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async storeResellerSettings(
    payload: PostResellerSettingsPayload,
  ): Promise<GetResellerSettingsResult> {
    try {
      return await this.postData('/seller/reseller/settings', {
        ...payload,
        ...getPlatformInfo(),
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerProfile(): Promise<SellerProfileResponse> {
    try {
      return await this.getData('/seller/account');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async updateSellerProfile(
    params: PostSellerProfileParams,
  ): Promise<GeneralApiProblem | number[]> {
    try {
      return await this.postData('/seller/account', {
        ...params,
        ...getPlatformInfo(),
      });
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async getSellerCategory(): Promise<SellerCategoryResponse[]> {
    try {
      return await this.getData('/seller/category');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getCitiesByStateId(stateId: number): Promise<any> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/city?state_id=${stateId}`,
      );
      return this.sanitizeFetchResponse(response)?.json();
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getDistrictsByCityId(cityId: number): Promise<any> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/district?city_id=${cityId}`,
      );
      return this.sanitizeFetchResponse(response)?.json();
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getPlaceId(
    lat: number,
    lng: number,
  ): Promise<{ results: PlaceIdResponse[]; status: string }> {
    try {
      const headers = {
        'x-api-platform': 'web-dash',
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/gmaps/geocode?lat=${lat}&lng=${lng}`,
        { headers },
      );
      return this.sanitizeFetchResponse(response)?.json();
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async getPlaceDetails(placeId: string): Promise<PlaceDetailsResponse> {
    try {
      const headers = {
        'x-api-platform': 'web-dash',
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/gmaps/place?place_id=${placeId}`,
        { headers },
      );
      return this.sanitizeFetchResponse(response)?.json();
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async getPlaceSuggestions(
    input: string,
  ): Promise<{ predictions: PlaceSuggestionsResponse[]; status: string }> {
    try {
      const headers = {
        'x-api-platform': 'web-dash',
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/gmaps/autocomplete?input=${input}`,
        { headers },
      );
      const isBodyExist = !!(await response.clone().text());
      const output = isBodyExist
        ? this.sanitizeFetchResponse(response)?.json()
        : { predictions: [], status: 'OK' };
      return output;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async getDeliveryTypeOptions(): Promise<{
    delivery_type_options: string[];
    success: boolean;
  }> {
    try {
      return await this.getData('/seller/delivery-type-options');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerProductCategory(
    params?: GetProductCategoryParam,
  ): Promise<SellerProductCategoryResponse[]> {
    try {
      return await this.getData('/seller/product-category', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async setDeliveryType(deliveryType: string): Promise<{ success: boolean }> {
    try {
      return await this.postData('/seller/delivery-type', {
        delivery_type: deliveryType,
        ...getPlatformInfo(),
      });
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async getDashboardSummary(
    params?: GetDashboardSummaryParams,
  ): Promise<GetDashboardSummaryResponse> {
    try {
      return await this.getData('/seller/dashboard', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async acceptOrder(
    id: string,
    {
      logisticFee,
      additionalFee,
      adminFeeId,
      paymentMethod,
      discount,
      discountType,
    }: AcceptOrderParams,
  ): Promise<number[]> {
    try {
      const body: PostAcceptOrderParams = {
        logistic_fee: logisticFee,
        additional_fee: additionalFee,
        ...(adminFeeId && { admin_fee_id: adminFeeId }),
        ...(paymentMethod && { payment_method: paymentMethod }),
        ...getPlatformInfo(),
      };
      if (discount && discountType) {
        body.discount_type = discountType;
        if (discountType === DiscountType.percentage) {
          body.discount_percentage = discount;
        } else {
          body.discount_amount = discount;
        }
      }
      return await this.postData(`/seller/order/${id}/accept`, body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async cancelOrder(
    id: string,
    { reason }: CancelOrderParams,
  ): Promise<number[]> {
    try {
      const body: PostCancelOrderParams = {
        cancelled_reason: reason,
        ...getPlatformInfo(),
      };
      return await this.postData(`/seller/order/${id}/cancel`, body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async confirmOrder(
    id: string,
    { deliverymanId }: ConfirmOrderData,
  ): Promise<number[]> {
    try {
      const body: PostConfirmOrderParams = {
        delivery_man_id: deliverymanId ?? undefined,
        ...getPlatformInfo(),
      };
      return await this.postData(`/seller/order/${id}/shipped`, body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async markOrderAsPaid(id: string): Promise<number[]> {
    try {
      const body: PostMarkAsPaidOrderParams = {
        ...getPlatformInfo(),
      };
      return await this.postData(`/seller/order/${id}/mark-as-paid`, body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async setOrderOnDelivery(
    id: string,
    payload: { tracking_number?: string; tracking_img?: string },
  ): Promise<number[]> {
    try {
      const body: PostSetOrderOnDeliveryParams = {
        ...getPlatformInfo(),
        ...payload,
      };
      return await this.postData(`/seller/order/${id}/deliver`, body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async completeOrder(
    id: string,
    returnItems: ReturnOrderData[],
  ): Promise<number[]> {
    try {
      const body: PostCompleteOrderParams = {
        return_items: returnItems,
        ...getPlatformInfo(),
      };
      return await this.postData(`/seller/order/${id}/complete`, body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async updateOrder(id: string, params: UpdateOrderData): Promise<number[]> {
    try {
      const body: PutUpdateOrderParams = {
        ...(params.items && { items: params.items }),
        ...(params.newItems && { new_items: params.newItems }),
        ...(params.salesId && { sales_id: params.salesId }),
        ...getPlatformInfo(),
      };
      return await this.putData(`/seller/order/${id}`, body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getOrderCount(params?: GetOrdersParam): Promise<OrderCountResult> {
    try {
      return await this.getData('/seller/order-count', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async logout(): Promise<any> {
    try {
      return await this.postData('/auth/seller/logout');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerStaff(params?: GetStaffParams): Promise<StaffResult> {
    try {
      return await this.getData('/seller/staff', {
        ...params,
        ...getPlatformInfo(),
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerRole(): Promise<StaffRole[]> {
    try {
      return await this.getData('/seller/role');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerPermission(): Promise<PermissionsResult> {
    try {
      return await this.getData('/seller/permission');
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerDeliveryman(
    params: GetDeliverymansParam,
  ): Promise<DeliverymanResult[]> {
    try {
      return await this.getData('/seller/delivery-man', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerSales(
    params: SalesListParams,
  ): Promise<{ status: boolean; data?: SalesResult[]; message?: string }> {
    try {
      const response = await this.getData('/seller/sales', params);
      if (response?.length) {
        return {
          status: true,
          data: response,
        };
      }
      return { status: false, message: MSG_GLOBAL_ERROR };
    } catch (e) {
      console.error(e);
      return { status: false, message: e.message || MSG_GLOBAL_ERROR };
    }
  }

  async postSellerStaff(params: PostStaffParams): Promise<any> {
    try {
      return await this.postData('/seller/staff', {
        ...params,
        ...getPlatformInfo(),
      });
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async putSellerStaff(
    id: string,
    params: PutStaffParams,
  ): Promise<null | Error> {
    try {
      return await this.putData(`/seller/staff/${id}`, {
        ...params,
        ...getPlatformInfo(),
      });
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async deleteSellerStaff(id: string): Promise<number[]> {
    try {
      return await this.deleteData(
        `/seller/staff/${id}`,
        {},
        {
          ...getPlatformInfo(),
        },
      );
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async postSellerProductCategory(
    params: PostSellerProductCategoryParams,
  ): Promise<any> {
    try {
      const body: PostSellerProductCategoryParams = {
        ...params,
        ...getPlatformInfo(),
      };

      return await this.postData('/seller/product-category', body);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async putSellerProductCategory(
    id: string,
    params: PutSellerProductCategoryParams,
  ): Promise<any> {
    try {
      const body: PutSellerProductCategoryParams = {
        ...params,
        ...getPlatformInfo(),
      };

      return await this.putData(`/seller/product-category/${id}`, body);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async deleteSellerProductCategory(
    id: string,
    newProductCategoryId?: string,
  ): Promise<number[]> {
    try {
      return await this.deleteData(
        `/seller/product-category/${id}`,
        {},
        {
          ...(newProductCategoryId && {
            new_product_category_id: newProductCategoryId,
          }),
          ...getPlatformInfo(),
        },
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getAdminFee(
    adminFeeParams?: GetAdminFeeParamsRequest,
  ): Promise<AdminFeeResponse[]> {
    try {
      return await this.getData('/seller/admin-fee', adminFeeParams);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async storeAdminFee(
    adminFeeData: CreateAdminFeeParams[],
    deliveryFeeData?: DeliveryFeeData[],
  ): Promise<AdminFeeResponse[]> {
    try {
      return await this.postData('/seller/admin-fee', {
        admin_fees: adminFeeData,
        ...(deliveryFeeData && { delivery_fees: deliveryFeeData }),
        ...getPlatformInfo(),
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async setOrderDueDate(data: SetOrderDueDateData[]): Promise<any> {
    try {
      return await this.postData('/seller/due-date', {
        ...data,
        ...getPlatformInfo(),
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getOrderDueDateSummary(
    params?: GetOrdersParam,
  ): Promise<OrderDueDateSummaryResponse> {
    try {
      return await this.getData('/seller/order-due-date-summary', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getOrderInvoice(orderId: string): Promise<OrderInvoiceResponse> {
    try {
      return await this.getData(`/seller/invoice/v2/${orderId}`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getResellerOrderInvoice(
    orderId: string,
  ): Promise<OrderInvoiceResponse> {
    try {
      return await this.getData(`/seller/reseller/invoice/v2/${orderId}`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getDeliveryAreas(
    params?: GetDeliveryAreaParams,
  ): Promise<{ count: number; coverage_areas: DeliveryAreaResponse[] }> {
    try {
      return await this.getData('/seller/coverage-area', {
        ...params,
        limit: params?.limit || 100,
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getDeliveryAreasV2(params?: GetDeliveryAreaParamsV2): Promise<{
    pagination: { count: number; limit: number; offset: number };
    data: DeliveryAreaResponseV2[];
  }> {
    try {
      return await this.getData('/v2/seller/coverage-area', {
        ...params,
        limit: params?.limit || 100,
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getDeliveryAreasDetailV2(
    id: string,
  ): Promise<{ data: DeliveryAreaDetailResponseV2 }> {
    try {
      return await this.getData(`/v2/seller/coverage-area/${id}`);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async storeDeliveryArea(
    params: PostDeliveryAreaParams,
  ): Promise<{ success: boolean }> {
    try {
      return await this.postData('/seller/coverage-area', {
        ...params,
        ...getPlatformInfo(),
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async updateDeliveryArea(
    id: number,
    params: PostDeliveryAreaParams,
  ): Promise<{ success: boolean }> {
    try {
      return await this.putData(`/seller/coverage-area/${id}`, {
        ...params,
        ...getPlatformInfo(),
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async deleteDeliveryArea(id: number, replacementId?: string): Promise<any> {
    try {
      return await this.deleteData(
        `/seller/coverage-area/${id}`,
        {},
        {
          ...(replacementId && { new_coverage_area_id: replacementId }),
          ...getPlatformInfo(),
        },
      );
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getCoverageAreaDetail(
    coverageAreaId: string,
    version = 'v2',
  ): Promise<{
    status: boolean;
    message?: string;
    data?: DeliveryAreaDetailResponse;
  }> {
    try {
      return await this.getData(
        `${
          version ? `/${version}` : ''
        }/seller/coverage-area/${coverageAreaId}`,
      );
    } catch (e) {
      return e;
    }
  }

  async deleteDeliveryAreaV2(
    id: string,
    replacementResellerAreaId?: string,
    replacementTradingAreaId?: string,
  ): Promise<any> {
    try {
      return await this.deleteData(
        `/v2/seller/coverage-area/${id}`,
        {},
        {
          new_coverage_area_id_reseller: replacementResellerAreaId,
          new_coverage_area_id_trading: replacementTradingAreaId,
          ...getPlatformInfo(),
        },
      );
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  // TODO: dupe getResellerSuppliers(params: GetResellerSuppliersParams)
  async getWholesaler(params?: GetWholesalerParam): Promise<WholesalerResult> {
    try {
      return await this.getData('/seller/reseller/suppliers', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getWholesalerProducts(
    params?: GetWholesaleProducts,
  ): Promise<WholesaleProductResult> {
    try {
      return await this.getData('/guest/seller-product', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getResellerWholesalerProducts(
    params?: GetWholesaleProducts,
  ): Promise<WholesaleResellerProductResult> {
    try {
      return await this.getData('/seller/reseller/v2/products', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getWholesalerCategories(
    username: string,
  ): Promise<SellerProductCategoryResponse[]> {
    try {
      return await this.getData('/guest/seller-category', { username });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async bulkCreateResellerProduct(params: BulkResellerProductsParam) {
    try {
      const body = {
        ...params,
        ...getPlatformInfo(),
      };

      return await this.postData('/seller/reseller/products', body);
    } catch (e) {
      console.error(e);
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getResellerProducts(
    params?: GetProductsResellerParam,
  ): Promise<ProductResult> {
    try {
      return await this.getData('/seller/reseller/v2/products', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getResellerProduct(id: string): Promise<ProductResponse> {
    try {
      return await this.getData(`/seller/reseller/product/${id}`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getResellerOrderReport(
    params: GetResellerOrderReportParams,
  ): Promise<ResellerOrderReportResponse> {
    try {
      return await this.getData('/seller/reseller/report', params);
    } catch (e) {
      console.error(e);
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async updateResellerProduct(
    id: string,
    params: PutProductParams,
  ): Promise<ProductResponse[]> {
    try {
      const body: PutProductParams = {
        ...params,
        ...getPlatformInfo(),
      };
      return await this.putData(`/seller/reseller/product/${id}`, body);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getResellerSuppliers(
    params: GetResellerSuppliersParams,
  ): Promise<ResellerSuppliersResponse> {
    try {
      return await this.getData('/seller/reseller/suppliers', params);
    } catch (e) {
      console.error(e);
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async storeResellerSuppliers(
    sellerIds: string[],
  ): Promise<StoreResellerSuppliersResponseItem[]> {
    try {
      return await this.postData('/seller/reseller/add-supplier', {
        seller_ids: sellerIds,
        ...(localStorage &&
          JSON.parse(localStorage.getItem(IS_STAFF)) === true && {
            platform: 'all',
          }),
      });
    } catch (e) {
      console.error(e);
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async updateResellerSupplierStatus(
    id: string,
    params: UpdateResellerSupplierStatusParams,
  ): Promise<any> {
    const body = {
      ...params,
      ...(localStorage &&
        JSON.parse(localStorage.getItem(IS_STAFF)) === true && {
          platform: 'all',
        }),
    };
    try {
      return await this.putData(`/seller/reseller/update-supplier/${id}`, body);
    } catch (e) {
      console.error(e);
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  // list all valid sellers to be set as reseller suppliers
  async getResellerSellers(params: GetResellerSellersParams): Promise<any> {
    try {
      return await this.getData('/seller/reseller/sellers', params);
    } catch (e) {
      console.error(e);
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async deleteResellerSupplier(id: string): Promise<any> {
    try {
      return await this.deleteData(`/seller/reseller/supplier/${id}`, null, {
        platform: 'all',
      });
    } catch (e) {
      console.error(e);
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async regeneratePaymentLink(
    id: string,
  ): Promise<RegeneratePaymentLinkResponse> {
    try {
      return await this.postData(
        `/seller/reseller/order/${id}/generate-oy-payment`,
      );
    } catch (e) {
      console.error(e);
    }
  }

  // TODO: promise type
  async confirmPriceChanges(
    orderId: string,
    productIds: string[],
  ): Promise<any> {
    try {
      return await this.postData(
        `/seller/reseller/order/${orderId}/product-unit-changed`,
        productIds,
      );
    } catch (e) {
      console.error(e);
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async resetPassword(password: string, staffId: string): Promise<any> {
    try {
      return await this.putData(`/seller/staff/reset-password/${staffId}`, {
        password,
        confirm_password: password,
        platform: 'web',
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async checkPaymentStatus(
    orderId: string,
  ): Promise<undefined | CheckPaymentStatusResponse> {
    try {
      return await this.getData(`/seller/check-payment-status/${orderId}`);
    } catch (e) {
      console.error(e);
    }
  }

  async getDeliveryFee(): Promise<DeliveryFeeResponse[]> {
    try {
      return await this.getData('/seller/delivery-fee', null, true);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async storeDeliveryFee(
    params: DeliveryFeeData[],
  ): Promise<DeliveryFeeResponse[]> {
    try {
      return await this.postData('/seller/delivery-fee', {
        delivery_fees: params,
        ...getPlatformInfo(),
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getBuyers(params?: BuyersListParams): Promise<BuyersListResponse> {
    try {
      return await this.getData('/seller/list-customer', params);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getBuyerDetail(buyerId?: string): Promise<BuyerDetailResponse> {
    try {
      return await this.getData(`/seller/detail-customer/${buyerId}`);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async acceptStoreVerification(buyerId: string): Promise<unknown> {
    try {
      return await this.postData(
        `/seller/accept-store/${buyerId}`,
        getPlatformInfo(),
      );
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async rejectStoreVerification({
    buyerId,
    reasons,
  }: RejectStoreVerificationParams): Promise<unknown> {
    try {
      return await this.postData(`/seller/reject-store/${buyerId}`, {
        reasons,
        ...getPlatformInfo(),
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async cancelStoreVerification({
    buyerId,
    reasons,
  }: RejectStoreVerificationParams): Promise<unknown> {
    try {
      return await this.postData(
        `/seller/cancel-verification-store/${buyerId}`,
        { reasons, ...getPlatformInfo() },
      );
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async updateBuyerTempoInfo(buyerId, params): Promise<DefaultAPIResponse> {
    try {
      return await this.putData(`/seller/customer/${buyerId}`, {
        ...params,
        ...getPlatformInfo(),
      });
    } catch (e) {
      throw Error(e?.message || MSG_GLOBAL_ERROR);
    }
  }

  async getRolePermissions(): Promise<GetRolePermissionsResponse> {
    try {
      return await this.getData('/seller/role-permissions');
    } catch (e) {
      return null;
    }
  }

  async updateRolePermission(
    id: string,
    permissionIds: number[],
  ): Promise<{ success: boolean; message?: string }> {
    try {
      return await this.putData(`/seller/role-permission/${id}`, {
        permission_ids: permissionIds,
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async createRole(data: {
    name: string;
    description?: string;
  }): Promise<{ message: string; data?: StaffRole }> {
    try {
      return await this.postData('/v2/admin/role', data);
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getSkuProducts(): Promise<ProductReportResponse> {
    try {
      return await this.getData('/seller/product-report');
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async bulkEditProductSkus(
    productSkusParam: ProductSku[],
    onUploadProgress: any,
  ) {
    try {
      return await this.putData(
        '/seller/products',
        { products: productSkusParam },
        onUploadProgress,
      );
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async changePaymentMethod(orderId: string, adminFeeId: string) {
    try {
      return await this.putData(`/seller/order/${orderId}/payment-method`, {
        admin_fee_id: adminFeeId,
      });
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async createCoverageArea(
    coverageAreaDetail,
  ): Promise<{ status: boolean; message?: string; kind?: string }> {
    try {
      return await this.postData(
        '/v2/seller/coverage-area',
        coverageAreaDetail,
      );
    } catch (e) {
      return e;
    }
  }

  async updateCoverageArea(
    coverageAreaDetail,
    id: string,
  ): Promise<{ status: boolean; message?: string; kind?: string }> {
    try {
      return await this.putData(
        `/v2/seller/coverage-area/${id}`,
        coverageAreaDetail,
      );
    } catch (e) {
      return e;
    }
  }

  async getCoverageAreaChangeLogs(params: ChangeLogRequestParams) {
    try {
      return await this.getData('/seller/dashboard-logs', params);
    } catch (e) {
      return e;
    }
  }

  async getGuestSellerProfile(params: GetGuestSellerProfileParamsRequest) {
    try {
      return await this.getData('/guest/seller-profile', params);
    } catch (e) {
      return e;
    }
  }
}

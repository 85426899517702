import Cookie from 'js-cookie';
import { milisecondsToDays } from '.';

export const JOOALAN_AT = 'JOOALAN_AT';

export const getCookies = () => Cookie.get();

export const setAccessTokenCookie = (accessToken: {
  token: string;
  expired_at: number;
}) => {
  const token = JSON.stringify(accessToken);
  Cookie.set(JOOALAN_AT, token, {
    expires: milisecondsToDays(accessToken.expired_at, false),
  });
};

export const getAccessTokenCookie = (): { token: string } => {
  const accessTokenCookie = Cookie.get(JOOALAN_AT);
  const accessToken = accessTokenCookie ? JSON.parse(accessTokenCookie) : null;
  return accessToken;
};

export const clearAccessTokenCookie = () => {
  Cookie.remove(JOOALAN_AT);
};

import currency from 'currency.js';

interface CurrencyInterface {
  symbol?: string;
  separator?: string;
  prevision?: number;
}

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const defaultCurrencyOpts = {
  symbol: 'Rp ',
  separator: '.',
  precision: 0,
};

export const defaultNumericOpts = { symbol: '', separator: '.', precision: 0 };

export const displayCurrency = (
  nominal?: number,
  options?: CurrencyInterface,
): string => {
  if (!nominal) return '0';

  return currency(nominal, options ?? defaultNumericOpts).format();
};

export const generateRandomKey = () =>
  `${new Date().getTime() + Math.random()}`;

export const underscoreToSpace = (text: string) => {
  if (!text) return '';
  return text.replace(/_/g, ' ');
};

export const alphabetOrderNumberToAlphabetChar = (order: number) => {
  return String.fromCharCode(order + 65);
};

import { cast, type Instance, types } from 'mobx-state-tree';
import {
  type WholesalerResponse,
  type User as UserResponse,
  type GetResellerSettingsResult,
  type GetResellerWholesalerSettingsResponse,
  type CityResponse,
  type StateResponse,
  type SubdistrictResponse,
  type DistrictResponse,
} from '../../utils';

export const UserModel = types.model('UserModel').props({
  id: types.identifier,
  name: types.string,
  username: types.string,
  email: types.string,
  countryCode: types.string,
  phone: types.string,
});

export type User = Instance<typeof UserModel>;

export const StateModel = types.model('StateModel').props({
  id: types.identifierNumber,
  name: types.string,
});

export type State = Instance<typeof StateModel>;

export const CityModel = types.model('CityModel').props({
  id: types.identifierNumber,
  name: types.string,
  stateId: types.number,
});

export type City = Instance<typeof CityModel>;

export const DistrictModel = types.model('DistrictModel').props({
  id: types.identifierNumber,
  name: types.string,
  cityId: types.number,
});

export type District = Instance<typeof DistrictModel>;

export const SubdistrictModel = types.model('SubdistrictModel').props({
  id: types.identifierNumber,
  name: types.string,
  districtId: types.number,
});

export type Subdistrict = Instance<typeof SubdistrictModel>;

export const SellerUserModel = types.model('SellerUserModel').props({
  id: types.union(types.identifierNumber, types.identifier),
  name: types.string,
  phone: types.string,
  wholesalerId: types.string,
});

export type SellerUser = Instance<typeof SellerUserModel>;

export const WholesalerModel = types.model('WholesalerModel').props({
  id: types.identifier,
  userId: types.string,
  resellerId: types.string,
  supplierId: types.string,
  isActive: types.boolean,
  address: types.string,
  categoryId: types.string,
  categoryName: types.string,
  sellerJopan: types.boolean,
  reseller: types.boolean,
  countOrderCompleted: types.maybeNull(types.number),
  gmvValue: types.maybeNull(types.number),
  user: types.maybe(UserModel),
});

export type Wholesaler = Instance<typeof WholesalerModel>;

export const ResellerSettingModel = types.model('ResellerSettingModel').props({
  id: types.identifier,
  resellerId: types.string,
  name: types.string,
  nameSetting: types.string,
  addressSetting: types.string,
  wholesalers: types.array(SellerUserModel),
});

export type ResellerSetting = Instance<typeof ResellerSettingModel>;

export function mapWholesalerResponseToWholesaler(
  response: WholesalerResponse,
): Wholesaler {
  return {
    id: response.supplier_id,
    userId: response.user_id ?? '',
    resellerId: response.reseller_id ?? '',
    supplierId: response.supplier_id ?? '',
    isActive: !!response.is_active,
    address: response.address ?? '',
    categoryId: response.category_id ?? '',
    categoryName: response.category_name ?? '',
    sellerJopan: !!response.seller_jopan,
    reseller: !!response.reseller,
    countOrderCompleted: response.count_order_completed ?? null,
    gmvValue: response.gmv_value ?? null,
    user: response.User ? mapUserResponseToUser(response.User) : null,
  };
}

export function mapUserResponseToUser(response: UserResponse): User {
  return {
    id: response.id,
    name: response.name ?? '',
    username: response.username ?? '',
    email: response.email ?? '',
    countryCode: response.country_code ?? '',
    phone: response.phone ?? '',
  };
}

export function mapStateResponseToState(response: StateResponse): State {
  if (!response) return null;

  return {
    id: response.id,
    name: response.name ?? '-',
  };
}

export function mapCityResponseToCity(response: CityResponse): City {
  if (!response) return null;

  return {
    id: response.id,
    name: response.name ?? '-',
    stateId: response.state_id ?? null,
  };
}

export function mapDistrictResponseToDistrict(
  response: DistrictResponse,
): District {
  if (!response) return null;

  return {
    id: response.id,
    name: response.name ?? '-',
    cityId: response.city_id ?? null,
  };
}

export function mapSubdistrictResponseToSubdistrict(
  response: SubdistrictResponse,
): Subdistrict {
  if (!response) return null;

  return {
    id: response.id,
    name: response.name ?? '-',
    districtId: response.district_id ?? null,
  };
}

function mapUsersResponseToUsers(
  response: GetResellerWholesalerSettingsResponse[],
): SellerUser[] {
  return response.map((user) => {
    return {
      id: user.wholesaler_id ?? user.id,
      name: user.name ?? '',
      phone: user.phone ?? '',
      wholesalerId: user.wholesaler_id,
    };
  });
}

export function mapResellerSettingResultToResellerSetting(
  response: GetResellerSettingsResult,
): ResellerSetting {
  return {
    id: response.id,
    resellerId: response.reseller_id,
    name: response.name ?? '',
    nameSetting: response.name_setting ?? '',
    addressSetting: response.address_setting ?? '',
    wholesalers: cast(
      response.ResellerWholesalerSettings
        ? mapUsersResponseToUsers(response.ResellerWholesalerSettings)
        : [],
    ),
  };
}

import { makeAutoObservable } from 'mobx';
import { cast } from 'mobx-state-tree';
import {
  type AcceptOrderParams,
  type CancelOrderParams,
  type ConfirmOrderData,
  type SupplierOrderCountResult,
  type SupplierOrdersCountParam,
  type GetOrdersParam,
  type GetResellerOrderReportParams,
  type OrderCountResult,
  type OrderDueDateSummaryResponse,
  type OrderResponse,
  type OrderResult,
  type SetOrderDueDateData,
  type UpdateOrderData,
  type OrderLimitResponse,
  type GetOrderLimitParam,
  type ReturnOrderData,
} from '../../utils';
import type RootStore from '../root-store';
import {
  mapOrderCountResultToOrderCount,
  type SupplierOrderCount,
  mapOrderDueDateSummary,
  mapOrderResponseToOrder,
  mapResellerOrderReportResponse,
  type Order,
  type OrderCount,
  type OrderDueDateSummary,
  type OrderItem,
  type OrderLimit,
  mapOrderLimitResponseToOrderLimit,
  type OrdersSupplier,
} from './order-store.model';

export interface OrderStoreModel {
  order: Order;
  orders: Map<string, Order>;
  ordersCount: number;
  allOrdersCount: OrderCount;
  allSupplierOrdersCount: SupplierOrderCount;
  newOrderItems: OrderItem[];
  getOrders: (params: GetOrdersParam) => Promise<Order[]>;
  getOrdersCount: () => Promise<OrderCount>;
  getOrderDetail: (trxNumber: string) => Promise<Order>;
  getSupplierOrders: (params: GetOrdersParam) => Promise<Order[]>;
  getSupplierOrdersCount: () => Promise<SupplierOrderCount>;
  acceptOrder: (id: string, params: AcceptOrderParams) => Promise<number[]>;
  cancelOrder: (id: string, params: CancelOrderParams) => Promise<number[]>;
  confirmOrder: (id: string, data: ConfirmOrderData) => Promise<number[]>;
  setOrderOnDelivery: (id: string) => Promise<number[]>;
  completeOrder: (id: string) => Promise<number[]>;
  setOrder: (order: Order) => void;
  markOrderAsPaid: (id: string) => Promise<number[]>;
  setOrderDueDate: (data: SetOrderDueDateData[]) => Promise<any>;
  changePaymentMethod: (orderId: string, adminFeeId: string) => Promise<any>;
  setVoucherFee: (voucherFee: number) => void;
  setDiscountAmount: (discount: number) => void;
}

class OrderStore {
  rootStore: RootStore;
  order: Order;
  orders: Map<string, Order> = new Map<string, Order>();
  ordersCount = 0;
  newOrderItems: OrderItem[] = [];
  allOrdersCount: OrderCount = {
    totalOrder: 0,
    totalOrderCreated: 0,
    totalOrderAccepted: 0,
    totalOrderShipped: 0,
    totalOrderOnDelivery: 0,
    totalOrderCancelled: 0,
    totalOrderCompleted: 0,
  };

  allSupplierOrdersCount: SupplierOrderCount = {
    totalOrderAccepted: 0,
    totalOrder: 0,
    totalOrderCancelled: 0,
    totalOrderCompleted: 0,
    totalOrderCreated: 0,
    totalOrderShipped: 0,
  };

  orderDueDateSummary: OrderDueDateSummary = {
    totalValue: 0,
    logisticFee: 0,
    additionalFee: 0,
    totalOrder: 0,
    totalPastDueDate: 0,
    totalTodayDueDate: 0,
    totalFutureDueDate: 0,
  };

  orderLimit: OrderLimit = {
    resellerMinOrderCashLimit: 0,
    resellerMaxOrderCashLimit: 0,
    resellerMinOrderTempoLimit: 0,
    resellerMaxOrderTempoLimit: 0,
    tradingMinOrderCashLimit: 0,
    tradingMaxOrderCashLimit: 0,
    tradingMinOrderTempoLimit: 0,
    tradingMaxOrderTempoLimit: 0,
  };

  constructor(rootStore?: RootStore) {
    if (rootStore) {
      this.rootStore = rootStore;
      makeAutoObservable(this, { rootStore: false });
    } else {
      makeAutoObservable(this);
    }
  }

  setOrder(_order: Order) {
    this.order = _order;
  }

  setOrders(_orders: Map<string, Order>) {
    this.orders = _orders;
  }

  setOrdersCount(_count: number) {
    this.ordersCount = _count;
  }

  setAllOrderCount(count: OrderCount) {
    this.allOrdersCount = count;
  }

  setAllSupplierOrderCount(count: SupplierOrderCount) {
    this.allSupplierOrdersCount = count;
  }

  setOrderLimit(data: OrderLimit) {
    this.orderLimit = data;
  }

  addNewProductToNewOrderItems(data: OrderItem, unitName: string) {
    const isUnitExist = !!this.newOrderItems.find(
      (item) => data.product.id === item.product.id && item.unit === unitName,
    );
    if (isUnitExist) {
      this.newOrderItems.forEach((item) => {
        if (item.unit === unitName) {
          item.qty = data.qty;
        }
      });
    } else {
      this.newOrderItems.push({ ...data, isNew: true });
    }
  }

  setNewOrderItems(orderItems: OrderItem[]) {
    this.newOrderItems = orderItems;
  }

  constructOrderDetail(order: Order) {
    let ordersSuppliers: OrdersSupplier[] = order?.ordersSupplier;
    order.orderItems.forEach((orderItem) => {
      const { supplierName, productId, unit, product } = orderItem;
      let selectedOrderSupplier = ordersSuppliers.find(
        (orderSupplier) => orderSupplier.supplierName === supplierName,
      );
      const isOrderItemExistInSupplier =
        selectedOrderSupplier?.orderItems?.find(
          (_orderItem) =>
            (orderItem.product.referenceProductId ?? productId) ===
            _orderItem.productId,
        );
      if (!isOrderItemExistInSupplier) {
        if (!selectedOrderSupplier) selectedOrderSupplier = cast({});
        selectedOrderSupplier.orderItems = cast([
          ...(selectedOrderSupplier?.orderItems ?? []),
          {
            basePrice: null,
            createdAt: orderItem.createdAt,
            deletedAt: null,
            id: orderItem.id,
            notes: null,
            orderId: order.id,
            orderLogs: null,
            orderStatusSupplier: null,
            price: 0,
            product: {
              name: product.name,
            },
            productId,
            qty: 0,
            unit,
            revision: null,
            ratio: null,
            updatedAt: null,
            selected: false,
            supplierId: null,
            supplierName,
            isNew: false,
          },
        ]);
        ordersSuppliers = ordersSuppliers.map((ordersSupplier) => {
          if (ordersSupplier.supplierName === supplierName) {
            ordersSupplier = selectedOrderSupplier;
          }
          return ordersSupplier;
        });
      }
    });
    const orderDetail: Order = {
      ...order,
      ordersSupplier: cast(ordersSuppliers),
    };
    this.setOrder(orderDetail);
  }

  async getOrders(
    params: GetOrdersParam,
    shouldUpdateStore = true,
    shouldReturnCount = false,
  ): Promise<any> {
    const orderResult: OrderResult = await this.rootStore.api.getOrders(params);
    const count = orderResult?.count ?? 0;
    if (
      (Array.isArray(orderResult?.rows) && count !== null) ||
      count !== undefined
    ) {
      const ordersArr = [];
      orderResult.rows?.forEach((row: OrderResponse) => {
        ordersArr.push(mapOrderResponseToOrder(row));
      });
      orderResult.changed_rows?.forEach((row: OrderResponse) => {
        ordersArr.push(mapOrderResponseToOrder(row, true));
      });
      const ordersMap = new Map<string, Order>();
      ordersArr
        .sort(
          (a: Order, b: Order) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )
        .sort((a: Order, b: Order) =>
          a.isChanged === b.isChanged ? 0 : a.isChanged ? -1 : 1,
        )
        .forEach((orderItem: Order) => ordersMap.set(orderItem.id, orderItem));
      if (shouldUpdateStore) {
        this.setOrders(ordersMap);
        this.setOrdersCount(count);
      }
      if (shouldReturnCount) {
        return {
          orders: ordersMap,
          count,
        };
      }
      return ordersMap;
    }
    return new Map();
  }

  async getOrdersCount(params?: GetOrdersParam) {
    const response: OrderCountResult =
      await this.rootStore.api.getOrderCount(params);
    const orderCount = mapOrderCountResultToOrderCount(response);
    this.setAllOrderCount(orderCount);
    return orderCount;
  }

  async getOrderLimit(params: GetOrderLimitParam) {
    const response: OrderLimitResponse =
      await this.rootStore.api.getOrderLimit(params);
    const orderMappingResponse = mapOrderLimitResponseToOrderLimit(response);
    this.setOrderLimit(orderMappingResponse);
    return orderMappingResponse;
  }

  async getSupplierOrdersCount(params?: SupplierOrdersCountParam) {
    const response: SupplierOrderCountResult =
      await this.rootStore.api.getSupplierOrderCount(params);
    const orderCount = mapOrderCountResultToOrderCount(response);
    this.setAllSupplierOrderCount(orderCount);
    return orderCount;
  }

  async getOrderDetail(trxNumber: string) {
    const response: OrderResponse =
      await this.rootStore.api.getOrderDetail(trxNumber);
    const order = response?.id ? mapOrderResponseToOrder(response) : null;
    this.setOrder(order);
    return order;
  }

  async getResellerSupplierOrderDetail(trxNumber: string) {
    const response: OrderResponse =
      await this.rootStore.api.getResellerSupplierOrderDetail(trxNumber);
    const order = response ? mapOrderResponseToOrder(response) : null;
    this.constructOrderDetail(order);
    return order;
  }

  async getResellerOrderDetail(trxNumber: string, version = '') {
    const response: OrderResponse =
      await this.rootStore.api.getResellerOrderDetail(trxNumber, version);
    const order = response?.id ? mapOrderResponseToOrder(response) : null;
    this.constructOrderDetail(order);
    return order;
  }

  async getSupplierOrders(
    params: GetOrdersParam,
    shouldUpdateStore = true,
    shouldReturnCount = false,
  ): Promise<any> {
    const orderResult: OrderResult =
      await this.rootStore.api.getSupplierOrders(params);
    const count = orderResult.count;
    if (
      (Array.isArray(orderResult.rows) && orderResult !== null) ||
      count !== undefined
    ) {
      const ordersArr = [];
      orderResult.rows?.forEach((row: OrderResponse) => {
        ordersArr.push(mapOrderResponseToOrder(row));
      });
      orderResult.changed_rows?.forEach((row: OrderResponse) => {
        ordersArr.push(mapOrderResponseToOrder(row, true));
      });
      const ordersMap = new Map<string, Order>();
      ordersArr
        .sort(
          (a: Order, b: Order) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )
        .sort((a: Order, b: Order) =>
          a.isChanged === b.isChanged ? 0 : a.isChanged ? -1 : 1,
        )
        .forEach((orderItem: Order) => ordersMap.set(orderItem.id, orderItem));
      if (shouldUpdateStore) {
        this.setOrders(ordersMap);
        this.setOrdersCount(count);
      }
      if (shouldReturnCount) {
        return {
          orders: ordersMap,
          count,
        };
      }
      return ordersMap;
    }
    return new Map();
  }

  async acceptOrder(
    id: string,
    {
      logisticFee,
      additionalFee,
      adminFeeId,
      paymentMethod,
      discount,
      discountType,
    }: AcceptOrderParams,
  ) {
    return await this.rootStore.api.acceptOrder(id, {
      logisticFee,
      additionalFee,
      adminFeeId,
      paymentMethod,
      discount,
      discountType,
    });
  }

  async cancelOrder(id: string, { reason }: CancelOrderParams) {
    return await this.rootStore.api.cancelOrder(id, { reason });
  }

  async confirmOrder(id: string, data: ConfirmOrderData) {
    return await this.rootStore.api.confirmOrder(id, data);
  }

  async setOrderOnDelivery(
    id: string,
    data: { trackingNumber: string; trackingImg?: string },
  ) {
    const payload = {
      tracking_number: data.trackingNumber,
      tracking_img: data.trackingImg,
    };
    return await this.rootStore.api.setOrderOnDelivery(id, payload);
  }

  async completeOrder(id: string, returnItems: ReturnOrderData[]) {
    return await this.rootStore.api.completeOrder(id, returnItems);
  }

  async markOrderAsPaid(id: string) {
    return await this.rootStore.api.markOrderAsPaid(id);
  }

  async updateOrder(id: string, data: UpdateOrderData) {
    /*
     * Deleted order items (qty = 0), should not be sent to update order
     * Otherwise, it will throw error from API: Barang tidak ditemukan
     */

    return await this.rootStore.api.updateOrder(id, data);
  }

  async setOrderDueDate(data: SetOrderDueDateData[]) {
    return await this.rootStore.api.setOrderDueDate(data);
  }

  setOrderDueDateSummary(summary: OrderDueDateSummary) {
    this.orderDueDateSummary = summary;
  }

  async getOrderDueDateSummary(params: GetOrdersParam) {
    const response: OrderDueDateSummaryResponse =
      await this.rootStore.api.getOrderDueDateSummary(params);
    const orderDueDateSummary = mapOrderDueDateSummary(response);
    this.setOrderDueDateSummary(orderDueDateSummary);
  }

  async getOrderInvoice(id: string, isReseller = false) {
    let response = null;
    if (isReseller) {
      response = await this.rootStore.api.getResellerOrderInvoice(id);
    } else {
      response = await this.rootStore.api.getOrderInvoice(id);
    }
    return response?.invoice_url;
  }

  async getResellerOrderReport(params: GetResellerOrderReportParams) {
    const { results } = await this.rootStore.api.getResellerOrderReport(params);
    let reportData = [];
    if (results?.length) {
      reportData = mapResellerOrderReportResponse(results);
    }
    return reportData;
  }

  async regeneratePaymentLink(id: string) {
    const response = await this.rootStore.api.regeneratePaymentLink(id);
    if (response?.payment_link) {
      const updatedOrder: Order = {
        ...this.order,
        paymentLink: response.payment_link,
      };
      this.setOrder(updatedOrder);
    }
  }

  async confirmPriceChanges(orderItemIds: string[]) {
    const { id, transactionNumber } = this.order;
    await this.rootStore.api.confirmPriceChanges(id, orderItemIds);
    await this.getResellerOrderDetail(transactionNumber, 'v2');
  }

  async checkPaymentStatus(orderId: string) {
    try {
      return await this.rootStore.api.checkPaymentStatus(orderId);
    } catch (error) {
      console.error('checkPaymentStatus', error);
    }
  }

  async changePaymentMethod(orderId: string, adminFeeId: string) {
    return await this.rootStore.api.changePaymentMethod(orderId, adminFeeId);
  }

  setVoucherFee(voucherFee: number) {
    this.order.voucherFee = voucherFee;
  }

  setDiscountAmount(discount: number) {
    this.order.discountAmount = discount;
  }

  async uploadOrderTrackingImage(file: File) {
    try {
      const { url } = await this.rootStore.api.uploadImage(file);
      if (url) {
        this.setOrder({
          ...this.order,
          trackingImg: url,
        });
        return url;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
}

export default OrderStore;

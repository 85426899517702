import React, { useState } from 'react';
import { type ConfirmationModalProps } from '.';
import { Button } from '../../button';
import { BaseModalDialog } from '../../modals/base-modal-dialog';

export const ConfirmationModal: React.FunctionComponent<
  ConfirmationModalProps
> = ({
  isOpen,
  title = 'KONFIRMASI',
  description = 'Apakah Anda yakin?',
  onConfirm,
  onCancel,
  confirmText = 'YA',
  confirmType = 'primary',
  cancelText = 'TIDAK',
  cancelType = 'tertiary',
}) => {
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] =
    useState<boolean>(false);
  const handleConfirmBtnPressed = async () => {
    setIsConfirmBtnDisabled(true);
    onConfirm();
    setIsConfirmBtnDisabled(false);
  };

  const handleCancelBtnPressed = () => {
    if (onCancel) {
      setIsConfirmBtnDisabled(true);
      onCancel();
      setIsConfirmBtnDisabled(false);
    }
  };
  return (
    <BaseModalDialog
      isOpen={isOpen}
      onClose={onCancel}
      style={{ width: '300px' }}
      dialogClassName="bg-dark-gray bg-opacity-50"
      title={title}
      titleClassName="justify-center"
      shouldCloseOnOverlayClick={false}
    >
      <div className="text-center justify-center">
        <div className="max-h-[calc(100vh-220px)] overflow-y-auto">
          <div className="my-5 whitespace-pre-wrap">{description}</div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-3 bottom-0 bg-white">
        <Button
          type={cancelType}
          fullWidth
          disabled={isConfirmBtnDisabled}
          onClick={handleCancelBtnPressed}
        >
          {cancelText}
        </Button>
        <Button
          type={confirmType}
          fullWidth
          disabled={isConfirmBtnDisabled}
          onClick={handleConfirmBtnPressed}
        >
          {confirmText}
        </Button>
      </div>
    </BaseModalDialog>
  );
};

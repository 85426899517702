import { makeAutoObservable } from 'mobx';
import {
  mapSellerCategoriesResponseToSellerCategories,
  mapSellerProductCategoriesResponseToSellerProductCategories,
  type SellerCategory,
  type SellerProductCategory,
} from '.';
import {
  type AdminFeeResponse,
  type PostSellerProfileParams,
  type SellerCategoryResponse,
  type SellerProductCategoryResponse,
  type SellerProfile,
  type SellerProfileData,
  type SellerSummaryResponse,
  IS_STAFF,
  clearAccessTokenCookie,
  MSG_GLOBAL_ERROR,
  TEAM_HO_ID,
} from '../../utils';
import type RootStore from '../root-store';
import {
  mapSellerSummaryResponseToSellerSummary,
  mapStaffRolePermisionResponseToStaffRolePermission,
  type SellerAdminFee,
  type SellerSummary,
  type StaffRolePermission,
  mapAdminFeeResponseToAdminFee,
  mapDeliveryTypesResponseToStringArr,
  mapSellerProfileResponseToSellerProfile,
  type DeliveryFee,
  mapDeliveryFeeResponseToDeliveryFee,
} from './account-store.model';
import {
  type CreateAdminFeeData,
  type DeliveryTypesResponse,
  type SellerProfileResponse,
  type DeliveryFeeData,
  type GetDashboardSummaryParams,
  type GetGuestSellerProfileParams,
} from '../../utils/interfaces';
import { type GeneralApiProblem } from '../../services/api/api-problem';

export interface AccountStoreModel {
  sellerProfile: SellerProfile;
  staffPermissions: StaffRolePermission;
  sellerCategories: SellerCategory[];
  sellerProductCategories: SellerProductCategory[];
  sellerAdminFee: SellerAdminFee[];
  sellerSummary: SellerSummary;
  deliveryTypes: string[];
  deliveryFees: DeliveryFee[];
  getSellerProfile: () => SellerProfile;
  updateSellerProfile: (data: SellerProfileData) => boolean;
  getSellerCategory: () => SellerCategory[];
  getSellerAdminFee: () => SellerAdminFee[];
  setSellerAdminFee: (adminFee: SellerAdminFee[]) => void;
  storeSellerAdminFee: (adminFee: SellerAdminFee[]) => SellerAdminFee[];
  getSellerSummary: () => SellerSummary;
  setSellerSummary: (summary: SellerSummary) => void;
  getDeliveryTypes: () => string[];
  setDeliveryTypes: (types: string[]) => void;
  setDeliveryFees: (data: DeliveryFee) => void;
}

class AccountStore {
  rootStore: RootStore;
  isSuperAdmin = true;
  isReseller = false;
  isTrading = false;
  isSupplier = false;
  isExternal = false;
  isCredistore = false;
  staffPermissions: StaffRolePermission = null;
  sellerProfile: SellerProfile = null;
  sellerCategories: SellerCategory[] = [];
  sellerProductCategories: SellerProductCategory[] = [];
  sellerAdminFee: SellerAdminFee[] = [];
  sellerSummary: SellerSummary = null;
  deliveryTypes: string[] = [];
  deliveryFees: DeliveryFee[] = [];

  constructor(rootStore?: RootStore) {
    if (rootStore) {
      this.rootStore = rootStore;
      makeAutoObservable(this, { rootStore: false });
    } else {
      makeAutoObservable(this);
    }
  }

  get activeRetailCashAdminFee() {
    return this.sellerAdminFee.filter(
      ({ active, sellerType, type }) =>
        active && sellerType === 'reseller' && type === 'cash',
    )?.[0];
  }

  get activeTradingCashAdminFee() {
    return this.sellerAdminFee.filter(
      ({ active, sellerType, type }) =>
        active && sellerType === 'trading' && type === 'cash',
    )?.[0];
  }

  get activeRetailTempoAdminFee() {
    return this.sellerAdminFee.filter(
      ({ active, sellerType, type }) =>
        active && sellerType === 'reseller' && type === 'tempo',
    );
  }

  get activeTradingTempoAdminFee() {
    return this.sellerAdminFee.filter(
      ({ active, sellerType, type }) =>
        active && sellerType === 'trading' && type === 'tempo',
    );
  }

  setIsSuperAdmin(isSuperAdmin: boolean) {
    this.isSuperAdmin = isSuperAdmin;
  }

  setStaffPermissions(staffPermissions: StaffRolePermission) {
    this.staffPermissions = staffPermissions;
  }

  setSellerProfile(profile: SellerProfile) {
    this.sellerProfile = profile;
    this.isReseller =
      profile?.sellerJopan &&
      profile?.digitalPayments &&
      !profile?.supplier &&
      !profile?.featuredWholesaler &&
      profile?.reseller;
    this.isTrading =
      profile?.sellerJopan &&
      profile?.digitalPayments &&
      !profile?.supplier &&
      profile?.featuredWholesaler &&
      !profile?.reseller;
    this.isSupplier =
      profile?.sellerJopan &&
      !profile?.digitalPayments &&
      profile?.supplier &&
      !profile?.featuredWholesaler &&
      !profile?.reseller;
    this.isExternal =
      profile?.sellerJopan &&
      !profile?.digitalPayments &&
      !profile?.supplier &&
      !profile?.featuredWholesaler &&
      !profile?.reseller;
    this.isCredistore =
      !profile?.sellerJopan &&
      !profile?.digitalPayments &&
      !profile?.supplier &&
      !profile?.featuredWholesaler &&
      !profile?.reseller;
  }

  setSellerCategories(categories: SellerCategory[]) {
    this.sellerCategories = categories;
  }

  setSellerProductCategories(categories: SellerProductCategory[]) {
    this.sellerProductCategories = categories;
  }

  setSellerSummary(sellerSummary: SellerSummary) {
    this.sellerSummary = sellerSummary;
  }

  async getRolePermissions() {
    const isStaff = JSON.parse(localStorage.getItem(IS_STAFF)) === true;
    this.setIsSuperAdmin(!isStaff);
    if (isStaff && !this.staffPermissions) {
      const response = await this.rootStore.api.getStaffPermission();
      if (response) {
        this.setStaffPermissions(
          mapStaffRolePermisionResponseToStaffRolePermission(response),
        );
      }
    }
  }

  async getSellerProfile() {
    const response: SellerProfileResponse =
      await this.rootStore.api.getSellerProfile();
    if (response) {
      this.setSellerProfile(mapSellerProfileResponseToSellerProfile(response));
    }
    return response;
  }

  async updateSellerProfile(
    data: SellerProfileData,
  ): Promise<{ success: boolean; message?: string; }> {
    try {
      const body: PostSellerProfileParams = {
        name: data.name,
        category_id: data.categoryId,
        profile_picture: data.profilePicture,
        username: data.username,
        phone: data.phone,
        email: data.email,
        address: data.address,
        latitude: data.latitude,
        longitude: data.longitude,
        additional_address: data.additionalAddress,
        delivery_area: data.deliveryArea,
        owner_name: data.ownerName,
        state_id: data.stateId,
        city_id: data.cityId,
        district_id: data.districtId,
        subdistrict_id: data.subdistrictId,
        zipcode: data.zipcode,
        ...(data.pin && { seller_pin: data.pin }),
        ...((data.resellerMinOrderCashLimit ||
          data.resellerMinOrderCashLimit === 0) && {
          reseller_min_order_cash_limit: data.resellerMinOrderCashLimit,
        }),
        ...((data.resellerMinOrderCashLimit ||
          data.resellerMinOrderTempoLimit ||
          data.resellerMaxOrderTempoLimit) && {
          reseller_max_order_cash_limit: 0,
        }),
        ...((data.resellerMinOrderTempoLimit ||
          data.resellerMinOrderTempoLimit === 0) && {
          reseller_min_order_tempo_limit: data.resellerMinOrderTempoLimit,
        }),
        ...((data.resellerMaxOrderTempoLimit ||
          data.resellerMaxOrderTempoLimit === 0) && {
          reseller_max_order_tempo_limit: data.resellerMaxOrderTempoLimit,
        }),
        ...((data.tradingMinOrderCashLimit ||
          data.tradingMinOrderCashLimit === 0) && {
          trading_min_order_cash_limit: data.tradingMinOrderCashLimit,
        }),
        ...((data.tradingMinOrderCashLimit ||
          data.tradingMinOrderTempoLimit ||
          data.tradingMaxOrderTempoLimit) && {
          trading_max_order_cash_limit: 0,
        }),
        ...((data.tradingMinOrderTempoLimit ||
          data.tradingMinOrderTempoLimit === 0) && {
          trading_min_order_tempo_limit: data.tradingMinOrderTempoLimit,
        }),
        ...((data.tradingMaxOrderTempoLimit ||
          data.tradingMaxOrderTempoLimit === 0) && {
          trading_max_order_tempo_limit: data.tradingMaxOrderTempoLimit,
        }),
        ...((data.minOrderCashLimit || data.minOrderCashLimit === 0) && {
          min_order_cash_limit: data.minOrderCashLimit,
        }),
        ...((data.minOrderCashLimit ||
          data.minOrderTempoLimit ||
          data.maxOrderTempoLimit) && { max_order_cash_limit: 0 }),
        ...((data.minOrderTempoLimit || data.minOrderTempoLimit === 0) && {
          min_order_tempo_limit: data.minOrderTempoLimit,
        }),
        ...((data.maxOrderTempoLimit || data.maxOrderTempoLimit === 0) && {
          max_order_tempo_limit: data.maxOrderTempoLimit,
        }),
        bank_account: data.bankAccountNo,
        bank_code: data.bankCode,
        bank_name: data.bankName,
        bank_owner_name: data.bankOwnerName,
      };

      if (data?.photo) {
        const { url } = await this.rootStore.api.uploadImage(data?.photo);
        if (url) body.profile_picture = url;
      }
      const response = await this.rootStore.api.updateSellerProfile(body);
      let success = true;
      let message = 'Profil berhasil diperbarui';
      if ((response as GeneralApiProblem).kind === 'rejected') {
        success = false;
        message = (response as GeneralApiProblem)?.message || MSG_GLOBAL_ERROR;
      }
      if (!success) return { success, message };
      this.setSellerProfile({
        ...this.sellerProfile,
        User: {
          ...this.sellerProfile.User,
          ...(data.name && { name: body.name }),
          ...(data.username && { username: body.username }),
          ...(data.phone && { phone: body.phone }),
          ...(data.email && { email: body.email }),
        },
        ...(data.categoryId && { category_id: body.category_id }),
        ...(data.photo && { profile_picture: body.profile_picture }),
        ...(data.address && { address: body.address }),
        ...(data.latitude && { latitude: parseFloat(body.latitude) }),
        ...(data.longitude && { longitude: parseFloat(body.longitude) }),
        ...(data.additionalAddress && {
          additional_address: body.additional_address,
        }),
        ...(data.reseller && { reseller: body.reseller }),
        ...(data.ownerName && { owner_name: body.owner_name }),
        ...(data.resellerMinOrderCashLimit && {
          resellerMinOrderCashLimit: data.resellerMinOrderCashLimit,
        }),
        ...((data.resellerMinOrderCashLimit ||
          data.resellerMinOrderTempoLimit ||
          data.resellerMaxOrderTempoLimit) && { resellerMaxOrderCashLimit: 0 }),
        ...(data.resellerMinOrderTempoLimit && {
          resellerMinOrderTempoLimit: data.resellerMinOrderTempoLimit,
        }),
        ...(data.resellerMaxOrderTempoLimit && {
          resellerMaxOrderTempoLimit: data.resellerMaxOrderTempoLimit,
        }),
        ...(data.tradingMinOrderCashLimit && {
          tradingMinOrderCashLimit: data.tradingMinOrderCashLimit,
        }),
        ...((data.tradingMinOrderCashLimit ||
          data.tradingMinOrderTempoLimit ||
          data.tradingMaxOrderTempoLimit) && { tradingMaxOrderCashLimit: 0 }),
        ...(data.tradingMinOrderTempoLimit && {
          tradingMinOrderTempoLimit: data.tradingMinOrderTempoLimit,
        }),
        ...(data.tradingMaxOrderTempoLimit && {
          tradingMaxOrderTempoLimit: data.tradingMaxOrderTempoLimit,
        }),
        ...(data.minOrderCashLimit && {
          minOrderCashLimit: data.minOrderCashLimit,
        }),
        ...((data.minOrderCashLimit ||
          data.minOrderTempoLimit ||
          data.maxOrderTempoLimit) && { maxOrderCashLimit: 0 }),
        ...(data.minOrderTempoLimit && {
          minOrderTempoLimit: data.minOrderTempoLimit,
        }),
        ...(data.maxOrderTempoLimit && {
          maxOrderTempoLimit: data.maxOrderTempoLimit,
        }),
        bankAccountNo: data.bankAccountNo,
        bankCode: data.bankCode,
        bankName: data.bankName,
        bankOwnerName: data.bankOwnerName,
      });
      return { success: true, message };
    } catch (error) {
      return { success: false, message: error.message || MSG_GLOBAL_ERROR };
    }
  }

  async getSellerCategory() {
    const response: SellerCategoryResponse[] =
      await this.rootStore.api.getSellerCategory();
    if (response.length) {
      const mapped: SellerCategory[] = response.map((category) =>
        mapSellerCategoriesResponseToSellerCategories(category),
      );
      this.setSellerCategories(mapped);
    }
    return response;
  }

  async getSellerProductCategory() {
    const response: SellerProductCategoryResponse[] =
      await this.rootStore.api.getSellerProductCategory();
    if (response.length) {
      const mapped: SellerProductCategory[] = response.map((category) =>
        mapSellerProductCategoriesResponseToSellerProductCategories(category),
      );
      this.setSellerProductCategories(mapped);
    }
    return response;
  }

  setSellerAdminFee(adminFee: SellerAdminFee[]) {
    this.sellerAdminFee = adminFee;
  }

  async getSellerAdminFee(coverageAreaId?: string): Promise<SellerAdminFee[]> {
    const response: AdminFeeResponse[] = await this.rootStore.api.getAdminFee(
      coverageAreaId ? { coverage_area_id: coverageAreaId } : null,
    );
    if (response?.length) {
      const adminFees = response.map((fee) =>
        mapAdminFeeResponseToAdminFee(fee),
      );
      this.setSellerAdminFee(adminFees);
      return adminFees;
    }
    return [];
  }

  // also store delivery fee
  async storeSellerAdminFee(
    adminFee: CreateAdminFeeData[],
    deliveryFee: DeliveryFeeData[] = null,
  ) {
    const adminFeePayload = adminFee.map((fee) => ({
      id: fee.id,
      type: fee.type,
      amount: fee.amount,
      percentage_amount: fee.percentageAmount,
      due_days: fee.dueDays,
      active: fee.active,
      ...(fee.sellerType && { seller_type: fee.sellerType }),
    }));
    const response = await this.rootStore.api.storeAdminFee(
      adminFeePayload,
      deliveryFee,
    );
    return response;
  }

  async getSellerSummary(
    params?: GetDashboardSummaryParams,
  ): Promise<SellerSummary> {
    try {
      const response: SellerSummaryResponse =
        await this.rootStore.api.getDashboardSummary(params);
      const sellerSummary = mapSellerSummaryResponseToSellerSummary(response);
      if (sellerSummary) {
        this.setSellerSummary(sellerSummary);
      }
      return sellerSummary;
    } catch (error) {
      console.error(error);
    }
  }

  setDeliveryTypes(types: string[]) {
    this.deliveryTypes = types;
  }

  async getDeliveryTypes() {
    try {
      const response: DeliveryTypesResponse =
        await this.rootStore.api.getDeliveryTypeOptions();
      const types = mapDeliveryTypesResponseToStringArr(response);
      if (types) {
        this.setDeliveryTypes(types);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async updateDeliveryType(type: string): Promise<boolean> {
    try {
      const { success }: DeliveryTypesResponse =
        await this.rootStore.api.setDeliveryType(type);
      return success;
    } catch (error) {
      console.error(error);
    }
  }

  get isCredimartSupplier() {
    return this.sellerProfile?.supplier;
  }

  setDeliveryFees(deliveryFees: DeliveryFee[]) {
    this.deliveryFees = deliveryFees;
  }

  async getDeliveryFee(): Promise<DeliveryFee[]> {
    try {
      const response = await this.rootStore.api.getDeliveryFee();
      if (response?.length) {
        const deliveryFees = response.map((deliveryFee) =>
          mapDeliveryFeeResponseToDeliveryFee(deliveryFee),
        );
        this.setDeliveryFees(deliveryFees);
        return deliveryFees;
      }
      return [];
    } catch (error) {
      throw Error(error?.message || 'Terjadi kesalahan');
    }
  }

  async storeDeliveryFee(params: DeliveryFeeData[]): Promise<boolean> {
    const response = await this.rootStore.api.storeDeliveryFee(params);
    return response?.length > 0;
  }

  setAddress(address: string) {
    if (address) this.sellerProfile.address = address;
  }

  setAdditionalAddress(additionalAddress: string) {
    if (additionalAddress)
      this.sellerProfile.additionalAddress = additionalAddress;
  }

  async logout() {
    try {
      const response = await this.rootStore.api.logout();
      if (response && response.success === 'true') {
        window.localStorage.clear();
        clearAccessTokenCookie();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async getGuestSellerProfile(
    params: GetGuestSellerProfileParams,
  ): Promise<{ status: boolean; data?: any; message?: string; }> {
    try {
      const body = {
        username: params.username,
        ...(params.coverageAreaId && {
          coverage_area_id: params.coverageAreaId,
        }),
      };
      const response = await this.rootStore.api.getGuestSellerProfile(body);
      if (response.profile) return { status: true, data: response.profile };
    } catch (error) {
      return { status: false };
    }
  }
}

export default AccountStore;

import { ORDER_DETAIL_TRANSLATION } from '../modules/order-detail/order-detail-consts';
import {
  ORDER_DELIVERY_TYPE_DELIVERY,
  ORDER_DELIVERY_TYPE_DELIVERY_PICKUP,
  ORDER_DELIVERY_TYPE_PICKUP,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CREATED,
  ORDER_STATUS_ON_DELIVERY,
  ORDER_STATUS_READY_TO_PICKUP,
  ORDER_STATUS_SHIPPED,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_TEMPO,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_PAID_COD,
  PAYMENT_STATUS_PAID_SALES,
  PAYMENT_STATUS_PAID_TRANSFER,
  PAYMENT_STATUS_UNPAID,
} from '../utils';

export const STATUS_MAP = {
  [ORDER_STATUS_CREATED]: 'Pesanan Baru',
  [ORDER_STATUS_ACCEPTED]: 'Pesanan Dikemas',
  [ORDER_STATUS_SHIPPED]: 'Pesanan Siap',
  [ORDER_STATUS_READY_TO_PICKUP]: 'Pesanan Siap Diambil', // frontend label if order (status === shipped && delivery_type === pickup)
  [ORDER_STATUS_ON_DELIVERY]: 'Pesanan Dikirim',
  [ORDER_STATUS_COMPLETED]: 'Selesai',
  [ORDER_STATUS_CANCELLED]: 'Dibatalkan',
};

export const STATUS_COLOR_MAP = {
  [ORDER_STATUS_CREATED]: 'order-created',
  [ORDER_STATUS_ACCEPTED]: 'order-accepted',
  [ORDER_STATUS_SHIPPED]: 'order-shipped',
  [ORDER_STATUS_ON_DELIVERY]: 'order-on_delivery',
  [ORDER_STATUS_COMPLETED]: 'order-completed',
  [ORDER_STATUS_CANCELLED]: 'order-cancelled',
};

export const PAYMENT_MAP = {
  [PAYMENT_STATUS_PAID]: 'Lunas - Sales',
  [PAYMENT_STATUS_PAID_TRANSFER]: 'Lunas - Transfer',
  [PAYMENT_STATUS_PAID_COD]: 'Lunas - Tunai',
  [PAYMENT_STATUS_UNPAID]: 'Belum Dibayar',
};

export const PAYMENT_COLOR_MAP = {
  [PAYMENT_STATUS_PAID]: 'bg-jungle',
  [PAYMENT_STATUS_PAID_TRANSFER]: 'bg-jungle',
  [PAYMENT_STATUS_PAID_SALES]: 'bg-jungle',
  [PAYMENT_STATUS_PAID_COD]: 'bg-jungle',
  [PAYMENT_STATUS_UNPAID]: 'bg-primary',
};

export const DELIVERY_TYPE_MAP = {
  [ORDER_DELIVERY_TYPE_DELIVERY]: 'Dikirim',
  [ORDER_DELIVERY_TYPE_PICKUP]: 'Ambil di tempat',
  [ORDER_DELIVERY_TYPE_DELIVERY_PICKUP]: 'Ambil di tempat dan dikirim',
};

export const PAYMENT_METHOD_MAP = {
  [PAYMENT_METHOD_CASH.toLowerCase()]:
    ORDER_DETAIL_TRANSLATION.PAYMENT_METHOD_CASH,
  [PAYMENT_METHOD_TEMPO]: 'Tempo',
};

import { makeAutoObservable } from 'mobx';
import { type ChangeLogRequestParams, MSG_GLOBAL_ERROR } from '../../utils';
import type RootStore from '../root-store';
import {
  type ChangeLog,
  mapChangeLogsResponseToChangeLogs,
} from './changelog-store.model';

class ChangeLogStore {
  rootStore: RootStore;
  changeLogs: ChangeLog[] = [];

  constructor(rootStore?: RootStore) {
    if (rootStore) {
      this.rootStore = rootStore;
      makeAutoObservable(this, { rootStore: false });
    } else {
      makeAutoObservable(this);
    }
  }

  setCoverageAreaChangeLogs(_changeLogs: ChangeLog[]) {
    this.changeLogs = _changeLogs;
  }

  async getChangeLogs(params: ChangeLogRequestParams) {
    try {
      const { status, data } =
        await this.rootStore.api.getCoverageAreaChangeLogs(params);
      if (status && data) {
        const mappedLogs = mapChangeLogsResponseToChangeLogs(data);
        this.setCoverageAreaChangeLogs(mappedLogs);
        return { status: true };
      }
      return { status: false, message: MSG_GLOBAL_ERROR };
    } catch (e) {
      return { status: false, message: e.message || MSG_GLOBAL_ERROR };
    }
  }
}

export default ChangeLogStore;

import React, { useContext } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { LoadingContext } from '.';
import { palette } from '../../../theme';

export const Loading: React.FunctionComponent<any> = (props) => {
  const {
    content: { visible },
  } = useContext(LoadingContext);

  if (!visible) {
    return null;
  }

  return (
    <div
      className="position-fixed mt-6 justify-center"
      style={{ width: 80, left: '50%', zIndex: 999, top: 0 }}
    >
      <PulseLoader color={palette.orangeDarker} />
    </div>
  );
};

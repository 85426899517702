import { makeAutoObservable } from 'mobx';
import type RootStore from '../root-store';
import {
  type GetResellerSettingsResult,
  type PostResellerSettingsPayload,
  type GetResellerSuppliersParams,
  type ResellerSupplier,
  type UpdateResellerSupplierStatusParams,
  type ResellerSeller,
  type GetResellerSellersParams,
  type GetWholesaleProductsParam,
} from '../../utils/interfaces';
import { type SelectOption } from '../../components/forms/select';
import {
  type Wholesaler,
  mapWholesalerResponseToWholesaler,
  type ResellerSetting,
  mapResellerSettingResultToResellerSetting,
} from './wholesaler-store.model';
import {
  type Product,
  type ProductCategory,
  mapProductResponseToProduct,
  mapSellerProductCategoryResponseToProductCategory,
  mapSuppliersResponseToSuppliers,
  mapSellersResponseToSellers,
} from '../product-store/product-store.model';
import { isRequestSuccess } from '../../utils/api';
import {
  DEFAULT_SUPPLIER_PRODUCT_PER_PAGE,
  MSG_GLOBAL_ERROR,
  ORDER_BY_NAME,
  ORDER_BY_NEWEST,
  ORDER_BY_PRICE,
  SORT_BY_ASC,
  SORT_BY_DESC,
} from '../../utils';

export enum WholesalerProductFilterType {
  all,
  new,
  promo,
  personal,
}
export enum WholesalerProductSortType {
  newest,
  lowest,
  highest,
}

class WholesalerStore {
  rootStore: RootStore;
  wholesalers = new Map<string, Wholesaler>();
  selectedSupplier: string;
  wholesalerProducts = new Map<string, Product>();
  wholesalerProductsCount: number;
  wholesalerCategories: ProductCategory[] = [];
  wholesalerProductSearchFilter: string;
  wholesalerProductFilterType: WholesalerProductFilterType =
    WholesalerProductFilterType.all;

  wholesalerProductSortType: WholesalerProductSortType = null;
  wholesalerCategoryFilter: SelectOption;
  resellerSetting: ResellerSetting;
  suppliers = new Map<string, ResellerSupplier>();
  suppliersCount: number;
  sellers = new Map<string, ResellerSeller>();
  sellersCount: number;

  constructor(rootStore?: RootStore) {
    if (rootStore) {
      this.rootStore = rootStore;
      makeAutoObservable(this, { rootStore: false });
    } else {
      makeAutoObservable(this);
    }
  }

  get allWholesaler(): Wholesaler[] {
    if (!this.wholesalers) return [];
    return Array.from(this.wholesalers.values());
  }

  get allWholesalerProduct(): Product[] {
    if (!this.wholesalerProducts) return [];
    return Array.from(this.wholesalerProducts.values());
  }

  get supplier(): Wholesaler {
    return this.wholesalers.get(this.selectedSupplier);
  }

  get currentSupplier(): string {
    return this.selectedSupplier;
  }

  setWholesalers(wholesalers: Map<string, Wholesaler>) {
    this.wholesalers = wholesalers;
  }

  setSelectedSuppliers(supplierId: string) {
    this.selectedSupplier = supplierId;
  }

  setWholesalerProducts(id: string, product: Product) {
    this.wholesalerProducts.set(id, product);
  }

  setWholesalerProductsCount(productsCount: number) {
    this.wholesalerProductsCount = productsCount;
  }

  setWholesalerCategories(categories: ProductCategory[]) {
    this.wholesalerCategories = categories;
  }

  setWholesaleProductSearchFilter(query: string) {
    this.wholesalerProductSearchFilter = query;
  }

  setWholesalerProductFilterType(type: WholesalerProductFilterType) {
    this.wholesalerProductFilterType = type;
  }

  setWholesalerProductSortType(sort: WholesalerProductSortType) {
    this.wholesalerProductSortType = sort;
  }

  setWholesalerCategoryFilter(category: SelectOption) {
    this.wholesalerCategoryFilter = category;
  }

  resetCurrentWholesalerProducts() {
    this.wholesalerProducts.clear();
  }

  resetWholesalerFilter() {
    this.wholesalerProductSearchFilter = null;
    this.wholesalerProductFilterType = WholesalerProductFilterType.all;
    this.wholesalerProductSortType = null;
    this.wholesalerCategoryFilter = null;
  }

  setResellerSetting(setting: ResellerSetting) {
    this.resellerSetting = setting;
  }

  async getAllWholesaler() {
    const { count: allWholesalerCount } =
      await this.rootStore.api.getWholesaler({ limit: 1 });
    if (allWholesalerCount != null || allWholesalerCount !== undefined) {
      const { count, rows } = await this.rootStore.api.getWholesaler({
        limit: allWholesalerCount,
      });
      if ((Array.isArray(rows) && count !== null) || count !== undefined) {
        const wholesalers = new Map();
        rows?.forEach((row) => {
          wholesalers.set(
            row.supplier_id,
            mapWholesalerResponseToWholesaler(row),
          );
        });
        this.setWholesalers(wholesalers);
      }
    }
  }

  async getAllWholesalerProduct(
    params: GetWholesaleProductsParam,
    isReseller = false,
  ) {
    let isNew = false;
    let isPromoActive = false;
    switch (this.wholesalerProductFilterType) {
      case WholesalerProductFilterType.new:
        isNew = true;
        break;
      case WholesalerProductFilterType.promo:
        isPromoActive = true;
        break;
      default:
        break;
    }

    let orderBy;
    let sortBy;
    switch (this.wholesalerProductSortType) {
      case WholesalerProductSortType.newest:
        orderBy = ORDER_BY_NEWEST;
        sortBy = SORT_BY_DESC;
        break;
      case WholesalerProductSortType.highest:
        orderBy = ORDER_BY_PRICE;
        sortBy = SORT_BY_DESC;
        break;
      case WholesalerProductSortType.lowest:
        orderBy = ORDER_BY_PRICE;
        sortBy = SORT_BY_ASC;
        break;
      default:
        // sort by name AZ
        orderBy = ORDER_BY_NAME;
        sortBy = SORT_BY_ASC;
        break;
    }

    const {
      available,
      username,
      offset,
      supplierId,
      coverageAreaIds,
      isTradingAccount,
      isExternalAccount,
    } = params;
    // GET ALL WHOLESALE PRODUCT
    const payload = {
      limit: DEFAULT_SUPPLIER_PRODUCT_PER_PAGE,
      offset,
      order_by: orderBy,
      sort_by: sortBy,
      // ...(!isReseller && { is_reseller_product: 'no' }),
      ...(available && { available }),
      ...(username && { username }),
      ...(supplierId && { wholesaler_id: supplierId }),
      ...(this.wholesalerProductSearchFilter && {
        name: this.wholesalerProductSearchFilter,
      }),
      ...(isNew && { is_new: true }),
      ...(isPromoActive && { promo_active: true }),
      ...(this.wholesalerCategoryFilter && {
        product_category_id: this.wholesalerCategoryFilter?.key,
      }),
      ...(coverageAreaIds && { coverage_area_id: coverageAreaIds }),
    };

    let wholesaleRows, allWholesaleCount;
    if (
      this.wholesalerProductFilterType ===
        WholesalerProductFilterType.personal ||
      isTradingAccount ||
      isExternalAccount
    ) {
      delete payload.coverage_area_id;
      delete payload.wholesaler_id;
      // delete payload.is_reseller_product;
      const { count, rows } = await this.rootStore.api.getProducts(payload);
      wholesaleRows = rows;
      allWholesaleCount = count;
    } else if (isReseller) {
      const response =
        await this.rootStore.api.getResellerWholesalerProducts(payload);
      wholesaleRows = response.rows;
      allWholesaleCount = response.count;
    } else if (username) {
      delete payload.coverage_area_id;
      const response = await this.rootStore.api.getWholesalerProducts(payload);
      if (response) {
        wholesaleRows = response.product.rows;
        allWholesaleCount = response.product.count;
      }
    }

    const allWholesaleProduct: Product[] = [];
    if (allWholesaleCount !== null || allWholesaleCount !== undefined) {
      this.setWholesalerProductsCount(allWholesaleCount);
      if (
        (Array.isArray(wholesaleRows) && allWholesaleCount !== null) ||
        allWholesaleCount !== undefined
      ) {
        allWholesaleProduct.push(
          ...wholesaleRows.map((row) => mapProductResponseToProduct(row)),
        );
      }
    }

    allWholesaleProduct?.forEach((row) => {
      this.setWholesalerProducts(row.id, row);
    });
  }

  async getWholesalerCategories(username: string) {
    const response = await this.rootStore.api.getWholesalerCategories(username);
    if (response.length) {
      const mapped: ProductCategory[] = response.map((category) =>
        mapSellerProductCategoryResponseToProductCategory(category),
      );
      this.setWholesalerCategories(mapped);
    }
  }

  async getResellerSettings() {
    const result: GetResellerSettingsResult =
      await this.rootStore.api.getResellerSettings();
    if (result) {
      const resellerSetting = mapResellerSettingResultToResellerSetting(result);
      if (resellerSetting) {
        this.setResellerSetting(resellerSetting);
      }
    }
  }

  async storeResellerSettings(payload: ResellerSetting) {
    try {
      const body: PostResellerSettingsPayload = {
        name: payload.name,
        name_setting: payload.nameSetting,
        address_setting: payload.addressSetting,
        wholesalers: payload.wholesalers?.map((wholesaler) => ({
          id: wholesaler.id as string,
          name: wholesaler.name,
          phone: wholesaler.phone,
        })),
      };
      const result: GetResellerSettingsResult =
        await this.rootStore.api.storeResellerSettings(body);
      return {
        status: !!result.id,
        message: 'success',
      };
    } catch (error) {
      return {
        status: false,
        message: error?.message,
      };
    }
  }

  setSuppliers(suppliers: Map<string, ResellerSupplier>) {
    this.suppliers = suppliers;
  }

  setSuppliersCount(count: number) {
    this.suppliersCount = count;
  }

  async getResellerSuppliers(params?: GetResellerSuppliersParams) {
    const { count, rows } =
      await this.rootStore.api.getResellerSuppliers(params);
    if (Array.isArray(rows)) {
      const suppliers = new Map();
      rows?.forEach((row) => {
        suppliers.set(row.id, mapSuppliersResponseToSuppliers(row));
      });
      this.setSuppliers(suppliers);
      this.setSuppliersCount(count);
    }
  }

  get resellerSuppliers(): ResellerSupplier[] {
    if (!this.suppliers) return [];
    return Array.from(this.suppliers.values());
  }

  async updateResellerSupplierStatus(
    id: string,
    params: UpdateResellerSupplierStatusParams,
  ) {
    const response = await this.rootStore.api.updateResellerSupplierStatus(
      id,
      params,
    );
    const isSuccess = isRequestSuccess(response);
    return isSuccess;
  }

  setSellers(sellers: Map<string, ResellerSeller>) {
    this.sellers = sellers;
  }

  setSellersCount(count: number) {
    this.sellersCount = count;
  }

  async getResellerSellers(params?: GetResellerSellersParams) {
    const { count, rows } = await this.rootStore.api.getResellerSellers(params);
    if (Array.isArray(rows)) {
      const sellers = new Map();
      rows?.forEach((row) => {
        sellers.set(row.id, mapSellersResponseToSellers(row));
      });
      this.setSellers(sellers);
      this.setSellersCount(count);
    }
  }

  async loadMoreResellerSellers(params?: GetResellerSellersParams) {
    const { count, rows } = await this.rootStore.api.getResellerSellers(params);
    if (Array.isArray(rows)) {
      const newSellers = new Map();
      rows?.forEach((row) => {
        newSellers.set(row.id, mapSellersResponseToSellers(row));
      });
      const sellers = new Map<string, ResellerSeller>([
        ...this.sellers,
        ...newSellers,
      ]);
      this.setSellers(sellers);
      this.setSellersCount(count);
    }
  }

  get resellerSellers(): ResellerSeller[] {
    if (!this.sellers) return [];
    return Array.from(this.sellers.values());
  }

  async storeResellerSuppliers(sellerIds?: string[]) {
    try {
      const response =
        await this.rootStore.api.storeResellerSuppliers(sellerIds);
      return response;
    } catch (e) {
      throw Error(e?.message || MSG_GLOBAL_ERROR);
    }
  }

  async deleteResellerSupplier(id: string) {
    try {
      const response = await this.rootStore.api.deleteResellerSupplier(id);
      const isSuccess = isRequestSuccess(response);
      return isSuccess;
    } catch (e) {
      throw Error(e?.message || MSG_GLOBAL_ERROR);
    }
  }
}

export default WholesalerStore;

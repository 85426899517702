import { createContext } from 'react';
import { type LoadingProps } from '.';

export const initialLoadingContent: LoadingProps = {
  visible: false,
};

export const LoadingContext = createContext({
  content: initialLoadingContent,
  stopLoading: () => null,
  startLoading: () => null,
});

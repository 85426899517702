import { makeAutoObservable } from 'mobx';
import {
  type BulkProductImagesParam,
  type CreateProductBulkData,
  type CreateProductCategoryData,
  type CreateProductImageParam,
  type CreateProductParams,
  type CreateProductUnitParam,
  type GetProductCategoryParam,
  type GetProductsParam,
  MSG_GLOBAL_ERROR,
  type PostBulkProductImagesParams,
  type PostSellerProductCategoryParams,
  type ProductPriceParams,
  type ProductPriceUnitParams,
  type ProductResponse,
  type ProductResult,
  type PutBulkActiveStatusProductsResponse,
  type PutProductImageParam,
  type PutProductParams,
  type PutProductUnitParam,
  type SellerProductCategoryResponse,
  type Unit,
  type UnitResult,
  type UpdateProductCategoryData,
} from '../../utils';
import { isRequestSuccess } from '../../utils/api';
import type RootStore from '../root-store';
import {
  mapProductResponseToProduct,
  mapSellerProductCategoryResponseToProductCategory,
  mapUnitResultToUnit,
  type Product,
  type ProductCategory,
  type ProductUnit,
} from './product-store.model';
import {
  type BulkResellerProductsParam,
  type CreateProductBulkParams,
  type GetProductsResellerParam,
  type ProductCategoryResponse,
  type ProductSku,
  type ResellerEditProductParams,
} from '../../utils/interfaces';
import type { SelectOption } from '../../components/forms/select';
import { type GeneralApiProblem } from '../../services/api/api-problem';

export interface ProductStoreModel {
  units: Unit[];
  unitsCount: number;
  product: Product;
  products: Map<string, Product>;
  productsCount: number;
  getProducts: (params: GetProductsParam) => void;
  updateProduct: (id: string, params: Product) => boolean;
  updateProductImages: (params: BulkProductImagesParam[]) => boolean;
  removeProduct: (id: string) => boolean;
  getUnits: () => void;
  getUnit: (id: string) => Unit;
  storeUnit: (name: string) => Unit;
  removeUnit: (id: string) => void;
  setProductCategoryQuery: (query: string) => void;
}

class ProductStore {
  rootStore: RootStore;
  units: Unit[] = [];
  unitsCount = 0;
  product: Product = null;
  productCategoriesMap = new Map<string, ProductCategory>();
  productCategoryQuery: string = null;
  products = new Map<string, Product>();
  productsCount = 0;
  resellerProducts = new Map<string, Product>();
  resellerProductsCount = 0;
  selectedProduct: string = null;

  constructor(rootStore?: RootStore) {
    if (rootStore) {
      this.rootStore = rootStore;
      makeAutoObservable(this, { rootStore: false });
    } else {
      makeAutoObservable(this);
    }
  }

  get allProductCategories(): ProductCategory[] {
    if (!this.productCategoriesMap) return [];
    return Array.from(this.productCategoriesMap.values()).sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }

  get productCategories(): ProductCategory[] {
    if (!this.productCategoriesMap) return [];
    let categories = this.allProductCategories;
    if (this.productCategoryQuery) {
      categories = categories.filter((cat) =>
        cat.name
          .toLowerCase()
          .includes(this.productCategoryQuery.toLowerCase()),
      );
    }
    return categories;
  }

  get allProducts(): Product[] {
    if (!this.products) return [];
    return Array.from(this.products.values());
  }

  get allResellerProduct(): Product[] {
    if (!this.resellerProducts) return [];
    return Array.from(this.resellerProducts.values());
  }

  get categorySelectOptions(): SelectOption[] {
    return (
      this.productCategories
        ?.filter((category: ProductCategory) => category.active)
        .map((category: ProductCategory) => ({
          key: category.id,
          value: category.margin
            ? `${category.name} (${String(category.margin)}%)`
            : `${category.name}`,
        })) ?? []
    );
  }

  setProductCategoryQuery(query: string) {
    this.productCategoryQuery = query;
  }

  setProductDetail(product: Product) {
    this.product = product;
  }

  setProduct(product: Product) {
    this.products.set(product.id, product);
  }

  setSelectedProduct(productId: string) {
    this.selectedProduct = productId;
  }

  deleteProduct(id: string) {
    this.products.delete(id);
  }

  setProducts(_products: Map<string, Product>) {
    this.products = _products;
  }

  setProductsCount(count: number) {
    this.productsCount = count;
  }

  setResellerProducts(products: Map<string, Product>) {
    this.resellerProducts = products;
  }

  deleteResellerProduct(id: string) {
    this.resellerProducts.delete(id);
  }

  setResellerProductsCount(_count: number) {
    this.resellerProductsCount = _count;
  }

  setUnits(_units: Unit[]) {
    this.units = _units;
  }

  setUnitsCount(count: number) {
    this.unitsCount = count;
  }

  setProductCategoriesMap(category: Map<string, ProductCategory>) {
    this.productCategoriesMap = category;
  }

  async getUnits(): Promise<Unit[]> {
    const result: UnitResult = await this.rootStore.api.getProductUnits();
    const mappedUnits = [];
    result?.rows?.forEach((row) => {
      mappedUnits.push(mapUnitResultToUnit(row));
    });
    this.setUnits(mappedUnits);
    return result.rows;
  }

  async getUnit(id: string) {
    const unitResult = await this.rootStore.api.getProductUnit(id);
    return mapUnitResultToUnit(unitResult);
  }

  async storeUnit(name: string) {
    return await this.rootStore.api.storeProductUnit(name);
  }

  async removeUnit(id: string, replacementId?: string) {
    return await this.rootStore.api.removeProductUnit(id, replacementId);
  }

  async getProducts(params?: GetProductsParam) {
    const { rows, count }: ProductResult =
      await this.rootStore.api.getProducts(params);
    if ((Array.isArray(rows) && count !== null) || count !== undefined) {
      const _products = new Map();
      rows?.forEach((row: ProductResponse) => {
        _products.set(row.id, mapProductResponseToProduct(row));
      });
      this.setProducts(_products);
      this.setProductsCount(count);
    }
  }

  async getProduct(id: string): Promise<Product> {
    const response: ProductResponse = await this.rootStore.api.getProduct(id);
    let mappedProduct = null;
    if (response) {
      mappedProduct = mapProductResponseToProduct(response);
      mappedProduct = {
        ...mappedProduct,
        productUnits: mappedProduct.productUnits
          .slice()
          .sort(
            (first: ProductUnit, second: ProductUnit) =>
              first.price - second.price,
          ),
      };
      this.setProductDetail(mappedProduct);
    }
    return mappedProduct;
  }

  async bulkCreateProduct(
    data: CreateProductBulkData[],
    unitType: null | 'credimart',
  ): Promise<ProductResponse[] | boolean> {
    try {
      const params: any = data.map((product) => {
        const unit1Id = this.units.find(
          (u) => u.name.toLowerCase() === product.unit1.toLowerCase(),
        )?.id;

        const mapped: CreateProductBulkParams = {
          name: product.name,
          category_name: product.categoryName,
          units: [
            {
              unit: product.unit1,
              base_price: product.base1,
              price: product.price1,
              user_unit_id: unit1Id,
              unit_type: unitType,
            },
          ],
        };

        if (product.sku) mapped.sku = product.sku;
        const coverageAreaIds = [];
        if (product.coverageAreaId1)
          coverageAreaIds.push(product.coverageAreaId1);
        if (product.coverageAreaId2)
          coverageAreaIds.push(product.coverageAreaId2);
        if (product.coverageAreaId3)
          coverageAreaIds.push(product.coverageAreaId3);
        if (coverageAreaIds.length) {
          mapped.coverage_area_id = coverageAreaIds.join();
        }
        if (product.description) mapped.description = product.description;

        if (product.qty1a)
          mapped.units.push({
            unit: product.unit1,
            wholesale_price: true,
            min_quantity: product.qty1a,
            base_price: product.base1a,
            price: product.price1a,
            user_unit_id: unit1Id,
            unit_type: unitType,
          });
        if (product.qty1b)
          mapped.units.push({
            unit: product.unit1,
            wholesale_price: true,
            min_quantity: product.qty1b,
            base_price: product.base1b,
            price: product.price1b,
            user_unit_id: unit1Id,
            unit_type: unitType,
          });
        if (product.qty1c)
          mapped.units.push({
            unit: product.unit1,
            wholesale_price: true,
            min_quantity: product.qty1c,
            base_price: product.base1c,
            price: product.price1c,
            user_unit_id: unit1Id,
            unit_type: unitType,
          });

        if (product.unit2) {
          const unit2Id = this.units.find(
            (u) => u.name.toLowerCase() === product.unit2.toLowerCase(),
          )?.id;
          mapped.units.push({
            unit: product.unit2,
            base_price: product.base2,
            price: product.price2,
            user_unit_id: unit2Id,
            unit_type: unitType,
          });
          if (product.qty2a)
            mapped.units.push({
              unit: product.unit2,
              wholesale_price: true,
              min_quantity: product.qty2a,
              base_price: product.base2a,
              price: product.price2a,
              user_unit_id: unit2Id,
              unit_type: unitType,
            });
          if (product.qty2b)
            mapped.units.push({
              unit: product.unit2,
              wholesale_price: true,
              min_quantity: product.qty2b,
              base_price: product.base2b,
              price: product.price2b,
              user_unit_id: unit2Id,
              unit_type: unitType,
            });
          if (product.qty2c)
            mapped.units.push({
              unit: product.unit2,
              wholesale_price: true,
              min_quantity: product.qty2c,
              base_price: product.base2c,
              price: product.price2c,
              user_unit_id: unit2Id,
              unit_type: unitType,
            });
        }

        if (product.unit3) {
          const unit3Id = this.units.find(
            (u) => u.name.toLowerCase() === product.unit3.toLowerCase(),
          )?.id;
          mapped.units.push({
            unit: product.unit3,
            base_price: product.base3,
            price: product.price3,
            user_unit_id: unit3Id,
            unit_type: unitType,
          });
          if (product.qty3a)
            mapped.units.push({
              unit: product.unit3,
              wholesale_price: true,
              min_quantity: product.qty3a,
              base_price: product.base3a,
              price: product.price3a,
              user_unit_id: unit3Id,
              unit_type: unitType,
            });
          if (product.qty3b)
            mapped.units.push({
              unit: product.unit3,
              wholesale_price: true,
              min_quantity: product.qty3b,
              base_price: product.base3b,
              price: product.price3b,
              user_unit_id: unit3Id,
              unit_type: unitType,
            });
          if (product.qty3c)
            mapped.units.push({
              unit: product.unit3,
              wholesale_price: true,
              min_quantity: product.qty3c,
              base_price: product.base3c,
              price: product.price3c,
              user_unit_id: unit3Id,
              unit_type: unitType,
            });
        }

        return mapped;
      });
      const result = await this.rootStore.api.bulkCreateProduct(params);
      return result;
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async createProduct(params: Product, areaId?: string) {
    const images: CreateProductImageParam[] = params.productImages?.map(
      (image) => ({
        url: image.imageUrl,
        featured: image.featured,
      }),
    );

    const units: CreateProductUnitParam[] = params.productUnits?.map(
      (unit) => ({
        user_unit_id: unit.userUnitId,
        unit: unit.unit,
        ratio: unit.ratio,
        base_price: unit.basePrice,
        price: unit.price,
        wholesale_price: unit.wholesalePrice,
        min_quantity: unit.minQty,
        discount_price: unit.discountPrice,
        unit_type: unit.unitType,
        available_online: unit.availableOnline,
      }),
    );

    const body: CreateProductParams = {
      units,
      images,
      name: params.name,
      available: params.available,
      description: params.description,
      sku: params.sku,
      product_category_id: params.productCategoryId,
      promo_active: params.promoActive,
      coverage_area_id: areaId,
    };

    const response = await this.rootStore.api.createProduct(body);
    return !!response.id;
  }

  async updateProduct(id: string, params: Product, areaId?: string) {
    const images: PutProductImageParam[] = params.productImages?.map(
      (image) => ({
        url: image.imageUrl,
        featured: image.featured,
      }),
    );

    const units: PutProductUnitParam[] = params.productUnits?.map((unit) => ({
      user_unit_id: unit.userUnitId,
      unit: unit.unit,
      ratio: unit.ratio,
      base_price: unit.basePrice,
      price: unit.price,
      wholesale_price: unit.wholesalePrice,
      min_quantity: unit.minQty,
      discount_price: unit.discountPrice,
      unit_type: unit.unitType,
      featured: !!unit.featured,
      available_online:
        unit.unitType === 'credimart' && !params.available
          ? false
          : unit.availableOnline, // if product not available, automatically set val to false for credimart price unit
    }));

    const body: PutProductParams = {
      units,
      images,
      name: params.name,
      available: params.available,
      description: params.description,
      sku: params.sku,
      product_category_id: params.productCategoryId,
      promo_active: params.promoActive,
      coverage_area_id: areaId,
    };

    const response = await this.rootStore.api.updateProduct(id, body);
    const isSuccess = isRequestSuccess(response);
    if (isSuccess) {
      this.setProduct(params);
    }
    return isSuccess;
  }

  async updateProductImages(params: BulkProductImagesParam[]) {
    const body: PostBulkProductImagesParams = {
      products: params,
    };
    const { success } = await this.rootStore.api.bulkProductImages(body);
    const isSuccess = !!success;
    return isSuccess;
  }

  async removeProduct(id: string) {
    const { success } = await this.rootStore.api.removeProduct(id);
    const isSuccess = !!success;
    if (isSuccess) {
      this.deleteProduct(id);
    }
    return isSuccess;
  }

  async bulkActiveStatusProducts(
    products: string[],
    available: boolean,
    isResellerProducts: boolean,
  ): Promise<boolean> {
    const response: PutBulkActiveStatusProductsResponse =
      await this.rootStore.api.bulkActiveStatusProducts({
        products,
        available,
      });
    const updatedProducts = response.update_products;
    if (updatedProducts?.length > 0) {
      products.forEach((id: string) => {
        let product = this.products.get(id);
        if (isResellerProducts) {
          product = this.resellerProducts.get(id);
        }
        product.available = false;
        this.setProduct(product);
      });
      return true;
    }
    return false;
  }

  async bulkRemoveProducts(
    products: string[],
    isResellerProducts: boolean,
  ): Promise<boolean> {
    const response: string[] = await this.rootStore.api.bulkRemoveProducts({
      products,
    });
    if (response?.length > 0) {
      products.forEach((id: string) => {
        if (isResellerProducts) {
          this.deleteResellerProduct(id);
        } else {
          this.deleteProduct(id);
        }
      });
      return true;
    }
    return false;
  }

  async bulkEditProducts(
    products: Product[],
  ): Promise<{ status: boolean; message?: string }> {
    try {
      const request: ProductPriceParams[] = products.map((product: Product) => {
        let units: ProductPriceUnitParams[] = [];
        units = product.productUnits?.reduce((unitsData, unit) => {
          const unitPayload = {
            unit: unit.unit,
            price: unit.price,
            base_price: unit.basePrice,
            min_quantity: unit.minQty,
            user_unit_id: unit.userUnitId,
            wholesale_price: unit.wholesalePrice,
            unit_type: unit.unitType,
            available_online: unit.availableOnline,
          };
          if (unit.credimartPrice) {
            unitsData.push({
              ...unitPayload,
              price: unit.credimartPrice,
              unit_type: 'credimart',
            });
          }
          if (unit.price) {
            unitsData.push({
              ...unitPayload,
              unit_type: null,
            });
          }
          return unitsData;
        }, []);
        return {
          id: product.id,
          sku: product.sku,
          ...(product.productCategory.id && {
            product_category_id: product.productCategory.id,
          }),
          units,
        };
      });
      const response = await this.rootStore.api.bulkEditProducts({
        products: request,
      });
      if (
        (response as Product[])?.length > 0 &&
        (response as GeneralApiProblem)?.kind !== 'rejected'
      )
        return { status: true };
      return {
        status: false,
        message: (response as GeneralApiProblem)?.message ?? MSG_GLOBAL_ERROR,
      };
    } catch (error) {
      return {
        status: false,
        message: error?.message ?? error ?? MSG_GLOBAL_ERROR,
      };
    }
  }

  async bulkEditResellerProducts(
    products: Product[],
  ): Promise<{ status: boolean; message?: string }> {
    try {
      const request: ResellerEditProductParams[] = products.map(
        (product: Product) => ({
          id: product.id,
          product_category_id: product.productCategory.id,
          coverage_area_id: product.coverageAreas
            .map(({ id }) => id)
            ?.toString(),
        }),
      );
      const response = await this.rootStore.api.bulkEditResellerProducts({
        products: request,
      });
      if (
        (response as Product[])?.length > 0 &&
        (response as GeneralApiProblem)?.kind !== 'rejected'
      )
        return { status: true };
      return {
        status: false,
        message: (response as GeneralApiProblem)?.message ?? MSG_GLOBAL_ERROR,
      };
    } catch (error) {
      return {
        status: false,
        message: error?.message ?? error ?? MSG_GLOBAL_ERROR,
      };
    }
  }

  async getProductCategories(params?: GetProductCategoryParam) {
    const response: SellerProductCategoryResponse[] =
      await this.rootStore.api.getSellerProductCategory(params);

    const categoriesMap = new Map();
    response?.forEach((cat) => {
      categoriesMap.set(
        cat.id,
        mapSellerProductCategoryResponseToProductCategory(cat),
      );
    });
    this.setProductCategoriesMap(categoriesMap);
    return response;
  }

  async createProductCategory(
    data: CreateProductCategoryData,
  ): Promise<ProductCategoryResponse> {
    const body: PostSellerProductCategoryParams = {
      name: data.name,
      description: data.description,
      icon: data.icon,
      active: data.active,
      margin: Number(data.margin),
      is_reseller: data.isReseller,
    };
    const response = await this.rootStore.api.postSellerProductCategory(body);
    return response;
  }

  async updateProductCategory(
    id: string,
    data: UpdateProductCategoryData,
  ): Promise<boolean> {
    const body: PostSellerProductCategoryParams = {
      name: data.name,
      description: data.description,
      icon: data.icon,
      active: data.active,
      margin: Number(data.margin),
    };
    const response = await this.rootStore.api.putSellerProductCategory(
      id,
      body,
    );
    const isSuccess = isRequestSuccess(response);
    return isSuccess;
  }

  async removeProductCategory(
    id: string,
    newProductCategoryId?: string,
  ): Promise<boolean> {
    const response = await this.rootStore.api.deleteSellerProductCategory(
      id,
      newProductCategoryId,
    );
    const isSuccess = isRequestSuccess(response);
    return isSuccess;
  }

  async getResellerProduct(id: string) {
    const response: ProductResponse =
      await this.rootStore.api.getResellerProduct(id);
    let mappedProduct = null;
    if (response) {
      mappedProduct = mapProductResponseToProduct(response);
      mappedProduct = {
        ...mappedProduct,
        productUnits: mappedProduct.productUnits
          .slice()
          .sort(
            (first: ProductUnit, second: ProductUnit) =>
              first.price - second.price,
          ),
      };
      this.setProductDetail(mappedProduct);
    }
    return mappedProduct;
  }

  async getResellerProducts(params: GetProductsResellerParam) {
    const { rows, count } =
      await this.rootStore.api.getResellerProducts(params);
    if ((Array.isArray(rows) && count !== null) || count !== undefined) {
      const products = new Map();
      rows?.forEach((row: ProductResponse) => {
        products.set(row.id, mapProductResponseToProduct(row));
      });
      this.setResellerProducts(products);
      this.setResellerProductsCount(count);
    }
  }

  async updateResellerProduct(id: string, params: Product, areaId: string) {
    const images: PutProductImageParam[] = params.productImages?.map(
      (image) => ({
        url: image.imageUrl,
        featured: image.featured,
      }),
    );

    const units: PutProductUnitParam[] = params.productUnits?.map((unit) => ({
      user_unit_id: unit.userUnitId,
      unit: unit.unit,
      ratio: unit.ratio,
      base_price: unit.basePrice,
      price: unit.price,
      wholesale_price: unit.wholesalePrice,
      min_quantity: unit.minQty,
      discount_price: unit.discountPrice,
      unit_type: unit.unitType,
    }));

    const body: PutProductParams = {
      units,
      images,
      name: params.name,
      available: params.available,
      description: params.description,
      sku: params.sku,
      product_category_id: params.productCategoryId,
      promo_active: params.promoActive,
      coverage_area_id: areaId,
    };

    const response = await this.rootStore.api.updateResellerProduct(id, body);
    const isSuccess = response?.length > 0;
    if (isSuccess) {
      this.setProduct(params);
    }
    return isSuccess;
  }

  async bulkCreateResellerProduct(params: BulkResellerProductsParam) {
    try {
      const response =
        await this.rootStore.api.bulkCreateResellerProduct(params);
      if (response?.length > 0) return true;
      return false;
    } catch (e) {
      throw Error(e?.message || 'Terjadi kesalahan');
    }
  }

  async getSkuProducts() {
    try {
      const response = await this.rootStore.api.getSkuProducts();
      if (response?.rows) return response.rows;
      return false;
    } catch (e) {
      return false;
    }
  }

  async bulkEditProductSkus(productSkusParams: ProductSku[], onUploadProgress) {
    let message = MSG_GLOBAL_ERROR;
    let success = false;
    try {
      const response = await this.rootStore.api.bulkEditProductSkus(
        productSkusParams,
        onUploadProgress,
      );
      if (response?.success === true) {
        success = true;
        message = 'Data produk berhasil diperbarui';
      } else if (response?.message) {
        message = response.message;
      }
      return { success, message };
    } catch (e) {
      if (e.message) message = e.message;
      return { success, message };
    }
  }

  resetProductCategoriesSelection() {
    this.productCategoriesMap.forEach((category, id) => {
      this.productCategoriesMap.set(id, {
        ...category,
        selected: false,
      });
    });
  }
}

export default ProductStore;

import { cast, type Instance, types } from 'mobx-state-tree';
import {
  type AreaDistrictResponse,
  type DeliveryAreaOrderSettingsResponse,
  type DeliveryAreaDetailResponse,
  type DeliveryAreaDistrictResponse,
  type DeliveryAreaResponse,
  type DeliveryAreaResponseV2,
  type DeliveryAreaDetailProductCountResponse,
} from '../../utils';

export const DeliveryAreaDistrictModel = types
  .model('DeliveryAreaDistrictModel')
  .props({
    id: types.maybeNull(types.number),
    stateId: types.number,
    cityId: types.number,
    districtId: types.number,
    stateName: types.string,
    cityName: types.string,
    districtName: types.string,
    isSelected: types.maybeNull(types.boolean),
  });

export type DeliveryAreaDistrict = Instance<typeof DeliveryAreaDistrictModel>;

const DeliveryPointAreaModel = types.model('DeliveryPointAreaModel').props({
  stateId: types.number,
  cityId: types.number,
  cityName: types.maybeNull(types.string),
  districts: types.maybeNull(types.array(DeliveryAreaDistrictModel)),
  districtIds: types.array(types.number),
});

export type DeliveryPointArea = Instance<typeof DeliveryPointAreaModel>;

const DeliveryAreaOrderLimitsModel = types
  .model('DeliveryAreaOrderLimitsModel')
  .props({
    cashMin: types.number,
    tempoMin: types.number,
    tempoMax: types.number,
  });

const DeliveryAreaTempoAdminFeeModel = types
  .model('DeliveryAreaTempoAdminFeeModel')
  .props({
    percentageAmount: types.maybeNull(types.number),
    percentageAmountDisplay: types.maybeNull(types.string),
    dueDays: types.maybeNull(types.number),
    dueDaysError: types.maybeNull(types.string),
  });

const DeliveryAreaAdminFeesModel = types
  .model('DeliveryAreaAdminFeesModel')
  .props({
    cashPercentageAmount: types.number,
    cashPercentageAmountDisplay: types.maybeNull(types.string),
    cashNominalAmount: types.number,
    tempo: types.array(DeliveryAreaTempoAdminFeeModel),
  });

const DeliveryAreaOrderSettingsModel = types
  .model('DeliveryAreaOrderSettingsModel')
  .props({
    orderLimits: DeliveryAreaOrderLimitsModel,
    adminFees: DeliveryAreaAdminFeesModel,
  });

export type DeliveryAreaOrderSettings = Instance<
  typeof DeliveryAreaOrderSettingsModel
>;

const DeliveryAreaDetailProductCountModel = types
  .model('DeliveryAreaDetailProductCountModel')
  .props({
    reseller: types.number,
    trading: types.number,
  });

export type DeliveryAreaDetailProductCount = Instance<
  typeof DeliveryAreaDetailProductCountModel
>;

export const DeliveryAreaDetailModel = types
  .model('DeliveryAreaDetailModel')
  .props({
    id: types.maybeNull(types.string),
    address: types.string,
    addressCityId: types.number,
    addressCityName: types.maybeNull(types.string),
    addressStateId: types.number,
    latitude: types.number,
    longitude: types.number,
    areas: types.maybeNull(types.array(DeliveryPointAreaModel)),
    coverageAreaDistricts: types.maybeNull(
      types.array(DeliveryAreaDistrictModel),
    ),
    name: types.string,
    phone: types.string,
    hasOrderTypeReseller: types.boolean,
    hasOrderTypeTrading: types.boolean,
    resellerOrderSettings: DeliveryAreaOrderSettingsModel,
    tradingOrderSettings: DeliveryAreaOrderSettingsModel,
    productCount: types.maybeNull(DeliveryAreaDetailProductCountModel),
  });

export type DeliveryAreaDetail = Instance<typeof DeliveryAreaDetailModel>;

function mapCoverageAreasResponseToCoverageDetailAreas(
  response: DeliveryAreaDistrictResponse[],
): DeliveryPointArea[] {
  if (!response?.length) return [];
  let areas: DeliveryPointArea[] = [];
  response.forEach((area) => {
    if (!areas.find((existingArea) => existingArea.cityId === area.city_id)) {
      areas.push({
        stateId: area.state_id,
        cityId: area.city_id,
        cityName: null,
        districtIds: cast([area.district_id]),
        districts: cast([
          {
            id: area.district_id,
            stateId: area.state_id,
            cityId: area.city_id,
            districtId: area.district_id,
            stateName: area.state.name,
            cityName: area.city.name,
            districtName: area.district.name,
            isSelected: true,
          },
        ]),
      });
    } else {
      areas = areas.map((existingArea) => {
        if (existingArea.cityId === area.city_id) {
          return {
            ...existingArea,
            districtIds: cast([...existingArea.districtIds, area.district_id]),
            districts: cast([
              ...existingArea.districts,
              {
                id: area.district_id,
                stateId: area.state_id,
                cityId: area.city_id,
                districtId: area.district_id,
                stateName: area.state.name,
                cityName: area.city.name,
                districtName: area.district.name,
                isSelected: true,
              },
            ]),
          };
        }
        return existingArea;
      });
    }
  });
  return areas;
}

function mapDeliveryAreaOrderSettingsResponseToCoverageAreaOrderSettings(
  response: DeliveryAreaOrderSettingsResponse,
): DeliveryAreaOrderSettings {
  if (!response) return null;
  return {
    orderLimits: cast({
      cashMin: response.order_limits?.cash_min ?? null,
      tempoMin: response.order_limits?.tempo_min ?? null,
      tempoMax: response.order_limits?.tempo_max ?? null,
    }),
    adminFees: cast({
      cashPercentageAmount: response.admin_fees?.cash_percentage_amount ?? null,
      cashNominalAmount: response.admin_fees?.cash_nominal_amount ?? null,
      tempo:
        response.admin_fees?.tempo.map((tempo) => ({
          percentageAmount: tempo.percentage_amount,
          dueDays: tempo.due_days,
        })) ?? [],
    }),
  };
}

function mapCoverageAreaDistrictsResponseToCoverageAreaDistricts(
  response: DeliveryAreaDistrictResponse[],
): DeliveryAreaDistrict[] {
  if (!response) return [];
  return response.map((resp) => ({
    id: resp.id,
    stateId: resp.state_id,
    cityId: resp.city_id,
    districtId: resp.district_id,
    stateName: resp.state.name,
    cityName: resp.city.name,
    districtName: resp.district.name,
    isSelected: true,
  }));
}

function mapDeliveryAreaProductCountResponseToDeliveryAreaProductCount(
  response: DeliveryAreaDetailProductCountResponse,
): DeliveryAreaDetailProductCount {
  if (!response) return null;
  return {
    reseller: response.reseller,
    trading: response.trading,
  };
}

export function mapCoverageAreaResponseToDeliveryArea(
  response: DeliveryAreaDetailResponse,
): DeliveryAreaDetail {
  if (!response) return null;
  return {
    id: response.id,
    address: response.address,
    addressCityId:
      response.address_city_id ??
      response.coverage_area_districts?.[0]?.city_id, // Handling old data with no city id
    addressCityName: null,
    addressStateId: response.address_state_id,
    latitude: response.latitude,
    longitude: response.longitude,
    areas: cast(
      mapCoverageAreasResponseToCoverageDetailAreas(
        response.coverage_area_districts,
      ),
    ),
    coverageAreaDistricts: cast(
      mapCoverageAreaDistrictsResponseToCoverageAreaDistricts(
        response.coverage_area_districts,
      ),
    ),
    name: response.name,
    phone: response.phone,
    hasOrderTypeReseller: !!response.has_order_type_reseller,
    hasOrderTypeTrading: !!response.has_order_type_trading,
    resellerOrderSettings:
      mapDeliveryAreaOrderSettingsResponseToCoverageAreaOrderSettings(
        response.order_type_reseller_settings,
      ),
    tradingOrderSettings:
      mapDeliveryAreaOrderSettingsResponseToCoverageAreaOrderSettings(
        response.order_type_trading_settings,
      ),
    productCount: mapDeliveryAreaProductCountResponseToDeliveryAreaProductCount(
      response.product_count,
    ),
  };
}

export const DeliveryAreaModel = types.model('DeliveryAreaModel').props({
  id: types.number,
  sellerId: types.string,
  name: types.string,
  phone: types.string,
  address: types.string,
  status: types.boolean,
  latitude: types.number,
  longitude: types.number,
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  productCount: types.string,
  areaDistricts: types.array(DeliveryAreaDistrictModel),
  addressCityId: types.number,
});

export type DeliveryArea = Instance<typeof DeliveryAreaModel>;

export const OrderLimitSettingsOnDeliveryArea = types
  .model('OrderLimitSettingsOnDeliveryArea')
  .props({
    cashMin: types.number,
    tempoMin: types.number,
    tempoMax: types.number,
  });

export const DeliveryAreaModelV2 = types.model('DeliveryAreaModelV2').props({
  id: types.string,
  name: types.string,
  phone: types.string,
  sellerId: types.string,
  address: types.string,
  latitude: types.number,
  longitude: types.number,
  sellerHubId: types.maybeNull(types.number),
  hasOrderTypeReseller: types.boolean,
  hasOrderTypeTrading: types.boolean,
  status: types.boolean,
  addressCityId: types.number,
});

export type DeliveryAreaV2 = Instance<typeof DeliveryAreaModelV2>;

export function mapDeliveryAreaDistrictsResponseToAreaDistricts(
  areaDistrict: AreaDistrictResponse,
): DeliveryAreaDistrict {
  return {
    id: areaDistrict.id,
    districtId: areaDistrict.district_id,
    districtName: areaDistrict.District.name,
    stateId: areaDistrict.state_id,
    stateName: areaDistrict.State.name,
    cityId: areaDistrict.city_id,
    cityName: areaDistrict.City.name,
    isSelected: false,
  };
}

export function mapDeliveryAreaResponseToDeliveryArea(
  response: DeliveryAreaResponse,
): DeliveryArea {
  return {
    id: response.id,
    sellerId: response.seller_id,
    name: response.name,
    phone: response.phone,
    address: response.address,
    status: response.status,
    latitude: response.latitude,
    longitude: response.longitude,
    deletedAt: response.deleted_at,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    productCount: response.product_count,
    addressCityId: response.address_city_id,
    areaDistricts: response?.CoverageAreaDistricts
      ? cast(
          response.CoverageAreaDistricts.map((areaDistrict) =>
            mapDeliveryAreaDistrictsResponseToAreaDistricts(areaDistrict),
          ),
        )
      : cast([]),
  };
}

export function mapDeliveryAreaResponseToDeliveryAreaV2(
  response: DeliveryAreaResponseV2,
): DeliveryAreaV2 {
  return {
    id: response.id,
    sellerId: response.seller_id,
    name: response.name,
    phone: response.phone,
    address: response.address,
    latitude: response.latitude,
    longitude: response.longitude,
    sellerHubId: response.seller_hub_id,
    status: response.status,
    hasOrderTypeTrading: response.has_order_type_trading,
    hasOrderTypeReseller: response.has_order_type_reseller,
    addressCityId: response.address_city_id,
  };
}

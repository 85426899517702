import { cast, type Instance, types } from 'mobx-state-tree';
import {
  type SellerCategoryResponse,
  type SellerProductCategoryResponse,
  type SellerSummaryResponse,
  type StaffRolePermissionResponse,
} from '../../utils';
import {
  type AdminFeeResponse,
  type DeliveryTypesResponse,
  type SellerProfile,
  type SellerProfileResponse,
  type DeliveryFeeResponse,
} from '../../utils/interfaces';
import {
  mapCityResponseToCity,
  mapDistrictResponseToDistrict,
  mapStateResponseToState,
  mapSubdistrictResponseToSubdistrict,
  mapUserResponseToUser,
} from '../wholesaler-store';
/**
 * STAFF ROLE PERMISSION MODEL
 *
 * - StaffRolePermissionModel
 * - StaffRolePermission
 *
 * Mappers:
 * - mapStaffRolePermisionResponseToStaffRolePermission
 */

export const StaffRolePermissionModel = types
  .model('StaffRolePermissionModel')
  .props({
    roleId: types.number,
    roleName: types.string,
    permissions: types.optional(types.array(types.number), []),
  });

export type StaffRolePermission = Instance<typeof StaffRolePermissionModel>;

export function mapStaffRolePermisionResponseToStaffRolePermission(
  response: StaffRolePermissionResponse,
): StaffRolePermission {
  let permissions = [];
  if (response.Role?.RolePermissions?.length) {
    permissions = response.Role.RolePermissions.map((p) => p.permission_id);
  } else if (response.SellerStaffPermissions) {
    permissions = response.SellerStaffPermissions.map((p) => p.permission_id);
  }
  return {
    roleId: response.role_id,
    roleName: response.Role.name,
    permissions: cast(permissions),
  };
}

export function mapSellerProfileResponseToSellerProfile(
  response: SellerProfileResponse,
): SellerProfile {
  return {
    additionalAddress: response.additional_address ?? '',
    address: response.address ?? '',
    categoryId: response.category_id ?? null,
    City: mapCityResponseToCity(response.City),
    countOrders: response.count_orders ?? 0,
    countProducts: response.count_products ?? 0,
    deliveryArea: response.delivery_area ?? null,
    deliveryType: response.delivery_type ?? null,
    digitalPayments: !!response.digital_payments,
    District: mapDistrictResponseToDistrict(response.District),
    featuredWholesaler: !!response.featured_wholesaler,
    id: response.id,
    latitude: response.latitude ?? 0,
    longitude: response.longitude ?? 0,
    minTotalPurchase: response.min_total_purchase ?? 0,
    profilePicture: response.profile_picture ?? '',
    reseller: !!response.reseller,
    sellerJopan: !!response.seller_jopan,
    State: mapStateResponseToState(response.State),
    Subdistrict: mapSubdistrictResponseToSubdistrict(response.Subdistrict),
    supplier: !!response.supplier,
    User: mapUserResponseToUser(response.User),
    zipcode: response.zipcode ?? '',
    ownerName: response.owner_name ?? '',
    resellerMinOrderCashLimit: response.reseller_min_order_cash_limit ?? null,
    resellerMaxOrderCashLimit: response.reseller_max_order_cash_limit ?? null,
    resellerMinOrderTempoLimit: response.reseller_min_order_tempo_limit ?? null,
    resellerMaxOrderTempoLimit: response.reseller_max_order_tempo_limit ?? null,
    tradingMinOrderCashLimit: response.trading_min_order_cash_limit ?? null,
    tradingMaxOrderCashLimit: response.trading_max_order_cash_limit ?? null,
    tradingMinOrderTempoLimit: response.trading_min_order_tempo_limit ?? null,
    tradingMaxOrderTempoLimit: response.trading_max_order_tempo_limit ?? null,
    minOrderCashLimit: response.min_order_cash_limit ?? null,
    maxOrderCashLimit: response.max_order_cash_limit ?? null,
    minOrderTempoLimit: response.min_order_tempo_limit ?? null,
    maxOrderTempoLimit: response.max_order_tempo_limit ?? null,
    sellerHubId: response.seller_hub_id ?? null,
    bankAccountNo: response.bank_account ?? null,
    bankCode: response.bank_code ?? null,
    bankName: response.bank_name ?? null,
    bankOwnerName: response.bank_owner_name ?? null,
  };
}

export const SellerCategoryModel = types.model('SellerCategoryModel').props({
  key: types.string,
  value: types.string,
});

export type SellerCategory = Instance<typeof SellerCategoryModel>;

export function mapSellerCategoriesResponseToSellerCategories(
  response: SellerCategoryResponse,
): SellerCategory {
  return {
    key: response.key,
    value: response.value,
  };
}

export const SellerProductCategoryModel = types
  .model('SellerProductCategoryModel')
  .props({
    id: types.string,
    name: types.string,
    totalProduct: types.number,
  });

export type SellerProductCategory = Instance<typeof SellerProductCategoryModel>;

export function mapSellerProductCategoriesResponseToSellerProductCategories(
  response: SellerProductCategoryResponse,
): SellerProductCategory {
  return {
    id: response.id,
    name: response.name,
    totalProduct: response.total_product,
  };
}

export enum SellerAdminFeeType {
  tempo = 'tempo',
  cash = 'cash',
}

export const SellerAdminFeeModel = types.model('SellerAdminFeeModel').props({
  id: types.string,
  type: types.enumeration<SellerAdminFeeType>(
    'SellerAdminFeeType',
    Object.values(SellerAdminFeeType),
  ),
  percentageAmount: types.number,
  dueDays: types.number,
  amount: types.number,
  active: types.boolean,
  sellerType: types.maybeNull(
    types.union(types.literal('reseller'), types.literal('trading')),
  ),
});

export type SellerAdminFee = Instance<typeof SellerAdminFeeModel>;

export const SellerSummaryModel = types.model('SellerSummaryModel').props({
  totalCountUnpaidOrders: types.number,
  totalOrders: types.number,
  totalProductSold: types.number,
  totalValueOrders: types.number,
  totalValueOrdersIn: types.number,
  totalValueUnpaidOrders: types.number,
  totalView: types.number,
  totalProfits: types.number,
});

export type SellerSummary = Instance<typeof SellerSummaryModel>;

export function mapSellerSummaryResponseToSellerSummary(
  response: SellerSummaryResponse,
): SellerSummary {
  return {
    totalCountUnpaidOrders: response.total_count_unpaid_orders,
    totalOrders: response.total_orders,
    totalProductSold: response.total_product_sold,
    totalValueOrders: response.total_value_orders,
    totalValueOrdersIn: response.total_value_orders_in,
    totalValueUnpaidOrders: response.total_value_unpaid_orders,
    totalView: response.total_view,
    totalProfits: response.total_profits,
  };
}

export function mapDeliveryTypesResponseToStringArr(
  response: DeliveryTypesResponse,
): string[] {
  return response?.delivery_type_options ?? [];
}

export function mapAdminFeeResponseToAdminFee(
  response: AdminFeeResponse,
): SellerAdminFee {
  return {
    id: response.id,
    type:
      response.type === SellerAdminFeeType.cash
        ? SellerAdminFeeType.cash
        : SellerAdminFeeType.tempo,
    percentageAmount: response.percentage_amount,
    dueDays: response.due_days,
    amount: response.amount,
    active: response.active,
    sellerType: response.seller_type ?? null,
  };
}

export const DeliveryFeeModel = types.model('DeliveryFeeModel').props({
  amount: types.number,
  id: types.string,
  sellerId: types.string,
  active: types.boolean,
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  type: types.enumeration(['cash', 'tempo']),
  sellerType: types.maybeNull(
    types.union(types.literal('reseller'), types.literal('trading')),
  ),
});

export type DeliveryFee = Instance<typeof DeliveryFeeModel>;

export function mapDeliveryFeeResponseToDeliveryFee(
  response: DeliveryFeeResponse,
): DeliveryFee {
  return {
    amount: response.amount,
    id: response.id,
    sellerId: response.seller_id,
    active: response.active,
    deletedAt: response.deleted_at,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    type: response.type,
    sellerType: response.seller_type,
  };
}

import { type Instance, types } from 'mobx-state-tree';
import { type ChangeLogResponse } from '../../utils';

export const ChangeLogModel = types.model('ChangeLogModel').props({
  id: types.number,
  actionBy: types.string,
  actionType: types.string,
  createdAt: types.string,
  dashboardType: types.string,
  featureName: types.string,
  fieldName: types.string,
  newValue: types.string,
  oldValue: types.string,
  referenceId: types.string,
  updatedAt: types.string,
});

export type ChangeLog = Instance<typeof ChangeLogModel>;

export function mapChangeLogsResponseToChangeLogs(
  response: ChangeLogResponse[],
): ChangeLog[] {
  return response.map((changeLogResponse: ChangeLogResponse) => ({
    id: changeLogResponse.id,
    actionBy: changeLogResponse.action_by,
    actionType: changeLogResponse.action_type,
    createdAt: changeLogResponse.created_at,
    dashboardType: changeLogResponse.dashboard_type,
    featureName: changeLogResponse.feature_name,
    fieldName: changeLogResponse.field_name,
    newValue: changeLogResponse.new_value,
    oldValue: changeLogResponse.old_value,
    referenceId: changeLogResponse.reference_id,
    updatedAt: changeLogResponse.updated_at,
  }));
}

import { makeAutoObservable } from 'mobx';
import {
  type DeliverymanResult,
  type GetStaffParams,
  type PostStaffParams,
  type PutStaffParams,
  type PermissionResponse,
  type PermissionsResult,
  type StaffRole,
  type CreateStaffData,
  type StaffResponse,
  type StaffResult,
  type UpdateStaffData,
  type SalesResult,
  type GetDeliverymansParam,
  AREA_SALES_MANAGER_ID,
} from '../../utils';
import { isRequestSuccess } from '../../utils/api';
import type RootStore from '../root-store';
import {
  type Staff,
  mapStaffResponseToStaff,
  mapStaffPermissionResponseToStaffPermission,
  type Deliveryman,
  type Sales,
  type RolePermission,
  mapRolePermissionsRowsToRolePermissions,
  type PermissionItem,
} from './staff-store.model';

class StaffStore {
  rootStore: RootStore;
  staffs = new Map<string, Staff>();
  staffCount = 0;
  selectedStaff: Staff = null;
  roles: StaffRole[] = [];
  staffPermissions: PermissionItem[] = [];
  deliverymans = new Map<string, Deliveryman>();
  salesList = new Map<string, Sales>();
  rolePermissions: RolePermission[] = [];

  constructor(rootStore?: RootStore) {
    if (rootStore) {
      this.rootStore = rootStore;
      makeAutoObservable(this, { rootStore: false });
    } else {
      makeAutoObservable(this);
    }
  }

  get allStaffs(): Staff[] {
    if (!this.staffs) return [];
    return Array.from(this.staffs.values());
  }

  get allDeliveryMans(): Deliveryman[] {
    if (!this.deliverymans) return [];
    return Array.from(this.deliverymans.values());
  }

  get allSales(): Deliveryman[] {
    if (!this.salesList) return [];
    return Array.from(this.salesList.values());
  }

  setStaffs(staffs: Map<string, Staff>) {
    this.staffs = staffs;
  }

  setStaffCount(count: number) {
    this.staffCount = count;
  }

  setSelectedStaff(staff: Staff) {
    this.selectedStaff = staff;
  }

  deleteStaff(id: string) {
    this.staffs.delete(id);
  }

  setRoles(roles: StaffRole[]) {
    this.roles = roles;
  }

  setStaffPermissions(_permissions: PermissionItem[]) {
    this.staffPermissions = _permissions;
  }

  setDeliverymans(deliveryMans: Map<string, Deliveryman>) {
    this.deliverymans = deliveryMans;
  }

  setSalesList(salesList: Map<string, Sales>) {
    this.salesList = salesList;
  }

  async getStaffs(params?: GetStaffParams): Promise<StaffResult> {
    const response = await this.rootStore.api.getSellerStaff(params);
    if (
      (Array.isArray(response?.rows) && response?.count !== null) ||
      response?.count !== undefined
    ) {
      const staffs = new Map();
      response.rows?.forEach((row: StaffResponse) => {
        staffs.set(row.id, mapStaffResponseToStaff(row));
      });
      this.setStaffs(staffs);
      this.setStaffCount(response.count);
    }
    return response;
  }

  async getRoles(): Promise<StaffRole[]> {
    const response = await this.rootStore.api.getSellerRole();
    if (response?.length) {
      const filteredRoles = response.filter(({ id }) => id !== 1); // exclude superadmin role from api
      this.setRoles(filteredRoles);
    }
    return response;
  }

  async getPermissions(): Promise<PermissionsResult> {
    const response = await this.rootStore.api.getSellerPermission();
    if (response?.count > 0) {
      const staffPermissions = [];
      response.rows?.forEach((row: PermissionResponse) => {
        staffPermissions.push(mapStaffPermissionResponseToStaffPermission(row));
      });
      this.setStaffPermissions(staffPermissions);
    }
    return response;
  }

  async getDeliverymans(
    params: GetDeliverymansParam,
  ): Promise<DeliverymanResult[] | null> {
    const response: DeliverymanResult[] | null =
      await this.rootStore.api.getSellerDeliveryman(params);
    if (response?.length) {
      const deliverymans = new Map();
      response.forEach((row: DeliverymanResult) => {
        deliverymans.set(row.id, row);
      });
      this.setDeliverymans(deliverymans);
    }
    return response;
  }

  async getSalesList(
    coverageAreaIds?: string,
  ): Promise<{ status: boolean; message?: string; data?: SalesResult[] }> {
    const param = {
      ...(coverageAreaIds && { coverage_area_ids: coverageAreaIds }),
    };
    const { status, data, message } =
      await this.rootStore.api.getSellerSales(param);
    if (status && data?.length) {
      const sales = new Map();
      data.forEach((row: SalesResult) => {
        sales.set(row.id, row);
      });
      this.setSalesList(sales);
      return { status: true, data };
    } else {
      this.setSalesList(new Map()); // reset sales list
      return { status: false, message };
    }
  }

  async createStaff(
    data: CreateStaffData,
  ): Promise<{ success: boolean; message?: string }> {
    const body: PostStaffParams = {
      username: data.username,
      password: data.password,
      confirm_password: data.password,
      role_id: data.roleId,
      status: true,
      ...(data.covereageAreaIds?.length > 0 && {
        coverage_area_ids: data.covereageAreaIds.map(({ coverageAreaId }) => ({
          coverage_area_id: coverageAreaId,
        })),
      }),
    };
    const response = await this.rootStore.api.postSellerStaff(body);
    if (response?.message) return { success: false, message: response.message };
    if (response?.id) return { success: true };
  }

  async updateStaff(
    id: string,
    data: UpdateStaffData,
  ): Promise<{ success: boolean; message?: string }> {
    const body: PutStaffParams = {
      username: data.username,
      role_id: data.roleId,
      status: data.status,
      ...(data.covereageAreaIds?.length > 0 && {
        coverage_area_ids: data.covereageAreaIds.map(({ coverageAreaId }) => ({
          coverage_area_id: coverageAreaId,
        })),
      }),
    };
    const response = await this.rootStore.api.putSellerStaff(id, body);
    if (response?.message) return { success: false, message: response.message };
    if (isRequestSuccess(response)) return { success: true };
  }

  async removeStaff(id: string): Promise<boolean> {
    const response = await this.rootStore.api.deleteSellerStaff(id);
    const isSuccess = isRequestSuccess(response);
    if (isSuccess) {
      this.deleteStaff(id);
    }
    return isSuccess;
  }

  async resetPassword(password: string): Promise<boolean> {
    const response = await this.rootStore.api.resetPassword(
      password,
      this.selectedStaff.id,
    );
    return isRequestSuccess(response);
  }

  get isAreaSalesManager() {
    return !!this.staffPermissions.find(
      (permission) => permission.id === AREA_SALES_MANAGER_ID,
    );
  }

  setRolePermissions(rolePermissions: RolePermission[]) {
    this.rolePermissions = rolePermissions;
  }

  async getRolePermissions(): Promise<RolePermission[]> {
    const response = await this.rootStore.api.getRolePermissions();
    if (response?.count > 0) {
      const rolePermissions: RolePermission[] = [];
      response.rows?.forEach((row) => {
        rolePermissions.push(mapRolePermissionsRowsToRolePermissions(row));
      });
      this.setRolePermissions(rolePermissions);
      return rolePermissions;
    }
    return [];
  }

  async updateRolePermission(id: string, permissionIds: number[]) {
    return await this.rootStore.api.updateRolePermission(id, permissionIds);
  }

  async createRole(payload: { name: string; description?: string }) {
    const { data } = await this.rootStore.api.createRole(payload);
    if (data) {
      this.setRoles([...this.roles, data]);
      return data.id;
    }
    return false;
  }
}

export default StaffStore;

import { cast, type Instance, types } from 'mobx-state-tree';
import {
  PERMISSION_ACCESS_PLATFORM,
  PERMISSION_DESCRIPTION,
  type PermissionResponse,
  type StaffResponse,
  type StaffCoverageAreasResponse,
  type RolePermissionRow,
  type StaffRolePermissionObject,
} from '../../utils';

/**
 * STAFF PERMISSION MODEL
 *
 * - StaffPermissionModel
 * - StaffPermission
 *
 * Mappers:
 * - mapStaffPermissionResponseToStaffPermission
 */

export const StaffPermissionModel = types.model('StaffPermission').props({
  id: types.number,
  description: types.string,
  accessPlatform: types.string,
  selected: types.boolean,
});

export type StaffPermission = Instance<typeof StaffPermissionModel>;

export function mapStaffPermissionResponseToStaffPermission(
  response: PermissionResponse,
): StaffPermission {
  return {
    id: response.id,
    description: response.description,
    accessPlatform: response.access_platform,
    selected: false,
  };
}

/**
 * ROLE MODEL
 *
 * - RoleModel
 * - Role
 *
 */

export const RoleModel = types.model('Role').props({
  id: types.number,
  name: types.string,
  rolePermissions: types.array(StaffPermissionModel),
});

export type Role = Instance<typeof RoleModel>;

/**
 * STAFF COVERAGE AREA MODEL
 *
 * - StaffCoverageAreaModel
 * - StaffCoverageArea
 *
 * Mappers:
 * - mapStaffCoverageAreasResponseToStaffCoverageArea
 */

export const StaffCoverageAreaModel = types.model('StaffCoverageArea').props({
  id: types.string,
  name: types.string,
  selected: types.boolean,
});

export type StaffCoverageArea = Instance<typeof StaffCoverageAreaModel>;

export function mapStaffCoverageAreasResponseToStaffCoverageArea(
  coverageAreas: StaffCoverageAreasResponse[],
): StaffCoverageArea[] {
  return coverageAreas.map((response) => ({
    id: response.coverage_area_id,
    name: response.CoverageArea?.name ?? '',
    selected: false,
  }));
}

/**
 * STAFF MODEL
 *
 * - StaffModel
 * - Staff
 *
 * Mappers:
 * - mapStaffResponseToStaff
 * - mapStaffPermissionResponseToStaffPermissions
 */

export const StaffModel = types.model('Staff').props({
  id: types.identifier,
  username: types.string,
  password: types.string,
  status: types.boolean,
  role: types.maybe(RoleModel),
  coverageAreas: types.array(StaffCoverageAreaModel),
  createdAt: types.string,
  updatedAt: types.string,
});

export type Staff = Instance<typeof StaffModel>;

export function mapStaffResponseToStaff(response: StaffResponse): Staff {
  return {
    id: response.id,
    username: response.username,
    password: response.password,
    status: response.status,
    role: {
      id: response.Role.id,
      name: response.Role.name,
      rolePermissions: cast(
        response.Role.RolePermissions?.length
          ? mapRolePermissionObjectToStaffPermissions(
              response.Role.RolePermissions,
            )
          : [],
      ),
    },
    coverageAreas: cast(
      response.StaffCoverageAreas
        ? mapStaffCoverageAreasResponseToStaffCoverageArea(
            response.StaffCoverageAreas.filter((obj) => !!obj?.CoverageArea),
          )
        : [],
    ),
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  };
}

export function mapRolePermissionObjectToStaffPermissions(
  response: StaffRolePermissionObject[],
): StaffPermission[] {
  return response?.map((result) => ({
    id: result.permission_id,
    description: PERMISSION_DESCRIPTION[result.permission_id],
    accessPlatform: PERMISSION_ACCESS_PLATFORM[result.permission_id],
    selected: false,
  }));
}

/**
 * DELIVERYMAN MODEL
 *
 * - DeliverymanModel
 * - Deliveryman
 */

export const DeliverymanModel = types.model('Deliveryman').props({
  id: types.identifier,
  username: types.string,
});

export type Deliveryman = Instance<typeof DeliverymanModel>;

/**
 * SALES MODEL
 *
 * - SalesModel
 * - Sales
 */

export const SalesModel = types.model('Sales').props({
  id: types.identifier,
  username: types.string,
});

export type Sales = Instance<typeof SalesModel>;

/**
 * CASHIER MODEL
 *
 * - CashierModel
 * - Cashier
 */

export const CashierModel = types.model('Cashier').props({
  id: types.identifier,
  username: types.string,
});

export type Cashier = Instance<typeof CashierModel>;

export const PermissionItemModel = types.model('PermissionItem').props({
  featureName: types.maybeNull(types.string),
  action: types.maybeNull(types.string),
  description: types.string,
  accessPlatform: types.string,
  id: types.number,
  selected: types.boolean,
});

export type PermissionItem = Instance<typeof PermissionItemModel>;

export const RolePermissionModel = types.model('RolePermission').props({
  id: types.number,
  name: types.string,
  RolePermissions: types.array(
    types.model({
      permissionId: types.number,
      sellerId: types.string,
      Permission: PermissionItemModel,
    }),
  ),
  countAccess: types.number,
  wordingName: types.string,
});

export type RolePermission = Instance<typeof RolePermissionModel>;

export function mapRolePermissionsRowsToRolePermissions(
  response: RolePermissionRow,
): RolePermission {
  return {
    id: response.id,
    name: response.name,
    RolePermissions: cast(
      response.RolePermissions.length
        ? response.RolePermissions.map((item) => ({
            permissionId: item.permission_id,
            sellerId: item.seller_id,
            Permission: {
              featureName: item.Permission.feature_name,
              action: item.Permission.action,
              description: item.Permission.description,
              accessPlatform: item.Permission.access_platform,
              id: item.permission_id,
              selected: false,
            },
          }))
        : [],
    ),
    countAccess: response.count_access,
    wordingName: response.wording_name,
  };
}

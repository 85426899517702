import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import module from '../package.json';

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  appVersion: !module ? '' : module.version,
  enabledReleaseStages: ['production'],
});

export default Bugsnag;

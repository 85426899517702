import React, { type SVGProps } from 'react';

export const IconHierarchy: React.FunctionComponent<SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 18.6133C16 20.2701 17.3431 21.6133 19 21.6133C20.6569 21.6133 22 20.2701 22 18.6133C22 16.9564 20.6569 15.6133 19 15.6133C17.3431 15.6133 16 16.9564 16 18.6133Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 8.61328C7 11.3747 9.23858 13.6133 12 13.6133C14.7614 13.6133 17 11.3747 17 8.61328C17 5.85186 14.7614 3.61328 12 3.61328C9.23858 3.61328 7 5.85186 7 8.61328Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 18.6133C2 20.2701 3.34315 21.6133 5 21.6133C6.65685 21.6133 8 20.2701 8 18.6133C8 16.9564 6.65685 15.6133 5 15.6133C3.34315 15.6133 2 16.9564 2 18.6133Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.6133L7 15.6133"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 15.6133L15 12.6133"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import styles from './index.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  withoutInitialFocus?: boolean;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl';
  className?: string; // modal body class
  style?: React.CSSProperties;
  title?: React.ReactNode;
  titleClassName?: string;
  children: React.ReactNode;
  dialogClassName?: string;
  withCloseButton?: boolean;
}

export const BaseModalDialog = ({
  isOpen,
  onClose,
  shouldCloseOnOverlayClick = true,
  withoutInitialFocus = false,
  size = 'md',
  className,
  style,
  title,
  titleClassName,
  children,
  dialogClassName,
  withCloseButton = false,
}: Props) => {
  const dialogRef = useRef();

  const dialogSizeClass = {
    xs: 'max-w-xs',
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    '6xl': 'max-w-6xl',
    '7xl': 'max-w-7xl',
  };

  const renderOverlay = () => (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog.Overlay className={styles.dialogOverlay} />
    </Transition.Child>
  );

  const renderModalBody = () => (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div
        className={classNames([
          styles.dialogBody,
          dialogSizeClass[size],
          className,
        ])}
        style={style}
      >
        {title && (
          <Dialog.Title
            as="h3"
            className={classNames([styles.dialogTitle, titleClassName])}
          >
            {title}
            {withCloseButton && (
              <FiX
                className="absolute right-0 cursor-pointer hover:text-primary"
                onClick={onClose}
              />
            )}
          </Dialog.Title>
        )}
        {children}
      </div>
    </Transition.Child>
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(
          'fixed inset-0 z-10 overflow-y-auto',
          dialogClassName,
        )}
        onClose={!shouldCloseOnOverlayClick ? () => {} : onClose}
        initialFocus={withoutInitialFocus && dialogRef}
      >
        <div ref={dialogRef} className="min-h-screen px-4 text-center">
          {renderOverlay()}

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          {renderModalBody()}
        </div>
      </Dialog>
    </Transition>
  );
};

import { cast, type Instance, types } from 'mobx-state-tree';
import {
  type BuyerDetailResponse,
  type BuyersListResult,
  type KeyValueResponse,
  type KycResponse,
  type StoreHistoriesResponse,
  type TempoLimitHistoryResponse,
} from '../../utils/interfaces';

export const BuyerModel = types.model('Buyer').props({
  address: types.string,
  additionalAddress: types.string,
  cashForTempo: types.number,
  createdAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  finishedOrderCount: types.number,
  finishedOrderAmount: types.number,
  id: types.identifier,
  identityStatus: types.maybeNull(types.string),
  kycHistory: types.maybeNull(types.string),
  name: types.string,
  phone: types.string,
  storeStatus: types.maybeNull(types.string),
  type: types.string,
  unpaidAmount: types.number,
  verificationStage: types.maybeNull(types.string),
  salesName: types.string,
  salesAcquisition: types.string,
  orderAddress: types.maybeNull(types.string),
  orderLatitude: types.maybeNull(types.number),
  orderLongitude: types.maybeNull(types.number),
  orderAdditionalAddress: types.string,
  trading: types.boolean,
});

export type Buyer = Instance<typeof BuyerModel>;

export function mapBuyersResponseToBuyers(
  buyersResponse: BuyersListResult[],
): Buyer[] {
  const CASH_REQUIRED_TO_GET_TEMPO = 3;
  const getSalesAcquisition = (buyer: BuyersListResult) => {
    if (buyer.organic) {
      if (buyer.SellerStaff?.username) {
        return buyer.SellerStaff?.username;
      } else {
        return 'Pesanan Mandiri';
      }
    } else {
      if (buyer.SellerStaff?.username) {
        return buyer.SellerStaff?.username;
      } else {
        return '-';
      }
    }
  };
  return buyersResponse.map((buyer) => ({
    address: buyer.address ?? '',
    additionalAddress: buyer.additional_address ?? '',
    cashForTempo: CASH_REQUIRED_TO_GET_TEMPO - (buyer.cash_for_tempo ?? 0),
    createdAt: buyer.created_at ?? '',
    deletedAt: buyer.deleted_at ?? '',
    finishedOrderCount: buyer.finished_order_count ?? 0,
    finishedOrderAmount: buyer.finished_order_amount ?? 0,
    id: buyer.id,
    identityStatus: buyer.identity_status,
    kycHistory: buyer.kyc_history ?? '-',
    name: buyer.name ?? '',
    phone: buyer.phone ?? '',
    storeStatus: buyer.store_status,
    type: buyer.type ?? null,
    verificationStage: buyer.verification_stage ?? '-',
    unpaidAmount: 0,
    salesName: buyer.sales_latest_order?.username ?? '-',
    salesAcquisition: getSalesAcquisition(buyer),
    orderAddress: buyer.order_address ?? '-',
    orderLatitude: buyer.order_latitude,
    orderLongitude: buyer.order_longitude,
    orderAdditionalAddress: buyer.order_additional_address ?? '',
    trading: !!buyer.trading,
  }));
}

export const KeyValueModel = types.model('KeyValue').props({
  key: types.string,
  value: types.string,
});

export type KeyValue = Instance<typeof KeyValueModel>;

export const KycModel = types.model('Kyc').props({
  address: types.maybeNull(types.string),
  bloodType: types.maybeNull(types.string),
  dateOfBirth: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  id: types.identifier,
  identityNumber: types.maybeNull(types.string),
  maritalStatus: types.maybeNull(types.string),
  placeOfBirth: types.maybeNull(types.string),
  religion: types.maybeNull(types.string),
  salt: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  storeImage: types.maybeNull(types.string),
  storeStatus: types.string,
  userId: types.string,
  verificationStage: types.string,
});

export type Kyc = Instance<typeof KycModel>;

export const StoreHistoryModel = types.model('StoreHistory').props({
  id: types.identifier,
  actionBy: types.maybeNull(types.string),
  kycType: types.maybeNull(types.string),
  kycStoreStatus: types.maybeNull(types.string),
  reasonBlocked: types.maybeNull(types.string),
  userId: types.string,
  createdAt: types.string,
});

export type StoreHistory = Instance<typeof StoreHistoryModel>;

const TempoLimitHistoryModel = types.model('TempoLimitHistory').props({
  id: types.identifierNumber,
  amount: types.maybeNull(types.number),
  tempoStatus: types.maybeNull(types.boolean),
  createdAt: types.string,
  updatedBy: types.maybeNull(types.string),
});

export type TempoLimitHistory = Instance<typeof TempoLimitHistoryModel>;

export const BuyerDetailModel = types.model('BuyerDetail').props({
  id: types.identifier,
  customerName: types.string,
  storeName: types.string,
  phone: types.string,
  address: types.string,
  additionalAddress: types.string,
  businessType: types.maybeNull(KeyValueModel),
  blockedAt: types.maybeNull(types.string),
  Kyc: types.maybeNull(KycModel),
  pictureUrl: types.maybeNull(types.string),
  StoreHistories: types.maybeNull(types.array(StoreHistoryModel)),
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  orderAddress: types.maybeNull(types.string),
  tempoLimitHistory: types.maybeNull(types.array(TempoLimitHistoryModel)),
  tempoActive: types.boolean,
  tempoLimitType: types.maybeNull(types.string),
  tempoMaxOrderLimit: types.maybeNull(types.number),
  totalOrderCount: types.optional(types.number, 0),
  totalOrderValue: types.optional(types.number, 0),
  orderLatitude: types.maybeNull(types.number),
  orderLongitude: types.maybeNull(types.number),
  orderAdditionalAddress: types.string,
  trading: types.boolean,
});

export type BuyerDetail = Instance<typeof BuyerDetailModel>;

function mapBusinessTypeResponseToKeyValue(
  businessTypeResponse: KeyValueResponse,
): KeyValue {
  if (!businessTypeResponse) return null;
  return {
    key: businessTypeResponse.key ?? '',
    value: businessTypeResponse.value ?? '',
  };
}

function mapKycResponseToKyc(kycResponse: KycResponse): Kyc {
  if (!kycResponse) return null;
  return {
    address: kycResponse.address,
    bloodType: kycResponse.blood_type,
    dateOfBirth: kycResponse.date_of_birth,
    fullName: kycResponse.full_name ?? '-',
    id: kycResponse.id,
    identityNumber: kycResponse.identity_number,
    maritalStatus: kycResponse.marital_status,
    placeOfBirth: kycResponse.place_of_birth,
    religion: kycResponse.religion,
    salt: kycResponse.salt,
    status: kycResponse.status,
    storeImage: kycResponse.store_image,
    storeStatus: kycResponse.store_status,
    userId: kycResponse.user_id,
    verificationStage: kycResponse.verification_stage,
  };
}

function mapStoreHistoriesResponseToStoreHistories(
  storeHistoriesResponse: StoreHistoriesResponse[],
): StoreHistory[] {
  if (!storeHistoriesResponse) return null;
  const storeHistories = storeHistoriesResponse?.map(
    (history: StoreHistoriesResponse) => {
      let actionBy = history.action_by;
      if (history.ActionByStaff?.username) {
        actionBy = history.ActionByStaff?.username;
      } else if (history.ActionBy?.name && actionBy !== 'system') {
        actionBy = 'Superadmin';
      } else if (actionBy === 'system') {
        actionBy = 'System';
      }
      return {
        actionBy,
        kycType: history.kyc_type,
        kycStoreStatus: history.kyc_store_status,
        id: history.id,
        reasonBlocked: history.reason_blocked,
        userId: history.user_id,
        createdAt: history.created_at,
      };
    },
  );
  return storeHistories;
}

function mapTempoLimitHistoryResponseToTempoLimitHistory(
  tempoLimitHistoriesResponse: TempoLimitHistoryResponse[],
): TempoLimitHistory[] {
  if (!tempoLimitHistoriesResponse || tempoLimitHistoriesResponse.length === 0)
    return [];
  const mappedTempoLimitHistories = tempoLimitHistoriesResponse.map(
    (history: TempoLimitHistoryResponse) => {
      return {
        id: history.id,
        amount: history.amount ?? 0,
        tempoStatus: history.tempo_status,
        createdAt: history.created_at ?? '',
        updatedBy: history.updated_by ?? '',
      };
    },
  );
  return mappedTempoLimitHistories;
}

export function mapBuyerDetailResponseToBuyerDetail(
  buyerDetailResponse: BuyerDetailResponse,
): BuyerDetail {
  return {
    id: buyerDetailResponse.id,
    customerName: buyerDetailResponse.customer_name ?? '',
    storeName: buyerDetailResponse.name ?? '',
    phone: buyerDetailResponse.phone,
    address: buyerDetailResponse.address,
    additionalAddress: buyerDetailResponse.additional_address,
    businessType: mapBusinessTypeResponseToKeyValue(
      buyerDetailResponse.business_type,
    ),
    blockedAt: buyerDetailResponse.blocked_at ?? null,
    Kyc: mapKycResponseToKyc(buyerDetailResponse.Kyc),
    pictureUrl: buyerDetailResponse.picture_url ?? null,
    StoreHistories: cast(
      mapStoreHistoriesResponseToStoreHistories(
        buyerDetailResponse.StoreHistory,
      ),
    ),
    latitude: buyerDetailResponse.latitude,
    longitude: buyerDetailResponse.longitude,
    orderAddress: buyerDetailResponse.order_address,
    tempoActive: !!buyerDetailResponse.tempo_active,
    tempoLimitType: buyerDetailResponse.tempo_limit_type,
    tempoMaxOrderLimit: buyerDetailResponse.tempo_max_order_limit ?? null,
    totalOrderCount: buyerDetailResponse.total_order_count ?? 0,
    totalOrderValue: buyerDetailResponse.total_order_value ?? 0,
    tempoLimitHistory: cast(
      mapTempoLimitHistoryResponseToTempoLimitHistory(
        buyerDetailResponse.TempoLimitHistory,
      ),
    ),
    orderLatitude: buyerDetailResponse.order_latitude,
    orderLongitude: buyerDetailResponse.order_longitude,
    orderAdditionalAddress: buyerDetailResponse.order_additional_address,
    trading: !!buyerDetailResponse.trading,
  };
}

import React, {
  Fragment,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { FiMinus, FiPlus, FiSearch, FiPrinter, FiX } from 'react-icons/fi';
import { Button } from '../../button';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import styles from './index.module.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useReactToPrint } from 'react-to-print';
import { ImageViewerContext } from '.';

const ZOOM_CONFIG = {
  minScale: 0.5,
  initialScale: 1,
  maxScale: 2,
};

export const ImageViewer: React.FunctionComponent<any> = () => {
  const {
    content: { url, zoomable, printable },
    clearImageViewerUrl,
  } = useContext(ImageViewerContext);

  const dialogRef = useRef();
  const imageRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => imageRef.current,
  });

  const handleClearImageViewerUrl = useCallback(() => {
    clearImageViewerUrl();
  }, [clearImageViewerUrl]);

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (!url) return;

      // Escape
      if (e.key === 'Escape' || e.code === 'Escape') {
        e.stopPropagation();
        handleClearImageViewerUrl();
      }
    },
    [handleClearImageViewerUrl, url],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);

  useEffect(() => {
    const body = document.body;
    if (url?.length) {
      document.documentElement.classList.add('overflow-hidden');
      body.classList.add('overflow-hidden');
    }

    return () => {
      document.documentElement.classList.remove('overflow-hidden');
      body.classList.remove('overflow-hidden');
    };
  }, [url]);

  if (!url) {
    return null;
  }

  const renderOverlay = () => (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog.Overlay className={styles.overlay} />
    </Transition.Child>
  );

  const renderModalBody = () => (
    <TransformWrapper
      minScale={ZOOM_CONFIG.minScale}
      initialScale={ZOOM_CONFIG.initialScale}
      maxScale={ZOOM_CONFIG.maxScale}
      wheel={{ step: 0.05 }}
      doubleClick={{ disabled: true }}
      centerOnInit={true}
      centerZoomedOut={true}
    >
      {({ zoomIn, zoomOut, resetTransform }) => (
        <Fragment>
          <TransformComponent
            wrapperClass={classNames(
              styles.imageViewer,
              'overflow-visible m-auto',
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <img className={styles.contentImage} src={url} alt="img" />
            </Transition.Child>
          </TransformComponent>
          {zoomable && (
            <div
              className={classNames(
                styles.zoomActions,
                'rounded overflow-hidden bg-white',
              )}
            >
              <Button
                type="tertiary"
                className="p-3 rounded-none text-gray hover:text-primary hover:opacity-100"
                onClick={() => {
                  zoomOut();
                }}
                title="Zoom Out"
              >
                <FiMinus size={24} />
              </Button>
              <Button
                type="tertiary"
                className="p-3 rounded-none text-gray hover:text-primary"
                onClick={() => {
                  resetTransform();
                }}
                title="Reset"
              >
                <FiSearch size={24} />
              </Button>
              <Button
                type="tertiary"
                className="p-3 rounded-none text-gray hover:text-primary"
                onClick={() => {
                  zoomIn();
                }}
                title="Zoom In"
              >
                <FiPlus size={24} />
              </Button>
            </div>
          )}
        </Fragment>
      )}
    </TransformWrapper>
  );

  return (
    <Transition appear show={!!url} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleClearImageViewerUrl}
        initialFocus={dialogRef}
      >
        <div ref={dialogRef} className="min-h-screen text-center">
          {renderOverlay()}

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          {printable && (
            <Button
              type="tertiary"
              className="bg-white p-3 fixed top-4 left-4 md:top-16 md:left-16 text-gray hover:text-primary"
              onClick={handlePrint}
              title="Print"
            >
              <FiPrinter size={24} />
            </Button>
          )}
          <Button
            type="tertiary"
            className="bg-white p-3 fixed top-4 right-4 md:top-16 md:right-16 text-gray hover:text-primary"
            onClick={handleClearImageViewerUrl}
            title="Close"
          >
            <FiX size={24} />
          </Button>
          {renderModalBody()}
          {/* Different img DOM for print support due to react-zoom-pan-pinch components styling conflicting */}
          <img
            className="hidden print:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            src={url}
            alt="img"
            ref={imageRef}
          />
        </div>
      </Dialog>
    </Transition>
  );
};

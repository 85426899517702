export const palette = {
  black: '#595959',
  darkestBlack: '#000',
  darkerBlack: '#434343',
  white: '#ffffff',
  offWhite: '#e6e6e6',
  orange: '#FBA928',
  orangeDarker: '#EB9918',
  darkerGrey: '#707070',
  grey: '#9e9e9e',
  lightGrey: '#BDBDBD',
  lighterGrey: '#E0E0E0',
  backgroundGrey: '#F5F5F5',
  borderGrey: '#ddd',
  backgroundLighterGrey: '#FCFCFC',
  backgroundLightGrey: '#EFEFEF',
  green: '#18BA62',
  onboardGreen: '#6FCF97',
  whatsappGreen: '#25D366',
  lightGreen: '#EBFDEF',
  backgroundGreen: '#F1FAF5',
  borderGreen: 'rgba(24, 186, 98, 0.25)',
  red: '#F23D3D',
  darkRed: '#D12935',
  lightRed: '#F64D52',
  lighterRed: '#FF6961',
  backgroundRed: '#FEECEC',
  blue: '#009ABF',
  brightBlue: '#4386F1',
  lightBlue: '#DCEFFE',
  backgroundBlue: '#F3FAFF',
  backgroundLightBlue: 'rgba(220, 239, 255, 0.1)',
  purple: '#6F65C4',
  yellowBackground: '#FFFDEA',
  yellowBright: '#F2C94C',
  yellowBorder: '#EBE4A9',
  yellowProgress: '#F6CF78',
  transparent: 'rgba(0, 0, 0, 0)',
  semiTransparent: 'rgba(0,0,0,0.3)',
  almostTransparent: 'rgba(0,0,0,0.05)',
  azure: '#0080FF',
};

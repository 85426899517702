import { createContext } from 'react';
import { type ImageViewerProps } from '.';

export const initialContent: ImageViewerProps = {
  zoomable: true,
  printable: true,
  url: '',
};

export const ImageViewerContext = createContext({
  content: initialContent,
  clearImageViewerUrl: () => null,
  initImageViewerUrl: (args: any) => null,
});

import { Api } from '../services/api';
import AccountStore from './account-store/account-store';
import AreaStore from './area-store/area-store';
import BuyerStore from './buyer-store/buyer-store';
import ChangeLogStore from './changelog-store/changelog-store';
import OrderStore from './order-store/order-store';
import ProductStore from './product-store/product-store';
import StaffStore from './staff-store/staff-store';
import WholesalerStore from './wholesaler-store/wholesaler-store';

class RootStore {
  api: Api;
  accountStore: AccountStore;
  areaStore: AreaStore;
  buyerStore: BuyerStore;
  orderStore: OrderStore;
  productStore: ProductStore;
  staffStore: StaffStore;
  wholesalerStore: WholesalerStore;
  changeLogStore: ChangeLogStore;

  constructor() {
    this.api = new Api();
    this.api.setup();
    this.accountStore = new AccountStore(this);
    this.areaStore = new AreaStore(this);
    this.buyerStore = new BuyerStore(this);
    this.orderStore = new OrderStore(this);
    this.productStore = new ProductStore(this);
    this.staffStore = new StaffStore(this);
    this.wholesalerStore = new WholesalerStore(this);
    this.changeLogStore = new ChangeLogStore(this);
  }
}

export default RootStore;

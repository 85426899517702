import React from 'react';
import { type SidebarMenuProps } from '.';
import { Text } from '../../typography';
import { Anchor } from '../../anchor';
import classNames from 'classnames';

export const SidebarMenu: React.FunctionComponent<SidebarMenuProps> = ({
  title,
  Icon,
  isActive,
  link,
  onClick,
}) => {
  const renderMenu = () => {
    return (
      <div
        className={classNames(
          'flex w-full p-4 justify-start items-center hover:bg-primary-50 rounded transition duration-300 group font-bold',
          {
            'text-primary bg-primary-50': isActive,
            'text-secondary-gray': !isActive,
          },
        )}
      >
        <Icon className="group-hover:text-primary" />
        {title && (
          <Text
            className={classNames('ml-2 group-hover:text-primary', {
              'text-secondary-gray': !isActive,
              'text-primary': isActive,
            })}
          >
            {title}
          </Text>
        )}
      </div>
    );
  };

  if (onClick) {
    return (
      <div onClick={onClick} className="cursor-pointer">
        {renderMenu()}
      </div>
    );
  }

  return <Anchor href={link}>{renderMenu()}</Anchor>;
};

import React from 'react';
import Modal from 'react-modal';
import { type BaseModalProps } from '../base-modal/base-modal.props';

export const SideMenuModal: React.FunctionComponent<BaseModalProps> = ({
  children,
  visible,
  onClose,
  style,
  ...props
}) => {
  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(000, 000, 000, 0.5)',
          zIndex: 2,
          transitionDuration: '150ms',
          ...style?.overlay,
        },
        content: {
          width: '280px',
          height: '100%',
          top: '0%',
          bottom: 'auto',
          left: '0%',
          position: 'absolute',
          backgroundColor: '#fff',
          ...style?.content,
        },
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};

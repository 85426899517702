import { makeAutoObservable } from 'mobx';
import {
  type GetDeliveryAreaParams,
  type GetDeliveryAreaParamsV2,
  MSG_GLOBAL_ERROR,
  type PlaceIdResponse,
  type PlaceSuggestionsResponse,
  type PostDeliveryAreaParams,
} from '../../utils';
import type RootStore from '../root-store';
import {
  type DeliveryArea,
  type DeliveryAreaV2,
  mapCoverageAreaResponseToDeliveryArea,
  mapDeliveryAreaResponseToDeliveryArea,
  mapDeliveryAreaResponseToDeliveryAreaV2,
  type DeliveryAreaDetail,
  type DeliveryAreaOrderSettings,
  type DeliveryPointArea,
} from './area-store.model';

export interface AreaStoreModel {
  getDeliveryAreas: () => DeliveryArea[];
  setDeliveryAreas: (area: DeliveryArea[]) => void;
}

class AreaStore {
  rootStore: RootStore;
  deliveryAreas: DeliveryArea[] = [];
  deliveryAreasV2: DeliveryAreaV2[] = [];
  deliveryAreasMap = new Map<string, DeliveryArea>();
  deliveryAreasMapV2 = new Map<string, DeliveryAreaV2>();
  deliveryAreasCount = 0;
  deliveryAreaDetail: DeliveryAreaDetail;
  areaPredictions: PlaceSuggestionsResponse[] = []; // TODO: map response to camelCase
  placeId: string;

  constructor(rootStore?: RootStore) {
    if (rootStore) {
      this.rootStore = rootStore;
      makeAutoObservable(this, { rootStore: false });
    } else {
      makeAutoObservable(this);
    }
  }

  setDeliveryAreas(areas: DeliveryArea[]) {
    this.deliveryAreas = areas;
  }

  setDeliveryAreasMap(areas: Map<string, DeliveryArea>) {
    this.deliveryAreasMap = areas;
  }

  setDeliveryAreasCount(_count: number) {
    this.deliveryAreasCount = _count;
  }

  setPlaceId(newPlaceId: string) {
    this.placeId = newPlaceId;
  }

  async getDeliveryAreas(
    params?: GetDeliveryAreaParams,
    shouldUpdateStore = true,
  ) {
    try {
      const response = await this.rootStore.api.getDeliveryAreas(params);
      const areas = response.coverage_areas.map((area) =>
        mapDeliveryAreaResponseToDeliveryArea(area),
      );
      const count = response.count;
      if (shouldUpdateStore) {
        this.setDeliveryAreas(
          areas.sort((a, b) => a.name.localeCompare(b.name)),
        );
        this.setDeliveryAreasCount(count);
        const areasMap = new Map();
        response.coverage_areas.forEach((area) => {
          areasMap.set(area.id, mapDeliveryAreaResponseToDeliveryArea(area));
        });
        this.setDeliveryAreasMap(areasMap);
      }
      return areas;
    } catch (error) {
      throw Error(error?.message || MSG_GLOBAL_ERROR);
    }
  }

  async storeDeliveryArea(params: PostDeliveryAreaParams) {
    try {
      return await this.rootStore.api.storeDeliveryArea(params);
    } catch (error) {
      throw Error(error?.message || MSG_GLOBAL_ERROR);
    }
  }

  async updateDeliveryArea(id: number, params: PostDeliveryAreaParams) {
    try {
      return await this.rootStore.api.updateDeliveryArea(id, params);
    } catch (error) {
      throw Error(error?.message || MSG_GLOBAL_ERROR);
    }
  }

  async deleteDeliveryArea(id: number, replacementId?: string) {
    try {
      return await this.rootStore.api.deleteDeliveryArea(id, replacementId);
    } catch (error) {
      throw Error(error?.message || MSG_GLOBAL_ERROR);
    }
  }

  setAreaPredictions(_areaPredictions: PlaceSuggestionsResponse[]) {
    this.areaPredictions = _areaPredictions;
  }

  async getPlaceSuggestions(
    address: string,
  ): Promise<{ status: boolean; message?: string }> {
    try {
      const { status, predictions } =
        await this.rootStore.api.getPlaceSuggestions(address);
      if (predictions?.length) this.setAreaPredictions(predictions);
      return { status: status === 'OK' };
    } catch (error) {
      return {
        status: false,
        message: error?.message || MSG_GLOBAL_ERROR,
      };
    }
  }

  setDeliveryAreaDetail(_deliveryAreaDetail: DeliveryAreaDetail) {
    this.deliveryAreaDetail = _deliveryAreaDetail;
  }

  async getDeliveryAreaDetail(
    coverageAreaId: string,
  ): Promise<{ status: boolean; message?: string }> {
    try {
      const { status, data } =
        await this.rootStore.api.getCoverageAreaDetail(coverageAreaId);

      if (status) {
        const mappedCoverageAreaDetail =
          mapCoverageAreaResponseToDeliveryArea(data);
        this.setDeliveryAreaDetail(mappedCoverageAreaDetail);
      }
    } catch (error) {
      return {
        status: false,
        message: error?.message || MSG_GLOBAL_ERROR,
      };
    }
  }

  async getPlaceDetail(placeId: string): Promise<{
    result?: any;
    parsedData?: any;
    status?: boolean;
    message?: string;
  }> {
    try {
      const response = await this.rootStore.api.getPlaceDetails(placeId);
      return {
        result: response.result,
        parsedData: response.parsed_data,
      };
    } catch (error) {
      return {
        status: false,
        message: error?.message || MSG_GLOBAL_ERROR,
      };
    }
  }

  async getCitiesByStateId(stateId: number) {
    try {
      return await this.rootStore.api.getCitiesByStateId(stateId);
    } catch (error) {
      return {
        status: false,
        message: error?.message || MSG_GLOBAL_ERROR,
      };
    }
  }

  async getDistrictsByCityId(cityId: number) {
    try {
      return await this.rootStore.api.getDistrictsByCityId(cityId);
    } catch (error) {
      return {
        status: false,
        message: error?.message || MSG_GLOBAL_ERROR,
      };
    }
  }

  async createCoverageArea(
    coverageAreaDetail: DeliveryAreaDetail,
  ): Promise<{ status: boolean; message?: string }> {
    try {
      const mapCoverageAreaOrderTypeSettingsToCoverageAreaOrderTypeSettingsRequest =
        (orderSettings: DeliveryAreaOrderSettings) => {
          if (!orderSettings) return {};
          return {
            order_limits: {
              cash_min: orderSettings.orderLimits.cashMin,
              tempo_min: orderSettings.orderLimits.tempoMin,
              tempo_max: orderSettings.orderLimits.tempoMax,
            },
            admin_fees: {
              cash_percentage_amount:
                orderSettings.adminFees.cashPercentageAmount,
              cash_nominal_amount: orderSettings.adminFees.cashNominalAmount,
              tempo: orderSettings.adminFees.tempo?.map((tempo) => ({
                percentage_amount: tempo.percentageAmount,
                due_days: tempo.dueDays,
              })),
            },
          };
        };

      const mapCoverageAreasToCoverageAreaRequest = (
        areas: DeliveryPointArea[],
      ) => {
        if (!areas) return [];
        return areas.map((area) => ({
          state_id: area.stateId,
          city_id: area.cityId,
          district_ids: area.districtIds,
        }));
      };

      const coverageAreaDetailRequest = {
        platform: 'web',
        address: coverageAreaDetail.address,
        address_city_id: coverageAreaDetail.addressCityId,
        address_state_id: coverageAreaDetail.addressStateId,
        latitude: coverageAreaDetail.latitude,
        longitude: coverageAreaDetail.longitude,
        name: coverageAreaDetail.name,
        phone: coverageAreaDetail.phone,
        has_order_type_reseller: !!coverageAreaDetail.hasOrderTypeReseller,
        has_order_type_trading: !!coverageAreaDetail.hasOrderTypeTrading,
        areas: mapCoverageAreasToCoverageAreaRequest(coverageAreaDetail.areas),
        order_type_reseller_settings:
          mapCoverageAreaOrderTypeSettingsToCoverageAreaOrderTypeSettingsRequest(
            coverageAreaDetail.resellerOrderSettings,
          ),
        order_type_trading_settings:
          mapCoverageAreaOrderTypeSettingsToCoverageAreaOrderTypeSettingsRequest(
            coverageAreaDetail.tradingOrderSettings,
          ),
      };
      const response = await this.rootStore.api.createCoverageArea(
        coverageAreaDetailRequest,
      );
      if (response.kind === 'rejected') throw new Error(response.message);
      return { status: true };
    } catch (error) {
      return {
        status: false,
        message: error?.message || MSG_GLOBAL_ERROR,
      };
    }
  }

  async updateCoverageArea(
    coverageAreaDetail: DeliveryAreaDetail,
    coverageAreaDetailId,
  ): Promise<{ status: boolean; message?: string }> {
    try {
      const mapCoverageAreaOrderTypeSettingsToCoverageAreaOrderTypeSettingsRequest =
        (orderSettings: DeliveryAreaOrderSettings) => {
          if (!orderSettings) return {};
          return {
            order_limits: {
              cash_min: orderSettings.orderLimits.cashMin,
              tempo_min: orderSettings.orderLimits.tempoMin,
              tempo_max: orderSettings.orderLimits.tempoMax,
            },
            admin_fees: {
              cash_percentage_amount:
                orderSettings.adminFees.cashPercentageAmount,
              cash_nominal_amount: orderSettings.adminFees.cashNominalAmount,
              tempo: orderSettings.adminFees.tempo?.map((tempo) => ({
                percentage_amount: tempo.percentageAmount,
                due_days: tempo.dueDays,
              })),
            },
          };
        };

      const mapCoverageAreasToCoverageAreaRequest = (
        areas: DeliveryPointArea[],
      ) => {
        if (!areas) return [];
        return areas.map((area) => ({
          state_id: area.stateId,
          city_id: area.cityId,
          district_ids: area.districtIds,
        }));
      };

      const coverageAreaDetailRequest = {
        platform: 'web',
        address: coverageAreaDetail.address,
        address_city_id: coverageAreaDetail.addressCityId,
        address_state_id: coverageAreaDetail.addressStateId,
        latitude: coverageAreaDetail.latitude,
        longitude: coverageAreaDetail.longitude,
        name: coverageAreaDetail.name,
        phone: coverageAreaDetail.phone,
        has_order_type_reseller: !!coverageAreaDetail.hasOrderTypeReseller,
        has_order_type_trading: !!coverageAreaDetail.hasOrderTypeTrading,
        areas: mapCoverageAreasToCoverageAreaRequest(coverageAreaDetail.areas),
        order_type_reseller_settings:
          mapCoverageAreaOrderTypeSettingsToCoverageAreaOrderTypeSettingsRequest(
            coverageAreaDetail.resellerOrderSettings,
          ),
        order_type_trading_settings:
          mapCoverageAreaOrderTypeSettingsToCoverageAreaOrderTypeSettingsRequest(
            coverageAreaDetail.tradingOrderSettings,
          ),
      };
      const response = await this.rootStore.api.updateCoverageArea(
        coverageAreaDetailRequest,
        coverageAreaDetailId,
      );
      if (response.kind === 'rejected') throw new Error(response.message);
      return { status: true };
    } catch (error) {
      return {
        status: false,
        message: error?.message || MSG_GLOBAL_ERROR,
      };
    }
  }

  async getPlaceId(
    lat: number,
    lng: number,
  ): Promise<{ results: PlaceIdResponse[]; status: string }> {
    try {
      return await this.rootStore.api.getPlaceId(lat, lng);
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async getCoverageAreaChangeLogs(referenceId: string) {
    try {
      const { status } = await this.rootStore.changeLogStore.getChangeLogs({
        reference_id: referenceId,
        feature_name: 'coverage_area',
        dashboard_type: 'reseller',
      });
      if (status) {
        return { status: true };
      }
      return { status: false, message: MSG_GLOBAL_ERROR };
    } catch (e) {
      return { status: false, message: e.message || MSG_GLOBAL_ERROR };
    }
  }

  setDeliveryAreasV2(areas: DeliveryAreaV2[]) {
    this.deliveryAreasV2 = areas;
  }

  setDeliveryAreasMapV2(areas: Map<string, DeliveryAreaV2>) {
    this.deliveryAreasMapV2 = areas;
  }

  async getDeliveryAreasV2(
    params?: GetDeliveryAreaParamsV2,
    shouldUpdateStore = true,
  ) {
    try {
      const response = await this.rootStore.api.getDeliveryAreasV2(params);
      const areas = response.data.map((area) =>
        mapDeliveryAreaResponseToDeliveryAreaV2(area),
      );
      const count = response.pagination.count;
      if (shouldUpdateStore) {
        this.setDeliveryAreasV2(
          areas.sort((a, b) => a.name.localeCompare(b.name)),
        );
        this.setDeliveryAreasCount(count);
        const areasMap = new Map();
        response.data.forEach((area) => {
          areasMap.set(area.id, mapDeliveryAreaResponseToDeliveryAreaV2(area));
        });
        this.setDeliveryAreasMapV2(areasMap);
      }
      return areas;
    } catch (error) {
      throw Error(error?.message || MSG_GLOBAL_ERROR);
    }
  }

  async deleteDeliveryAreaV2(
    id: string,
    newResellerId?: string,
    newTradingId?: string,
  ) {
    try {
      const response = await this.rootStore.api.deleteDeliveryAreaV2(
        id,
        newResellerId,
        newTradingId,
      );
      return response;
    } catch (error) {
      throw Error(error?.message || MSG_GLOBAL_ERROR);
    }
  }
}

export default AreaStore;
